import { chatbotApiEndpoint } from '../../lib/decorators'
import { post } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { MailRedactionJSON } from '../../models/Chatbot/MailRedaction'
import type { MailRedactionVariablesJSON } from '../../models/Chatbot/MailRedaction/Variables'

export const MailRedactions = {
  create: (variables: MailRedactionVariablesJSON): ApiRequestDescriptor<MailRedactionJSON> =>
    chatbotApiEndpoint(post('/v1/mail_redactions', variables)),
}
