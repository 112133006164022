// See `frontends/packages/ui/scss/_colors.scss` for the list of allowed colors

const defaultTheme: Record<string, string> = {
  texture_background_blend_mode: 'screen',

  main_bg: '#ffffff',
  main_fg: '#0f4447',

  navbar_bg: '#ffffff',
  navbar_fg: '#0f4447',
  darker_navbar_bg: '#fff7ef',
  darker_navbar_fg: '#0f4447',

  sidebar_bg: '#fff7ef',
  sidebar_fg: '#0f4447',
  sidebar_current_bg: '#ffefdf',
  sidebar_current_fg: '#0f4447',

  footer_bg: '#ffffff',
  footer_fg: '#0f4447',

  tile_bg: '#58e4c3',
  tile_fg: '#0f4447',

  primary_button_bg: '#2c666e',
  primary_button_fg: '#ffffff',
  primary_button_border: 'transparent',
  primary_button_hovered_bg: '#2e9cab',
  primary_button_hovered_fg: '#ffffff',
  primary_button_hovered_border: 'transparent',

  secondary_button_bg: 'transparent',
  secondary_button_fg: '#0f4447',
  secondary_button_border: '#0f444799',
  secondary_button_hovered_bg: '#0f444726',
  secondary_button_hovered_fg: '#0f4447',
  secondary_button_hovered_border: '#0f4447',

  side_content_fg: '#2c666e',
  link_fg: '#2e9cab',
  image_bg: '#fff7ef',
  error_fg: '#e08282',

  frame_bg: '#ffffff',
  frame_fg: '#0f4447',
  frame_border: '#fff7ef',
  collapsed_frame_bg: '#fff7ef',
  collapsed_frame_fg: '#0f4447',
  collapsed_frame_border: '#ffefdf',
  darker_frame_bg: '#fff7ef',
  darker_frame_fg: '#0f4447',
  darker_frame_border: '#ffefdf',

  input_bg: '#ffffff',
  input_fg: '#0f4447',
  input_border: '#194f45',
  input_fg_faded: '#0f444766',
  input_border_light: '#0f444726',
  errored_input_bg: '#e0828219',
  errored_input_border: '#e08282',

  dropdown_bg: '#ffffff',
  dropdown_fg: '#0f4447',
  dropdown_hover_bg: '#194F4526',
  dropdown_hover_fg: '#0f4447',
  dropdown_title_bg: '#fff7ef',
  dropdown_title_fg: '#0f4447',
  dropdown_title_border: 'transparent',

  tooltip_bg: '#2c666e',
  tooltip_fg: '#fff',

  tabs_bg: '#ffffff',
  tabs_fg: '#0f4447',
  tabs_border: '#0f4447',
  tabs_hover_bg: '#0f444733',
  tabs_hover_fg: '#0f4447',
  tabs_hover_border: 'transparent',
  tabs_selected_bg: '#0f4447',
  tabs_selected_fg: '#fff',
  tabs_selected_border: '#0f4447',

  backdrop_color: '#0f444799',
  separator_color: '#0f444726',
  spinner_fg: '#0f444799',

  table_bg: '#ffffff',
  table_darker_bg: '#fff7ef',
  table_fg: '#0f4447',
  table_border: '#0f444726',
  table_header_bg: '#ffefdf',
  table_header_fg: '#0f4447',

  circled_bg: 'transparent',
  circled_fg: '#0f4447',
  circled_border: '#0f4447',

  status_tag_bg: 'transparent',
  status_tag_fg: '#58e4c3',
  status_tag_border: '#58e4c3',
  status_tag_draft_bg: '#194F4599',
  status_tag_draft_fg: '#ffffff',
  status_tag_draft_border: 'transparent',
  status_tag_done_bg: '#58e4c3',
  status_tag_done_fg: '#ffffff',
  status_tag_done_border: 'transparent',
  status_tag_validated_bg: 'transparent',
  status_tag_validated_fg: '#0f4447',
  status_tag_validated_border: 'transparent',
  status_tag_error_bg: '#e08282',
  status_tag_error_fg: '#ffffff',
  status_tag_error_border: 'transparent',
  status_tag_waiting_bg: '#fffc92',
  status_tag_waiting_fg: '#0f4447',
  status_tag_waiting_border: 'transparent',

  fred_hero_bg: '#0f444799',
  fred_hero_fg: '#fff',
  fred_hero_check: '#2e9cab',

  chatbot_user_message_bg: '#0f444715',
  chatbot_user_message_fg: '#0f4447',
  chatbot_bot_message_bg: '#fff7ef',
  chatbot_bot_message_fg: '#0f4447',
}

export default defaultTheme
