import { cloneDeep } from 'lodash';
import { IntlShape } from 'react-intl';

import { safeFormatMessage } from 'utils/functions.typed';

import messages from '../messages';
import { Country } from '../Country';
import { Model } from '../Model';
import { LegacyPersonFields } from './Legacy';

export { LegacyPersonFields, LegacyPerson } from './Legacy';

export enum Civility {
  MR = 'mr',
  MRS = 'mrs',
}

const skeleton: LegacyPersonFields = {
  type: Civility.MR,
  firstName: '',
  lastName: '',
  contact: {},
  address: {},
  birthLocation: null,
};

export interface LimitedClientFields {
  civility: Civility | null;
  email: string;
  firstname: string;
  lastname: string;
  id: number;
  confirmed_at?: string | null;
}

export interface PersonFields {
  civility: Civility | null;
  firstname: string | null;
  lastname: string | null;
  birth_name: string | null;
  phone?: string | null;
  address: string | null;
  postal_code: string | null;
  city: string | null;
  country: Country | null;
  latitude?: number | null;
  longitude?: number | null;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Person<T extends PersonFields = PersonFields>
  extends PersonFields {}
/* eslint-enable @typescript-eslint/no-unused-vars */

export class Person<T extends PersonFields = PersonFields> extends Model<T> {
  static readonly Civility = Civility;

  static get skeleton(): LegacyPersonFields {
    return cloneDeep(skeleton);
  }

  formattedName(intl?: IntlShape): string {
    return [
      this.civility && safeFormatMessage(intl, messages, this.civility),
      this.firstname,
      this.lastname,
    ]
      .filter(part => part && part !== '')
      .join(' ');
  }
}
