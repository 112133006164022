import { FormattedDate, FormattedMessage } from 'react-intl';

import { Text, Tooltip, FormattedPrice } from '@advitam/ui';
import { PaymentMean } from 'models/Payment/Mean';
import { PaymentState } from '@advitam/api/models/Payment/State';
import { PaymentSummary } from 'models/Payment/Summary';
import { PaymentReceived } from '@advitam/api/models/Payment/Received';

import messages from '../../messages';
import style from './style.scss';

interface PaymentsProps {
  payment: PaymentSummary;
}

export default function PaymentCard({
  payment,
}: PaymentsProps): JSX.Element | null {
  let status: string;
  let tooltip: keyof typeof messages;
  let { amount } = payment;

  if (payment.received === PaymentReceived.LOST) {
    status = 'lost';
    tooltip = 'lost';
  } else if (payment.received === PaymentReceived.NO) {
    status = 'notReceived';
    tooltip = 'notReceived';
  } else if (payment.isAccepted) {
    status = 'paid';
    tooltip = payment.paid_at ? 'paidAt' : 'paid';
  } else if (payment.isReimbursed) {
    status = 'reimbursed';
    tooltip = payment.paid_at ? 'reimbursedAt' : 'reimbursed';
    amount = Math.abs(amount);
  } else if (
    payment.mean === PaymentMean.CHECK &&
    payment.state === PaymentState.ERROR
  ) {
    status = 'rejected';
    tooltip = 'rejected';
  } else if (
    payment.mean === PaymentMean.CHECK &&
    payment.state === PaymentState.CANCEL
  ) {
    status = 'rejected';
    tooltip = 'cancelled';
  } else if (
    payment.mean === PaymentMean.CHECK &&
    payment.state === PaymentState.GUARANTEE
  ) {
    status = 'caution';
    tooltip = 'caution';
  } else if (
    (payment.mean === PaymentMean.CHECK || payment.mean === PaymentMean.CASH) &&
    payment.state === PaymentState.WAITING
  ) {
    status = 'waiting';
    tooltip = 'waiting';
  } else {
    return null;
  }

  return (
    <Tooltip
      content={
        <Text>
          <FormattedMessage
            id={messages[tooltip].id}
            values={{
              date: payment.paid_at && (
                <FormattedDate value={payment.paid_at} />
              ),
            }}
          />
        </Text>
      }
    >
      <span className={[style.payment, style[status]].join(' ')}>
        <FormattedPrice value={amount} />
      </span>
    </Tooltip>
  );
}
