import { LayerType } from 'models/Deal/Layer';

export const LAYERS_MODAL = 'Deal/LayersModal';

const DEFUNCT = 'defunctInfo';
const DEFUNCT_PERSON = `${DEFUNCT}.person`;
const DEFUNCT_COMP_INFO = `${DEFUNCT}.defunctCompInfo`;

type Hashtype = { [key: string]: string };

const DEFUNCT_MAPPING: Hashtype = {
  civility: `${DEFUNCT_PERSON}.type`,
  firstname: `${DEFUNCT_PERSON}.firstName`,
  lastname: `${DEFUNCT_PERSON}.lastName`,
  birth_name: `${DEFUNCT_PERSON}.birthName`,
  address: `${DEFUNCT_PERSON}.address`,
  nationality: `${DEFUNCT_PERSON}.nationality`,
  birth_date: `${DEFUNCT_PERSON}.birthDate`,
  birth_location: `${DEFUNCT_PERSON}.birthLocation`,
  birth_postal_code: `${DEFUNCT_PERSON}.birth_postal_code`,
  birth_country: `${DEFUNCT_PERSON}.birth_country`,
  work: `${DEFUNCT_PERSON}.work`,
  marital_status: `${DEFUNCT_PERSON}.marital_status`,
  powered_device: `${DEFUNCT_COMP_INFO}.poweredDevice`,
  size: `${DEFUNCT_COMP_INFO}.size`,
  weight: `${DEFUNCT_COMP_INFO}.weight`,
};

const FATHER = `${DEFUNCT_COMP_INFO}.father`;

const FATHER_MAPPING: Hashtype = {
  firstname: `${FATHER}.firstName`,
  lastname: `${FATHER}.lastName`,
  birth_name: `${FATHER}.birthName`,
  work: `${FATHER}.work`,
  address: `${FATHER}.address`,
  dead: `${FATHER}.dead`,
};

const MOTHER = `${DEFUNCT_COMP_INFO}.mother`;

const MOTHER_MAPPING: Hashtype = {
  firstname: `${MOTHER}.firstName`,
  lastname: `${MOTHER}.lastName`,
  birth_name: `${MOTHER}.birthName`,
  work: `${MOTHER}.work`,
  address: `${MOTHER}.address`,
  dead: `${MOTHER}.dead`,
};

const PARTNER = `${DEFUNCT_COMP_INFO}.partner`;

const PARTNER_MAPPING: Hashtype = {
  civility: `${PARTNER}.type`,
  firstname: `${PARTNER}.firstName`,
  lastname: `${PARTNER}.lastName`,
  birth_name: `${PARTNER}.birthName`,
  work: `${PARTNER}.work`,
  address: `${PARTNER}.address`,
  dead: `${PARTNER}.dead`,
};

// Note: The mapping must be use on the targeted empowered_client
const CLIENT_MAPPING: Hashtype = {
  civility: 'client.civility',
  firstname: 'client.firstname',
  lastname: 'client.lastname',
  birth_name: 'client.birth_name',
  link: 'link',
  address: 'client.address',
  postal_code: 'client.postal_code',
  city: 'client.city',
  country: 'client.country.name',
  phone: 'client.phone',
  phone_2: 'client.phone_2',
};

export const LAYERS_MAPPING = {
  [LayerType.DEFUNCT]: DEFUNCT_MAPPING,
  [LayerType.DEFUNCT_FATHER]: FATHER_MAPPING,
  [LayerType.DEFUNCT_MOTHER]: MOTHER_MAPPING,
  [LayerType.DEFUNCT_PARTNER]: PARTNER_MAPPING,
  [LayerType.EMPOWERED_CLIENT]: CLIENT_MAPPING,
};
