import { call, put, takeLatest, delay } from 'redux-saga/effects';

import { Session } from '@advitam/api';
import { requestWithAuth } from 'utils/request';

import { GET_DEFUNCT_CONCESSION } from 'components/ConcessionSelection/constants';

import {
  PRE_BOOK_SUPPLIERS,
  UPDATE_SUPPLIER_BOOKING_STATUS,
  BOOK_SUPPLIERS,
  UPDATE_BOOKING_COMMENT,
  GET_BOOKING_INFO,
  UPDATE_SUPPLIER_SELECTION,
  PRE_CASKETING_BOOKING,
} from 'containers/Deal/BookingSection/constants';

import {
  QUERY_UPDATE_DEAL_OWNER,
  GET_CLIENT_DATA,
  GET_ORDERS_LINKED,
  DEAL_TYPE_FUNERAL,
  PUT_DEAL_BRAND,
  GET_GRAVEYARD_CONCESSIONS,
  GET_GRAVEYARD_CONCESSION_TYPES,
  GET_GRAVEYARD_BASIC_INFOS,
  GET_ESTIMATION,
} from './constants.ts';

import { fetchPaymentsDetails } from './Payments/thunk.ts';
import { fetchItems } from './Sections/Todolist/thunk.ts';
import { setDealOwner } from './SideBar/actions';
import { setOrdersLinked } from './OrdersLinked/actions';

import {
  setBookingInfo,
  setBookingComment,
  successPreBookSuppliers,
  successBookSuppliers,
  successUpdateSupplierBookingStatus,
} from './BookingSection/actions';

import { setClient } from './EditPerson/actions';
import { update as updateClient, removeEmailError } from './Clients/slice.ts';

import { Errors } from './Clients/constants.ts';

import {
  error,
  errorModal,
  setGraveyardConcessions,
  setGraveyardConcessionTypes,
  setGraveyardBasicInfos,
} from './actions';
import { setDeal, setDealBrand } from './slice.ts';
import { clientToLegacy } from './Clients/thunk.ts';
import { initialFetch } from './thunk.ts';

export function* updateDealOwner(action) {
  try {
    const requestURL = `/api/v1/deals/${action.uuid}/user`;
    const params = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_email: action.newOwner.email }),
    };
    yield call(requestWithAuth, requestURL, params);
    yield put(setDealOwner(action.newOwner.id));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* putDealBrand({ dealUUID, brand }) {
  try {
    const requestURL = `/api/v1/deals/${dealUUID}/brand`;
    const params = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        [Session.HEADER_NAME]: Session.SESSION_ID,
      },
      body: JSON.stringify({ brand_id: brand.id }),
    };
    yield call(requestWithAuth, requestURL, params);
    yield put(setDealBrand(brand));
    yield put(fetchPaymentsDetails(dealUUID));
    // Reloading deal has price might have change
    yield call(initialFetch, { uuid: dealUUID });
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* getClientData({ id, clients, index }) {
  try {
    const requestURL = `/api/v1/clients/${id}`;
    // TODO: We should remove all the legacy clients
    const resp = clientToLegacy(yield call(requestWithAuth, requestURL));
    yield put(setClient(id, resp, index, clients));
    if (resp.id) {
      const clientsNotUpdated = clients.filter(
        (c, idx) =>
          idx !== index &&
          c.contact.email === resp.contact.email &&
          c.id !== resp.id,
      );
      const isErrorRequired = clientsNotUpdated.length > 0;
      if (!isErrorRequired) {
        yield put(
          removeEmailError({
            email: resp.contact.email || '',
            error: Errors.CLIENT_ALREADY_EXISTS,
          }),
        );
      }
    }
    yield put(updateClient({ client: resp, idx: index }));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* getOrdersLinked(action) {
  try {
    const parentOrChildren =
      action.dealType === DEAL_TYPE_FUNERAL ? 'children' : 'parent';
    const requestURL = `/api/v1/deals/${action.dealUUID}/${parentOrChildren}`;
    const ordersLinked = yield call(requestWithAuth, requestURL);
    yield put(setOrdersLinked(ordersLinked));
  } catch (err) {
    err.reponse = yield err.response;
    yield put(error(err));
  }
}

export function* getBookingInfo(action) {
  try {
    const requestURL = `/api/v1/deals/${action.dealUUID}/supplier_selection`;
    const selectedSuppliers = yield call(requestWithAuth, requestURL);
    yield put(setBookingInfo(selectedSuppliers));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

function* postPreBookSuppliers(action) {
  try {
    const requestURL = `/api/v1/deals/${action.dealUUID}/suppliers/prebook`;
    const params = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    yield call(requestWithAuth, requestURL, params);
    yield put(successPreBookSuppliers());
  } catch (err) {
    err.response = yield err.response;
    yield put(errorModal(err));
  }
}

function* postBookSuppliers(action) {
  try {
    const requestURL = `/api/v1/deals/${action.dealUUID}/suppliers/book`;
    const params = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    yield call(requestWithAuth, requestURL, params);
    yield put(successBookSuppliers());
  } catch (err) {
    err.response = yield err.response;
    yield put(errorModal(err));
  }
}

function* postPreCasketingBooking(action) {
  try {
    const requestURL = `/api/v1/deals/${action.dealUUID}/suppliers/book`;
    const params = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        type: 'pre_casketing',
      }),
    };
    yield call(requestWithAuth, requestURL, params);
    yield call(getBookingInfo, { dealUUID: action.dealUUID });
    yield put(fetchItems());
  } catch (err) {
    err.response = yield err.response;
    yield put(errorModal(err));
  }
}

function* putSupplierBookingStatus(action) {
  try {
    const requestURL = `/api/v1/deals/${action.dealUUID}/suppliers/booking`;
    const params = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        supplier_id: action.supplierID,
        booking_status: action.bookingStatus,
      }),
    };
    yield call(requestWithAuth, requestURL, params);
    yield put(
      successUpdateSupplierBookingStatus(
        action.supplierID,
        action.bookingStatus,
        action.dealUUID,
      ),
    );
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

function* postSupplierSelection(action) {
  try {
    const requestURL = `/api/v1/deals/${action.dealUUID}/supplier_selection`;
    const formattedSolutions = Object.keys(action.solutions).map(groupName => ({
      id: action.solutions[groupName].selection_id,
      family: groupName,
    }));
    const params = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        choices: formattedSolutions,
      }),
    };
    const { deal, supplier_selection: supplierSelection } = yield call(
      requestWithAuth,
      requestURL,
      params,
    );
    // We hard resync our deal
    yield put(setDeal(deal));
    yield put(setBookingInfo(supplierSelection));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* getGraveyardConcessionTypes(action) {
  try {
    const requestURL = `/api/v1/graveyards/${action.id}/concession_types`;
    const resp = yield call(requestWithAuth, requestURL);
    yield put(setGraveyardConcessionTypes(resp));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* getGraveyardConcessions(action) {
  try {
    const requestURL = `/api/v1/graveyards/${action.id}/concessions`;
    const resp = yield call(requestWithAuth, requestURL);
    yield put(setGraveyardConcessions(resp));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* getDefunctConcession({ id, callback }) {
  try {
    const requestURL = `/api/v1/defunct_concessions/${id}`;
    const resp = yield call(requestWithAuth, requestURL);
    yield put(callback(resp));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* getGraveyardBasicInfos({ graveyardId }) {
  try {
    const requestURL = `/api/v1/graveyards/${graveyardId}/minimal`;
    const resp = yield call(requestWithAuth, requestURL);
    yield put(setGraveyardBasicInfos(resp));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* updateBookingComment({ dealUUID, supplierID, comment }) {
  try {
    const requestURL = `/api/v1/deals/${dealUUID}/suppliers/booking/comment`;
    const params = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        supplier_id: supplierID,
        comment,
      }),
    };
    yield call(requestWithAuth, requestURL, params);
    // fake delay for UX purpose
    yield delay(1000);
    yield put(setBookingComment(supplierID, comment));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* getEstimationData(action) {
  try {
    const requestURL = `/api/v1/prestations/estimations/${action.id}`;
    const estimation = yield call(requestWithAuth, requestURL);
    yield put(action.callback(estimation));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export default function* dealTypeWrapperData() {
  yield takeLatest(QUERY_UPDATE_DEAL_OWNER, updateDealOwner);
  yield takeLatest(GET_CLIENT_DATA, getClientData);
  yield takeLatest(GET_ORDERS_LINKED, getOrdersLinked);
  yield takeLatest(PUT_DEAL_BRAND, putDealBrand);
  yield takeLatest(GET_GRAVEYARD_CONCESSION_TYPES, getGraveyardConcessionTypes);
  yield takeLatest(GET_GRAVEYARD_CONCESSIONS, getGraveyardConcessions);
  yield takeLatest(GET_DEFUNCT_CONCESSION, getDefunctConcession);
  yield takeLatest(GET_GRAVEYARD_BASIC_INFOS, getGraveyardBasicInfos);
  yield takeLatest(UPDATE_BOOKING_COMMENT, updateBookingComment);
  yield takeLatest(GET_ESTIMATION, getEstimationData);
  yield takeLatest(GET_BOOKING_INFO, getBookingInfo);
  yield takeLatest(PRE_BOOK_SUPPLIERS, postPreBookSuppliers);
  yield takeLatest(BOOK_SUPPLIERS, postBookSuppliers);
  yield takeLatest(UPDATE_SUPPLIER_BOOKING_STATUS, putSupplierBookingStatus);
  yield takeLatest(UPDATE_SUPPLIER_SELECTION, postSupplierSelection);
  yield takeLatest(PRE_CASKETING_BOOKING, postPreCasketingBooking);
}
