import { useIntl } from 'react-intl';

import { DealKoReason } from '@advitam/api/models/Deal/KoReason';
import type { DealState } from '@advitam/api/models/Deal/State';
import { Tooltip } from '@advitam/ui';
import Check from '@advitam/ui/images/icons/check.svg';
import { safeFormatMessage } from 'utils/functions.typed';

import messages from './messages';
import style from './DealStateFlag.module.scss';

interface StateProps {
  state: DealState;
  paid?: boolean;
  koReason?: DealKoReason | null;
}

export default function State({
  state,
  paid,
  koReason,
}: StateProps): JSX.Element {
  const intl = useIntl();

  const tooltipMessage = [safeFormatMessage(intl, messages, state)];
  if (paid) {
    tooltipMessage.push(intl.formatMessage(messages.paid));
  }
  if (koReason) {
    tooltipMessage.push(safeFormatMessage(intl, messages, koReason));
  }

  return (
    <Tooltip
      content={tooltipMessage.join(', ')}
      contentClassName={`${style.container} ${style[state]}`}
    >
      {paid && <Check />}
    </Tooltip>
  );
}
