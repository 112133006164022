import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import type { GraveyardJSON } from '@advitam/api/models/Graveyard';
import { assert, nextTick } from '@advitam/support';
import { Path } from 'containers/App/constants';

import { GRAVEYARD } from './constants';
import { NewGraveyard } from './types';
import { AppStateSubset, setGraveyard } from './slice';
import { makeSelectRawGraveyard } from './selectors';

export const fetchGraveyard = createAsyncThunk(
  `${GRAVEYARD}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Graveyards.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createGraveyard = createAsyncThunk(
  `${GRAVEYARD}_CREATE`,
  async (graveyard: NewGraveyard, { rejectWithValue, dispatch }) => {
    try {
      const { body } = await request(Api.V1.Graveyards.create(graveyard));

      dispatch(setGraveyard(body));
      // Let the form reset to avoid displaying the change loss modal
      nextTick(() => nextTick(() => dispatch(replace(`./${body.id}`))));

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateGraveyard = createAsyncThunk(
  `${GRAVEYARD}_UPDATE`,
  async (graveyard: GraveyardJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Graveyards.update(graveyard));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const saveGraveyard = createAsyncThunk(
  `${GRAVEYARD}_SAVE`,
  async (graveyard: GraveyardJSON | NewGraveyard, { dispatch }) => {
    if (graveyard.id === undefined) {
      await dispatch(createGraveyard(graveyard));
    } else {
      await dispatch(updateGraveyard(graveyard));
    }
  },
);

export const updateGraveyardName = createAsyncThunk(
  `${GRAVEYARD}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const graveyard = makeSelectRawGraveyard()(state);
    assert(graveyard?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Graveyards.update({ id: graveyard.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const destroyGraveyard = createAsyncThunk(
  `${GRAVEYARD}_DESTROY`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const graveyard = makeSelectRawGraveyard()(state);
    assert(graveyard?.id !== undefined);

    try {
      await request(Api.V1.Graveyards.destroy(graveyard.id));
      dispatch(push(Path.ENTITIES));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsGraveyardDisabled = createAsyncThunk(
  `${GRAVEYARD}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const graveyard = makeSelectRawGraveyard()(state);
    assert(graveyard?.id !== undefined);

    try {
      await request(Api.V1.Graveyards.Disabled.update(graveyard.id, disabled));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
