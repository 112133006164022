import { defineMessages } from 'react-intl';

export default defineMessages({
  link: {
    id: 'app.containers.DealItems.OtherSection.link',
  },
  noLink: {
    id: 'app.containers.DealItems.OtherSection.noLink',
  },
  linkedToDealText: {
    id: 'app.containers.DealItems.OtherSection.linkedToDealText',
  },
  linkedToDealLink: {
    id: 'app.containers.DealItems.OtherSection.linkedToDealLink',
  },
  linkedToMemorialText: {
    id: 'app.containers.DealItems.OtherSection.linkedToMemorialText',
  },
  linkedToMemorialLink: {
    id: 'app.containers.DealItems.OtherSection.linkedToMemorialLink',
  },
});
