import type { IntlShape } from 'react-intl';

import { Model as ApiModel } from '@advitam/api/models/Model';
import { DealState } from '@advitam/api/models/Deal/State';
import { DealType } from '@advitam/api/models/Deal/Type';
import { DealKoReason } from '@advitam/api/models/Deal/KoReason';
import { FormattedError } from 'components/ErrorModal/FormattedError';
import stepNames from 'containers/Deal/DealFuneral/StepsSection/StepEditors/messages';
import { formatApiMessage, safeFormatMessage } from 'utils/functions.typed';

import { BrandJSON } from '../Brand';
import { Model } from '../Model';
import { ClientJSON, ClientRole } from '../Client';
import { Person } from '../Person';
import { LegacyStep } from './Step';
import { Service } from './Service';
import { Product } from './Product';
import Setting from './Setting';
import { ItemDetails, ItemDelivery } from './ItemDetails';
import { PaymentProblem } from './PaymentProblem';
import { DealDefunct, DealDefunctJSON } from './Defunct';

export { Layer } from './Layer';
export { DealParentType } from './ItemDetails';

export interface FuneralDetails {
  steps: LegacyStep[] | null;
  services: Service[];
  products: Product[];
  wishes: Setting[];
}

export interface MarbleDetails {
  services: Service[];
  products: Product[];
  wishes: Setting[];
  graveyard: {
    error?: string[];
  };
}

export interface DealBase {
  id?: number;
  uuid?: string;
  deal_type: DealType;
  state: DealState;
  user_id: number | null;
  clients: ClientJSON[];
  brand?: BrandJSON;

  error?: string[];
  have_error?: boolean;

  funeral?: FuneralDetails;
  item?: ItemDetails;
  marble?: MarbleDetails;

  ko_comment: string | null;
  ko_reason: DealKoReason | null;

  client_commentary: string;
  commentary: string;
  auto_price: number | null;
  manual_price: number | null;
  payment_problem: PaymentProblem;
  business_id: string | null;
  defunctBodyState?: 0 | 1;
}

export interface DealJSON extends DealBase {
  defunctInfo?: DealDefunctJSON;
  remind_at: string | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Deal extends DealBase {
  defunctInfo?: DealDefunct;
  remind_at: Date | null;
}

export class Deal extends Model<DealJSON> {
  constructor(data: DealJSON) {
    super(data);
    if (data.defunctInfo) {
      this.defunctInfo = new DealDefunct(data.defunctInfo);
    }

    this.remind_at = ApiModel.parseDate(data.remind_at, false);
  }

  get mainClient(): ClientJSON {
    const mainClient = this.clients.find(
      client => client.role === ClientRole.OWNER,
    );

    return mainClient || Person.skeleton;
  }

  get delivery(): ItemDelivery {
    if (!this.item)
      return { message: '', signature: '', step_id: null, step_type: null };
    return this.item.delivery;
  }

  get defunct(): DealDefunct | undefined {
    return this.defunctInfo;
  }

  get isFuneral(): boolean {
    return this.deal_type === DealType.FUNERAL;
  }

  get isItem(): boolean {
    return this.deal_type === DealType.ITEM;
  }

  get isMarble(): boolean {
    return this.deal_type === DealType.MARBLE;
  }

  get finalPrice(): number | null {
    return this.manual_price || this.auto_price;
  }

  getErrors(intl: IntlShape): FormattedError | null {
    if (!this.have_error) {
      return null;
    }

    const result = [
      ...Deal.formatErrorList(intl, this.error),
      ...this.getFuneralErrors(intl),
      ...this.getMarbleErrors(intl),
    ];

    if (result.length === 0) {
      return null;
    }
    return { status: 'consolidationError', message: result };
  }

  private getFuneralErrors(intl: IntlShape): string[] {
    if (!this.funeral) {
      return [];
    }

    let result: string[] = [];
    this.funeral.steps?.forEach(step => {
      result = [
        ...result,
        ...Deal.formatErrorList(
          intl,
          step.error,
          safeFormatMessage(intl, stepNames, step.eventType),
        ),
      ];
    });

    return [
      ...result,
      ...Deal.getServicesAndProductsErrors(intl, this.funeral),
    ];
  }

  private getMarbleErrors(intl: IntlShape): string[] {
    if (!this.marble) {
      return [];
    }

    return [
      ...Deal.formatErrorList(intl, this.marble.graveyard.error),
      ...Deal.getServicesAndProductsErrors(intl, this.marble),
    ];
  }

  private static getServicesAndProductsErrors(
    intl: IntlShape,
    details: FuneralDetails | MarbleDetails,
  ): string[] {
    let result: string[] = [];

    details.services.forEach(srv => {
      result = [
        ...result,
        ...Deal.formatErrorList(intl, srv.error, srv.priceLine.description),
      ];
    });
    details.products.forEach(product => {
      result = [
        ...result,
        ...Deal.formatErrorList(
          intl,
          product.error,
          product.productObject.product_name,
        ),
      ];
    });

    return result;
  }

  private static formatErrorList(
    intl: IntlShape,
    errors?: string[],
    desc?: string | null,
  ): string[] {
    if (!errors) {
      return [];
    }

    return errors.map((err: string): string =>
      formatApiMessage(intl, err, { desc: desc || '' }),
    );
  }
}
