import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import type { PrefectureJSON } from '@advitam/api/models/Prefecture';

import { Path } from 'containers/App/constants';
import { assert, nextTick } from '@advitam/support';

import { PREFECTURE } from './constants';
import { AppStateSubset, setPrefecture } from './slice';
import { makeSelectRawPrefecture } from './selectors';
import type { NewPrefecture } from './types';

export const fetchPrefecture = createAsyncThunk(
  `${PREFECTURE}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Prefectures.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createPrefecture = createAsyncThunk(
  `${PREFECTURE}_CREATE`,
  async (prefecture: NewPrefecture, { rejectWithValue, dispatch }) => {
    try {
      const { body } = await request(Api.V1.Prefectures.create(prefecture));

      dispatch(setPrefecture(body));
      nextTick(() => nextTick(() => dispatch(replace(`./${body.id}`))));

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updatePrefecture = createAsyncThunk(
  `${PREFECTURE}_UPDATE`,
  async (prefecture: PrefectureJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Prefectures.update(prefecture));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updatePrefectureName = createAsyncThunk(
  `${PREFECTURE}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const prefecture = makeSelectRawPrefecture()(state);
    assert(prefecture?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Prefectures.update({ id: prefecture.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const savePrefecture = createAsyncThunk(
  `${PREFECTURE}_SAVE`,
  async (prefecture: PrefectureJSON | NewPrefecture, { dispatch }) => {
    if (prefecture.id === undefined) {
      await dispatch(createPrefecture(prefecture));
    } else {
      await dispatch(updatePrefecture(prefecture));
    }
  },
);

export const destroyPrefecture = createAsyncThunk(
  `${PREFECTURE}_DESTROY`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const prefecture = makeSelectRawPrefecture()(state);
    assert(prefecture?.id !== undefined);

    try {
      await request(Api.V1.Prefectures.destroy(prefecture.id));
      dispatch(push(Path.ENTITIES));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsPrefectureDisabled = createAsyncThunk(
  `${PREFECTURE}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const prefecture = makeSelectRawPrefecture()(state);
    assert(prefecture?.id !== undefined);

    try {
      await request(
        Api.V1.Prefectures.Disabled.update(prefecture.id, disabled),
      );
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
