import { createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import AdvitamApi from '@advitam/api';
import type { ClientJSON } from '@advitam/api/models/Client';
import type { FullClientJSON } from '@advitam/api/models/Client/Full';
import { DealType } from '@advitam/api/models/Deal/Type';
import Api, { requestAsync } from 'api';
import { Deals } from 'api/formatters/Deals';
import { assert, isNil, Objects } from '@advitam/support';
import {
  Client,
  ClientJSON as LegacyClientJSON,
  ClientRole,
} from 'models/Client';
import { Deal } from 'models/Deal';
import { validateEmail } from 'utils/functions.js';

import {
  AppStateSubset as ClientsAppStateSubset,
  remove,
  softRemove,
  replace,
  update,
  openDeletingRequiredClientModal,
  setIdOfClient,
  addEmailError,
  removeEmailError,
  cleanDuplicatedClientError,
} from './slice';
import { CLIENTS_SPACE, Errors } from './constants';
import {
  makeSelectDealClients,
  makeSelectDirtyClientIndices,
  makeSelectisOwnerDirty,
  makeSelectisEmpowermentDirty,
  makeSelectLinksDirty,
  makeSelectDbOwnerIndex,
  makeSelectDbEmpoweredIndex,
} from './selectors';
import { makeSelectDeal } from '../selectors.js';
import type { AppStateSubset as DealAppStateSubset } from '../slice';

type AppStateSubset = DealAppStateSubset & ClientsAppStateSubset;

function legacyClientToNew(client: LegacyClientJSON): FullClientJSON {
  return {
    // Only keep values that are defined in the form, this will avoid erasing
    // others by mistake by passing null
    id: client.id,
    civility: client.type,
    firstname: client.firstName,
    lastname: client.lastName,
    birth_name: client.birthName || null,
    birth_date: !isNil(client.birthDate)
      ? DateTime.fromSeconds(client.birthDate).toISODate()
      : null,
    phone: client.contact.phone || null,
    phone_2: client.contact.phone_2 || null,
    email: client.contact.email || null,
    address: client.address.address || null,
    postal_code: client.address.postal_code || '',
    insee_code: client.address.insee_code || '',
    city: client.address.city || null,
    country: client.address.country_code
      ? {
          name: client.address.country || '',
          code: client.address.country_code,
        }
      : null,
  } as FullClientJSON;
}

export function clientToLegacy(client: FullClientJSON): LegacyClientJSON {
  return {
    id: client.id,
    email: client.email || undefined,
    birthLocation: client.birth_location || null,
    nationality: client.nationality || undefined,
    type: client.civility,
    firstName: client.firstname || '',
    lastName: client.lastname || '',
    birthName: client.birth_name || undefined,
    birthDate: client.birth_date
      ? DateTime.fromISO(client.birth_date)
          .setZone('utc', { keepLocalTime: true })
          .toUnixInteger()
      : undefined,
    contact: {
      email: client.email || undefined,
      phone: client.phone || undefined,
      phone_2: client.phone_2 || undefined,
      phone_3: client.phone_3 || undefined,
    },
    address: {
      address: client.address || undefined,
      address_l2: client.address_l2 || undefined,
      postal_code: client.postal_code || undefined,
      insee_code: client.insee_code || undefined,
      city: client.city || undefined,
      country: client.country?.name,
      country_code: client.country?.code,
    },
  };
}

async function updateOrCreateClient(client: Client): Promise<LegacyClientJSON> {
  let clientResponse: FullClientJSON | ClientJSON | null = null;

  if (!client.id) {
    const { body } = await requestAsync<ClientJSON, unknown>(
      AdvitamApi.V1.Clients.create(legacyClientToNew(client)),
    );
    clientResponse = body;
  } else {
    const { body } = await requestAsync<FullClientJSON, unknown>(
      AdvitamApi.V1.Clients.update(
        Objects.omit(legacyClientToNew(client), 'birth_date', 'birth_location'),
      ),
    );
    clientResponse = body;
  }

  const result = clientToLegacy(clientResponse as FullClientJSON);
  result.has_an_account = client.has_an_account;
  result.defunct_id = client.defunct_id;
  return result;
}

async function updateClient(client: Client): Promise<Client> {
  const body = await updateOrCreateClient(client);

  // TODO: Drop this hack once payloads get uniformized
  const clientResponse = new Client(client.id ? body : { ...client });
  clientResponse.id = body.id;
  clientResponse.role = client.role;
  clientResponse.special = client.special;
  clientResponse.link = client.link;
  clientResponse.ability_id = client.ability_id;
  return clientResponse;
}

// NOTE: This fonction will discard your role/special field do not rely on it
async function createAbility(deal: Deal, client: Client): Promise<Client> {
  // Creating an ability with special or as a owner create a conflict
  const special = false;
  const role =
    client.role === ClientRole.OWNER ? ClientRole.EDITOR : client.role;
  const payload = { ...client, special, role };
  const { body } = await requestAsync(
    Api.V1.Deals.Abilities.create(deal, new Client(payload)),
  );
  assert(body !== null);
  return new Client({
    ...client,
    ability_id: body.id,
    link: client.link || '',
  });
}

async function updateAbiliy(client: Client): Promise<LegacyClientJSON> {
  const { body } = await requestAsync(Api.V1.Deals.Abilities.update(client));
  assert(body !== null);
  return Deals.Ability.formatToOldClient(body);
}

async function updateOwner(
  deal: Deal,
  client: Client,
): Promise<LegacyClientJSON> {
  const { body } = await requestAsync(Api.V1.Deals.Owner.update(deal, client));
  assert(body !== null);
  return Deals.Ability.formatToOldClient(body);
}

async function updateEmpoweredClient(
  deal: Deal,
  client: Client,
): Promise<LegacyClientJSON> {
  const { body } = await requestAsync(
    Api.V1.Deals.EmpoweredClient.update(deal, client),
  );
  assert(body !== null);
  return Deals.Ability.formatToOldClient(body);
}

async function destroyClientAbility(client: Client): Promise<void> {
  await requestAsync(Api.V1.Deals.Abilities.destroy(client));
}

async function fetchClientWithEmail(email: string): Promise<number | null> {
  try {
    const { body } = await requestAsync(Api.V1.Clients.Email.show(email));
    assert(body !== null);
    return body.id;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err && err.status === 404) {
      return null;
    }
    throw err;
  }
}

export const destroyAbility = createAsyncThunk(
  `${CLIENTS_SPACE}/DESTROY_ABILITY`,
  async (idx: number, { dispatch, rejectWithValue, getState }) => {
    const state = getState() as AppStateSubset;
    const clients = makeSelectDealClients()(state);
    const client = clients[idx];
    const dbEmpoweredIndex = makeSelectDbOwnerIndex()(state);
    const dbOwnerIndex = makeSelectDbEmpoweredIndex()(state);
    const deal = makeSelectDeal()(state) as Deal;

    if (
      client.role === ClientRole.OWNER ||
      (client.special && deal.deal_type === DealType.FUNERAL)
    ) {
      return dispatch(openDeletingRequiredClientModal());
    }

    if (
      (idx === dbEmpoweredIndex || idx === dbOwnerIndex) &&
      clients[idx].ability_id !== undefined
    ) {
      return dispatch(softRemove(idx));
    }
    dispatch(remove(idx));
    try {
      return destroyClientAbility(client);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateClients = createAsyncThunk(
  `${CLIENTS_SPACE}/POST_UPDATES`,
  async (_arg, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const dirty = makeSelectDirtyClientIndices()(state);
    const isOwnerDirty = makeSelectisOwnerDirty()(state);
    const isEmpoweredDirty = makeSelectisEmpowermentDirty()(state);
    const deal = makeSelectDeal()(state) as Deal;
    const linksDirty = makeSelectLinksDirty()(state);

    try {
      const res = await Promise.all(
        makeSelectDealClients()(state).map((client, idx) =>
          !client.isDestroyed && dirty.includes(idx)
            ? updateClient(client)
            : client,
        ),
      );

      const clients = await Promise.all(
        res.map(client =>
          client.isDestroyed || client.ability_id !== undefined
            ? client
            : createAbility(deal, client),
        ),
      );

      if (isOwnerDirty) {
        const client = clients.find(c => c.role === ClientRole.OWNER);
        await updateOwner(deal, client as Client);
      }

      if (isEmpoweredDirty) {
        const client = clients.find(c => c.special);
        await updateEmpoweredClient(deal, client as Client);
      }

      const result = await Promise.all(
        clients.map((client, idx) => {
          if (!dirty.includes(idx) || client.isDestroyed) {
            return client;
          }
          const isLinkDirty =
            linksDirty[idx].initial !== linksDirty[idx].update;
          if (isLinkDirty || client.role !== ClientRole.OWNER) {
            return updateAbiliy(client);
          }
          return client;
        }),
      );

      const finalClients = await Promise.all(
        clients.map(async client => {
          if (!client.isDestroyed || client.ability_id === undefined) {
            return client;
          }
          await destroyClientAbility(client);
          return undefined;
        }),
      );
      return result.filter((_c, idx) => finalClients[idx] !== undefined);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export interface UpdatePayload {
  idx: number;
  client: LegacyClientJSON;
}

export const updateClientsState = createAsyncThunk(
  `${CLIENTS_SPACE}/POST_STATES_UPDATES`,
  async (
    { idx, client: clientPayload }: UpdatePayload,
    { getState, dispatch, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const clients = makeSelectDealClients()(state);
    const client = clients[idx];
    const oldEmail = client.contact.email;

    const newEmail = clientPayload.contact.email;
    const isEmailUpdated = oldEmail !== newEmail;
    const abilityId = client.ability_id;

    // We update first to not freeze the UI
    dispatch(update({ idx, client: clientPayload }));
    dispatch(cleanDuplicatedClientError());
    dispatch(
      removeEmailError({
        email: oldEmail as string,
        error: Errors.CLIENT_ALREADY_EXISTS,
      }),
    );

    if (!isEmailUpdated) {
      return undefined;
    }

    if (abilityId !== undefined && client.id) {
      dispatch(replace({ idx }));
    }

    if (!newEmail || !validateEmail(newEmail)) {
      return undefined;
    }

    const emailExists = clients.some(
      (c, index) =>
        index !== idx && !c.isDestroyed && c.contact.email === newEmail,
    );

    if (emailExists) {
      dispatch(
        addEmailError({
          email: newEmail,
          error: Errors.DUPLICATED_LOCAL_CLIENT,
        }),
      );
    }
    try {
      const id = await fetchClientWithEmail(newEmail);
      if (id !== null && client.id !== id) {
        dispatch(setIdOfClient({ email: newEmail, clientId: id }));
        dispatch(
          addEmailError({
            email: newEmail,
            error: Errors.CLIENT_ALREADY_EXISTS,
          }),
        );
      }
    } catch (err) {
      return rejectWithValue(err);
    }

    return undefined;
  },
);
