import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { withSlice } from '@advitam/react';
import { ErrorText, ResourceList } from '@advitam/ui';
import { FormattedApiError } from 'components/Format/ApiError';

import ClientList from './ClientList';
import Filters from './Filters';
import Header from './Header';
import slice, { setFilters } from './slice';
import { fetchClients } from './thunk';
import {
  makeSelectHasMorePages,
  makeSelectLoading,
  makeSelectError,
} from './selectors';
import type { ClientsFilters } from './types';

function Clients(): JSX.Element {
  const dispatch = useDispatch();
  const hasMorePages = useSelector(makeSelectHasMorePages());
  const error = useSelector(makeSelectError());
  const isLoading = useSelector(makeSelectLoading());
  const [searchParams] = useSearchParams();

  const fetch = useCallback(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  const onFilterChange = useCallback(
    (filters: ClientsFilters): void => {
      dispatch(setFilters(filters));
      fetch();
    },
    [dispatch, fetch],
  );

  useEffect(() => {
    const q = searchParams.get('q');
    if (q) {
      dispatch(setFilters({ q }));
    }
    fetch();
  }, []);

  return (
    <main>
      <ResourceList.Container
        error={
          error && (
            <ErrorText>
              <FormattedApiError error={error} />
            </ErrorText>
          )
        }
        filters={<Filters onChange={onFilterChange} />}
        hasMore={hasMorePages}
        header={<Header />}
        isLoading={isLoading}
        useWindow={false}
        fetchResources={fetch}
      >
        <ClientList />
      </ResourceList.Container>
    </main>
  );
}

export default withSlice(slice)(Clients);
