import { I18nPath, I18nUrl, Url } from '../../constants'
import messages from './messages'

export const footerLinks = {
  ar: [
    {
      titleKey: messages.arrange.id,
      links: [
        {
          textKey: messages.funerals.id,
          internal: true,
          href: I18nPath.ar.FUNERAL_INDEX,
        },
        {
          textKey: messages.tombstone.id,
          internal: true,
          href: I18nPath.ar.GRAVESTONE,
        },
        {
          textKey: messages.repatriation.id,
          internal: true,
          href: I18nPath.ar.REPATRIATION_INDEX,
        },
        {
          textKey: messages.flowers.id,
          internal: true,
          href: I18nPath.ar.FLOWERS_FUNNEL,
        },
      ],
    },
    {
      titleKey: messages.moreInfos.id,
      links: [
        {
          textKey: messages.commitments.id,
          internal: true,
          href: I18nPath.ar.COMMITMENTS,
        },
        {
          textKey: messages.reviews.id,
          href: I18nPath.ar.FAMILY_REVIEWS,
          internal: true,
        },
        {
          textKey: messages.press.id,
          internal: true,
          href: I18nPath.ar.PRESS,
        },
      ],
    },
  ],

  de: [
    {
      titleKey: messages.arrange.id,
      links: [
        {
          textKey: messages.funerals.id,
          internal: true,
          href: I18nPath.de.FUNERAL_INDEX,
        },
        {
          textKey: messages.tombstone.id,
          internal: true,
          href: I18nPath.de.GRAVESTONE,
        },
        {
          textKey: messages.repatriation.id,
          internal: true,
          href: I18nPath.de.REPATRIATION_INDEX,
        },
        {
          textKey: messages.flowers.id,
          internal: true,
          href: I18nPath.de.FLOWERS_FUNNEL,
        },
      ],
    },
    {
      titleKey: messages.moreInfos.id,
      links: [
        {
          textKey: messages.commitments.id,
          internal: true,
          href: I18nPath.de.COMMITMENTS,
        },
        {
          textKey: messages.reviews.id,
          href: I18nPath.de.FAMILY_REVIEWS,
          internal: true,
        },
        {
          textKey: messages.press.id,
          internal: true,
          href: I18nPath.de.PRESS,
        },
      ],
    },
  ],

  en: [
    {
      titleKey: messages.arrange.id,
      links: [
        {
          textKey: messages.funerals.id,
          internal: true,
          href: I18nPath.en.FUNERAL_INDEX,
        },
        {
          textKey: messages.tombstone.id,
          internal: true,
          href: I18nPath.en.GRAVESTONE,
        },
        {
          textKey: messages.repatriation.id,
          internal: true,
          href: I18nPath.en.REPATRIATION_INDEX,
        },
        {
          textKey: messages.flowers.id,
          internal: true,
          href: I18nPath.en.FLOWERS_FUNNEL,
        },
      ],
    },
    {
      titleKey: messages.moreInfos.id,
      links: [
        {
          textKey: messages.commitments.id,
          internal: true,
          href: I18nPath.en.COMMITMENTS,
        },
        {
          textKey: messages.reviews.id,
          href: I18nPath.en.FAMILY_REVIEWS,
          internal: true,
        },
        {
          textKey: messages.blog.id,
          internal: true,
          href: I18nPath.en.BLOG,
        },
        {
          textKey: messages.press.id,
          internal: true,
          href: I18nPath.en.PRESS,
        },
      ],
    },
  ],

  es: [
    {
      titleKey: messages.arrange.id,
      links: [
        {
          textKey: messages.funerals.id,
          internal: true,
          href: I18nPath.es.FUNERAL_INDEX,
        },
        {
          textKey: messages.tombstone.id,
          internal: true,
          href: I18nPath.es.GRAVESTONE,
        },
        {
          textKey: messages.repatriation.id,
          internal: true,
          href: I18nPath.es.REPATRIATION_INDEX,
        },
        {
          textKey: messages.flowers.id,
          internal: true,
          href: I18nPath.es.FLOWERS_FUNNEL,
        },
      ],
    },
    {
      titleKey: messages.moreInfos.id,
      links: [
        {
          textKey: messages.commitments.id,
          internal: true,
          href: I18nPath.es.COMMITMENTS,
        },
        {
          textKey: messages.reviews.id,
          href: I18nPath.es.FAMILY_REVIEWS,
          internal: true,
        },
        {
          textKey: messages.press.id,
          internal: true,
          href: I18nPath.es.PRESS,
        },
      ],
    },
  ],

  fr: [
    {
      titleKey: messages.arrange.id,
      links: [
        {
          textKey: messages.funerals.id,
          internal: true,
          href: I18nPath.fr.FUNERAL_INDEX,
        },
        {
          textKey: messages.tombstone.id,
          internal: true,
          href: I18nPath.fr.GRAVESTONE,
        },
        {
          textKey: messages.repatriation.id,
          internal: true,
          href: I18nPath.fr.REPATRIATION_INDEX,
        },
        {
          textKey: messages.flowers.id,
          internal: true,
          href: I18nPath.fr.FLOWERS_FUNNEL,
        },
        {
          textKey: messages.assistGPT.id,
          internal: true,
          href: I18nPath.fr.ASSIST_GPT,
        },
      ],
    },
    {
      titleKey: messages.moreInfos.id,
      links: [
        {
          textKey: messages.commitments.id,
          internal: true,
          href: I18nPath.fr.COMMITMENTS,
        },
        {
          textKey: messages.reviews.id,
          href: I18nPath.fr.FAMILY_REVIEWS,
          internal: true,
        },
        {
          textKey: messages.blog.id,
          internal: true,
          href: I18nPath.fr.BLOG,
        },
        {
          textKey: messages.press.id,
          internal: true,
          href: I18nPath.fr.PRESS,
        },
        {
          textKey: messages.glossary.id,
          internal: true,
          href: I18nPath.fr.GLOSSARY,
        },
      ],
    },
  ],

  he: [
    {
      titleKey: messages.arrange.id,
      links: [
        {
          textKey: messages.funerals.id,
          href: I18nPath.he.FUNERAL_INDEX,
          internal: true,
        },
        {
          textKey: messages.repatriation.id,
          href: I18nPath.he.GRAVESTONE,
          internal: true,
        },
        {
          textKey: messages.tombstone.id,
          href: I18nPath.he.REPATRIATION_INDEX,
          internal: true,
        },
        {
          textKey: messages.flowers.id,
          href: I18nPath.he.FLOWERS_FUNNEL,
          internal: true,
        },
      ],
    },
    {
      titleKey: messages.moreInfos.id,
      links: [
        {
          textKey: messages.commitments.id,
          href: I18nPath.he.COMMITMENTS,
          internal: true,
        },
        {
          textKey: messages.reviews.id,
          href: I18nPath.he.FAMILY_REVIEWS,
          internal: true,
        },
        {
          textKey: messages.press.id,
          href: I18nPath.he.PRESS,
          internal: true,
        },
      ],
    },
  ],

  it: [
    {
      titleKey: messages.arrange.id,
      links: [
        {
          textKey: messages.funerals.id,
          internal: true,
          href: I18nPath.it.FUNERAL_INDEX,
        },
        {
          textKey: messages.tombstone.id,
          internal: true,
          href: I18nPath.it.GRAVESTONE,
        },
        {
          textKey: messages.repatriation.id,
          internal: true,
          href: I18nPath.it.REPATRIATION_INDEX,
        },
        {
          textKey: messages.flowers.id,
          internal: true,
          href: I18nPath.it.FLOWERS_FUNNEL,
        },
      ],
    },
    {
      titleKey: messages.moreInfos.id,
      links: [
        {
          textKey: messages.commitments.id,
          internal: true,
          href: I18nPath.it.COMMITMENTS,
        },
        {
          textKey: messages.reviews.id,
          href: I18nPath.it.FAMILY_REVIEWS,
          internal: true,
        },
        {
          textKey: messages.press.id,
          internal: true,
          href: I18nPath.it.PRESS,
        },
      ],
    },
  ],

  pt: [
    {
      titleKey: messages.arrange.id,
      links: [
        {
          textKey: messages.funerals.id,
          internal: true,
          href: I18nPath.pt.FUNERAL_INDEX,
        },
        {
          textKey: messages.tombstone.id,
          internal: true,
          href: I18nPath.pt.GRAVESTONE,
        },
        {
          textKey: messages.repatriation.id,
          internal: true,
          href: I18nPath.pt.REPATRIATION_INDEX,
        },
        {
          textKey: messages.flowers.id,
          internal: true,
          href: I18nPath.pt.FLOWERS_FUNNEL,
        },
      ],
    },
    {
      titleKey: messages.moreInfos.id,
      links: [
        {
          textKey: messages.commitments.id,
          internal: true,
          href: I18nPath.pt.COMMITMENTS,
        },
        {
          textKey: messages.reviews.id,
          href: I18nPath.pt.FAMILY_REVIEWS,
          internal: true,
        },
        {
          textKey: messages.press.id,
          internal: true,
          href: I18nPath.pt.PRESS,
        },
      ],
    },
  ],

  sr: [
    {
      titleKey: messages.arrange.id,
      links: [
        {
          textKey: messages.funerals.id,
          internal: true,
          href: I18nPath.sr.FUNERAL_INDEX,
        },
        {
          textKey: messages.tombstone.id,
          internal: true,
          href: I18nPath.sr.GRAVESTONE,
        },
        {
          textKey: messages.repatriation.id,
          internal: true,
          href: I18nPath.sr.REPATRIATION_INDEX,
        },
        {
          textKey: messages.flowers.id,
          internal: true,
          href: I18nPath.sr.FLOWERS_FUNNEL,
        },
      ],
    },
    {
      titleKey: messages.moreInfos.id,
      links: [
        {
          textKey: messages.commitments.id,
          internal: true,
          href: I18nPath.sr.COMMITMENTS,
        },
        {
          textKey: messages.reviews.id,
          href: I18nPath.sr.FAMILY_REVIEWS,
          internal: true,
        },
        {
          textKey: messages.press.id,
          internal: true,
          href: I18nPath.sr.PRESS,
        },
      ],
    },
  ],

  tr: [
    {
      titleKey: messages.arrange.id,
      links: [
        {
          textKey: messages.funerals.id,
          internal: true,
          href: I18nPath.tr.FUNERAL_INDEX,
        },
        {
          textKey: messages.tombstone.id,
          internal: true,
          href: I18nPath.tr.GRAVESTONE,
        },
        {
          textKey: messages.repatriation.id,
          internal: true,
          href: I18nPath.tr.REPATRIATION_INDEX,
        },
        {
          textKey: messages.flowers.id,
          internal: true,
          href: I18nPath.tr.FLOWERS_FUNNEL,
        },
      ],
    },
    {
      titleKey: messages.moreInfos.id,
      links: [
        {
          textKey: messages.commitments.id,
          internal: true,
          href: I18nPath.tr.COMMITMENTS,
        },
        {
          textKey: messages.reviews.id,
          href: I18nPath.tr.FAMILY_REVIEWS,
          internal: true,
        },
        {
          textKey: messages.press.id,
          internal: true,
          href: I18nPath.tr.PRESS,
        },
      ],
    },
  ],

  zh: [
    {
      titleKey: messages.arrange.id,
      links: [
        {
          textKey: messages.funerals.id,
          internal: true,
          href: I18nPath.zh.FUNERAL_INDEX,
        },
        {
          textKey: messages.tombstone.id,
          internal: true,
          href: I18nPath.zh.GRAVESTONE,
        },
        {
          textKey: messages.repatriation.id,
          internal: true,
          href: I18nPath.zh.REPATRIATION_INDEX,
        },
        {
          textKey: messages.flowers.id,
          internal: true,
          href: I18nPath.zh.FLOWERS_FUNNEL,
        },
      ],
    },
    {
      titleKey: messages.moreInfos.id,
      links: [
        {
          textKey: messages.commitments.id,
          internal: true,
          href: I18nPath.zh.COMMITMENTS,
        },
        {
          textKey: messages.reviews.id,
          href: I18nPath.zh.FAMILY_REVIEWS,
          internal: true,
        },
        {
          textKey: messages.press.id,
          internal: true,
          href: I18nPath.zh.PRESS,
        },
      ],
    },
  ],
}

export const COOKIE_HASH = '#cookies'

export const bottomFooterLinks = {
  ar: [
    { textKey: messages.terms.id, href: I18nUrl.ar.TERMS },
    { textKey: messages.privacyPolicy.id, href: I18nUrl.ar.PRIVACY_POLICY },
    { textKey: messages.cookies.id, href: COOKIE_HASH },
    { textKey: messages.legalNotice.id, href: I18nUrl.ar.LEGAL_NOTICE },
    { textKey: messages.weAreHiring.id, href: Url.JOBS },
  ],
  de: [
    { textKey: messages.terms.id, href: I18nUrl.de.TERMS },
    { textKey: messages.privacyPolicy.id, href: I18nUrl.de.PRIVACY_POLICY },
    { textKey: messages.cookies.id, href: COOKIE_HASH },
    { textKey: messages.legalNotice.id, href: I18nUrl.de.LEGAL_NOTICE },
    { textKey: messages.weAreHiring.id, href: Url.JOBS },
  ],
  en: [
    { textKey: messages.terms.id, href: I18nUrl.en.TERMS },
    { textKey: messages.privacyPolicy.id, href: I18nUrl.en.PRIVACY_POLICY },
    { textKey: messages.cookies.id, href: COOKIE_HASH },
    { textKey: messages.legalNotice.id, href: I18nUrl.en.LEGAL_NOTICE },
    { textKey: messages.weAreHiring.id, href: Url.JOBS },
  ],
  es: [
    { textKey: messages.terms.id, href: I18nUrl.es.TERMS },
    { textKey: messages.privacyPolicy.id, href: I18nUrl.es.PRIVACY_POLICY },
    { textKey: messages.cookies.id, href: COOKIE_HASH },
    { textKey: messages.legalNotice.id, href: I18nUrl.es.LEGAL_NOTICE },
    { textKey: messages.weAreHiring.id, href: Url.JOBS },
  ],
  he: [
    { textKey: messages.terms.id, href: I18nUrl.he.TERMS },
    { textKey: messages.privacyPolicy.id, href: I18nUrl.he.PRIVACY_POLICY },
    { textKey: messages.cookies.id, href: COOKIE_HASH },
    { textKey: messages.legalNotice.id, href: I18nUrl.he.LEGAL_NOTICE },
    { textKey: messages.weAreHiring.id, href: Url.JOBS },
  ],
  it: [
    { textKey: messages.terms.id, href: I18nUrl.it.TERMS },
    { textKey: messages.privacyPolicy.id, href: I18nUrl.it.PRIVACY_POLICY },
    { textKey: messages.cookies.id, href: COOKIE_HASH },
    { textKey: messages.legalNotice.id, href: I18nUrl.it.LEGAL_NOTICE },
    { textKey: messages.weAreHiring.id, href: Url.JOBS },
  ],
  fr: [
    { textKey: messages.terms.id, href: I18nUrl.fr.TERMS },
    { textKey: messages.privacyPolicy.id, href: I18nUrl.fr.PRIVACY_POLICY },
    { textKey: messages.cookies.id, href: COOKIE_HASH },
    { textKey: messages.legalNotice.id, href: I18nUrl.fr.LEGAL_NOTICE },
    { textKey: messages.generalDocumentation.id, href: I18nUrl.fr.GENERAL_DOCUMENTATION },
    { textKey: messages.weAreHiring.id, href: Url.JOBS },
  ],
  pt: [
    { textKey: messages.terms.id, href: I18nUrl.pt.TERMS },
    { textKey: messages.privacyPolicy.id, href: I18nUrl.pt.PRIVACY_POLICY },
    { textKey: messages.cookies.id, href: COOKIE_HASH },
    { textKey: messages.legalNotice.id, href: I18nUrl.pt.LEGAL_NOTICE },
    { textKey: messages.weAreHiring.id, href: Url.JOBS },
  ],
  sr: [
    { textKey: messages.terms.id, href: I18nUrl.sr.TERMS },
    { textKey: messages.privacyPolicy.id, href: I18nUrl.sr.PRIVACY_POLICY },
    { textKey: messages.cookies.id, href: COOKIE_HASH },
    { textKey: messages.legalNotice.id, href: I18nUrl.sr.LEGAL_NOTICE },
    { textKey: messages.weAreHiring.id, href: Url.JOBS },
  ],
  tr: [
    { textKey: messages.terms.id, href: I18nUrl.tr.TERMS },
    { textKey: messages.privacyPolicy.id, href: I18nUrl.tr.PRIVACY_POLICY },
    { textKey: messages.cookies.id, href: COOKIE_HASH },
    { textKey: messages.legalNotice.id, href: I18nUrl.tr.LEGAL_NOTICE },
    { textKey: messages.weAreHiring.id, href: Url.JOBS },
  ],
  zh: [
    { textKey: messages.terms.id, href: I18nUrl.zh.TERMS },
    { textKey: messages.privacyPolicy.id, href: I18nUrl.zh.PRIVACY_POLICY },
    { textKey: messages.cookies.id, href: COOKIE_HASH },
    { textKey: messages.legalNotice.id, href: I18nUrl.zh.LEGAL_NOTICE },
    { textKey: messages.weAreHiring.id, href: Url.JOBS },
  ],
}
