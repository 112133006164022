import { ReactNode, useCallback } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import { useFormValue } from '@advitam/react'
import { HiddenInput } from '@advitam/ui'

import Api from './Api'
import Google from './Google'
import style from './DefunctBrithPlaceInput.module.scss'

interface BirthPlaceInputProps {
  label: ReactNode
  prefix: string
}

export default function BirthPlaceInput({ label, prefix }: BirthPlaceInputProps): JSX.Element {
  const country = useFormValue<string | null>(`${prefix}.birth_country.code`)
  const isFrance = !country || country === 'FR'

  const render = useCallback(
    ({ meta }: FieldRenderProps<string>) =>
      isFrance ? (
        <Api label={label} prefix={prefix} error={meta.touched && !meta.valid} />
      ) : (
        <Google label={label} prefix={prefix} error={meta.touched && !meta.valid} />
      ),
    [isFrance, label, prefix],
  )

  return (
    <div className={style.container}>
      <Field name={`${prefix}.birth_location`} render={render} />
      <HiddenInput name={`${prefix}.birth_country.name`} />
      <HiddenInput name={`${prefix}.birth_country.code`} />
      <HiddenInput name={`${prefix}.birth_postal_code`} />
    </div>
  )
}
