import { DateTime } from 'luxon';
import { FormSpy, useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import { EntityInvoiceGroupType } from '@advitam/api/models/EntityInvoice/Group/Type';
import type { AutocompleteResult } from '@advitam/api/v1/Autocompletes';
import {
  ApiAutocomplete,
  DealWithInvoiceAutocomplete,
} from '@advitam/integration';
import { usePreviousValue } from '@advitam/react';
import { DateOnlyInput, FormLayout, Select } from '@advitam/ui';

import { useEntityTypeSelectItems } from '../../../utils';
import { ENTITY_ENDPOINTS } from '../../../constants';
import messages from '../messages';
import style from './GroupInputs.module.scss';
import type { PrestationForm } from '../types';
import { groupId } from '../utils';

type GroupInputsPrestationForm = PrestationForm & {
  'invoice.entity.id': PrestationForm['invoice']['entity']['id'];
  'invoice.entity.name': PrestationForm['invoice']['entity']['name'];
  'invoice.group.id': PrestationForm['invoice']['group']['id'];
  'invoice.group.year': PrestationForm['invoice']['group']['year'];
  'prestation.deal': PrestationForm['prestation']['deal'];
};

interface AutocompleteEntityResult extends AutocompleteResult {
  name: string;
}

function getEntityDisplayValue(value: AutocompleteEntityResult): string {
  return value.name || value.description || '';
}

export default function GroupInputs(): JSX.Element {
  const intl = useIntl();
  const { values } = useFormState<PrestationForm>();

  const previousEntityType = usePreviousValue(values.invoice.entity.type);
  const previousGroupType = usePreviousValue(values.invoice.group.type);
  const previousDate = usePreviousValue(values.date);

  const entityTypes = useEntityTypeSelectItems();
  const entityEndpoint =
    values.invoice.entity.type && ENTITY_ENDPOINTS[values.invoice.entity.type];

  const billingTypes = [
    {
      value: EntityInvoiceGroupType.DEAL,
      name: intl.formatMessage(messages.billingTypeDeal),
    },
    {
      value: EntityInvoiceGroupType.PRESTATION,
      name: intl.formatMessage(messages.billingTypePrestation),
    },
    {
      value: EntityInvoiceGroupType.MONTH,
      name: intl.formatMessage(messages.billingTypeMonth),
    },
    {
      value: EntityInvoiceGroupType.QUARTER,
      name: intl.formatMessage(messages.billingTypeQuarter),
    },
  ];

  return (
    <>
      <FormLayout.Row>
        <Select
          required
          name="invoice.entity.type"
          items={entityTypes}
          label={<FormattedMessage id={messages.entityType.id} />}
        />
        <ApiAutocomplete
          required
          name="invoice.entity"
          getDisplayValue={getEntityDisplayValue}
          label={<FormattedMessage id={messages.entityId.id} />}
          keys={['name']}
          disabled={!entityEndpoint}
          endpoint={entityEndpoint ? Api.V1.absolute(entityEndpoint()) : ''}
          requestHeaders={{ ...getTokens() }}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          required
          name="invoice.group.type"
          items={billingTypes}
          label={<FormattedMessage id={messages.billingType.id} />}
          tooltip={
            <div className={style.tooltip}>
              <FormattedMessage id={messages.billingTypeTooltipText.id} />
              <ul>
                <li>
                  <FormattedMessage id={messages.billingTypeTooltipItem1.id} />
                </li>
                <li>
                  <FormattedMessage id={messages.billingTypeTooltipItem2.id} />
                </li>
                <li>
                  <FormattedMessage id={messages.billingTypeTooltipItem3.id} />
                </li>
              </ul>
            </div>
          }
        />
        {values.invoice.group.type === EntityInvoiceGroupType.DEAL ? (
          <DealWithInvoiceAutocomplete
            required
            label={<FormattedMessage id={messages.defunctOrClient.id} />}
            name="prestation.deal"
          />
        ) : (
          <DateOnlyInput
            required
            name="date"
            label={<FormattedMessage id={messages.date.id} />}
          />
        )}
      </FormLayout.Row>

      <FormSpy<GroupInputsPrestationForm> subscription={{ values: true }}>
        {({ values: newValues, form }): null => {
          const { group } = newValues.invoice;
          const groupChanged =
            group.type !== previousGroupType ||
            (newValues.date !== previousDate && !newValues.date?.includes('_'));
          const dealChanged =
            group.type === EntityInvoiceGroupType.DEAL &&
            newValues.prestation.deal.id !== newValues.invoice.group.id;

          if (groupChanged || dealChanged) {
            form.batch(() => {
              if (!group.type) {
                form.change('invoice.group.id', null);
                form.change('invoice.group.year', null);
                return;
              }

              if (group.type === EntityInvoiceGroupType.DEAL) {
                const { deal } = newValues.prestation;
                form.change('invoice.group.id', deal.id || null);
                form.change('invoice.group.year', null);
                return;
              }

              const newGroupId = groupId(newValues.date, group.type);
              const groupYear = newValues.date
                ? DateTime.fromISO(newValues.date).year
                : null;
              form.change('invoice.group.id', newGroupId);
              form.change('invoice.group.year', groupYear);
            });
          }

          const { entity } = newValues.invoice;
          if (previousEntityType && entity.type !== previousEntityType) {
            form.batch(() => {
              form.change('invoice.entity.id', null);
              form.change('invoice.entity.name', null);
            });
          }

          return null;
        }}
      </FormSpy>
    </>
  );
}
