import { createSlice } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';

import { STATE_SELECT_SLICE } from './constants';
import { updateState } from './thunk';

export interface State {
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [STATE_SELECT_SLICE]: State;
}

const initialState: State = {
  error: null,
};

const slice = createSlice({
  name: STATE_SELECT_SLICE,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    clearError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(updateState.rejected, (state, { payload }) => {
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export default slice;
export const { clearError } = slice.actions;
