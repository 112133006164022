import { ReactNode, useCallback } from 'react'

import Api from '@advitam/api'
import type { AutocompleteCityhallResult } from '@advitam/api/v1/Autocompletes'

import ApiAutocomplete from './ApiAutocomplete'

interface AutocompleteProps {
  placeholder?: string
  required?: boolean
  label: ReactNode
  tooltip?: ReactNode
  name: string
  className?: string
  disabled?: boolean
}

export default function Autocomplete({
  placeholder,
  name,
  label,
  tooltip,
  required,
  className,
  disabled,
}: AutocompleteProps): JSX.Element {
  const getDisplayValue = useCallback(
    (result: AutocompleteCityhallResult): string => `${result.name} (${result.postal_code})`,
    [],
  )

  return (
    <ApiAutocomplete<AutocompleteCityhallResult>
      placeholder={placeholder}
      name={name}
      label={label}
      tooltip={tooltip}
      endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.cityhalls)}
      required={required}
      className={className}
      disabled={disabled}
      getDisplayValue={getDisplayValue}
      keys={['name', 'postal_code', 'insee_code']}
    />
  )
}
