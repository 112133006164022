import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import type { WorshipJSON } from '@advitam/api/models/Worship';
import { assert, nextTick } from '@advitam/support';
import { Path } from 'containers/App/constants';

import { WORSHIP } from './constants';
import { AppStateSubset, setWorship } from './slice';
import { makeSelectRawWorship } from './selectors';
import { NewWorship } from './types';

export const fetchWorship = createAsyncThunk(
  `${WORSHIP}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Worships.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createWorship = createAsyncThunk(
  `${WORSHIP}_CREATE`,
  async (worship: NewWorship, { rejectWithValue, dispatch }) => {
    try {
      const { body } = await request(Api.V1.Worships.create(worship));

      dispatch(setWorship(body));
      nextTick(() => nextTick(() => dispatch(replace(`./${body.id}`))));

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateWorship = createAsyncThunk(
  `${WORSHIP}_UPDATE`,
  async (worship: WorshipJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Worships.update(worship));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateWorshipName = createAsyncThunk(
  `${WORSHIP}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const worship = makeSelectRawWorship()(state);
    assert(worship?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Worships.update({ id: worship.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const saveWorship = createAsyncThunk(
  `${WORSHIP}_SAVE`,
  async (worship: WorshipJSON | NewWorship, { dispatch }) => {
    if (worship.id === undefined) {
      await dispatch(createWorship(worship));
    } else {
      await dispatch(updateWorship(worship));
    }
  },
);

export const destroyWorship = createAsyncThunk(
  `${WORSHIP}_DESTROY`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const worship = makeSelectRawWorship()(state);
    assert(worship?.id !== undefined);

    try {
      await request(Api.V1.Worships.destroy(worship.id));
      dispatch(push(Path.ENTITIES));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsWorshipDisabled = createAsyncThunk(
  `${WORSHIP}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const worship = makeSelectRawWorship()(state);
    assert(worship?.id !== undefined);

    try {
      await request(Api.V1.Worships.Disabled.update(worship.id, disabled));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
