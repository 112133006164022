import type { ReactNode } from 'react';

import style from './Layout.module.scss';

interface ContainerProps {
  className?: string;
  children: ReactNode | ReactNode[];
}

export default function CrudContainer({
  className,
  children,
}: ContainerProps): JSX.Element {
  return (
    <main className={[style.container, className].filter(Boolean).join(' ')}>
      {children}
    </main>
  );
}
