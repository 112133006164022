import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  GridChildComponentProps,
  GridItemKeySelector,
  ListChildComponentProps,
  VariableSizeGrid,
  VariableSizeList,
} from 'react-window'

import { useBreakpoint, useResizeObserver } from '@advitam/react'

import Container from '../../Container'
import { PageSpinner } from '../../Spinner'
import { BREAKPOINT_VALUE, HEADER_SIZE, GUTTER_SIZE, SCROLLBAR_OFFSET } from './constants'
import Header from './Header'
import { Breakpoint, Column } from './types'
import { applyGutter, getColumnWidths } from './utils'
import style from './Windowed.module.scss'

interface ContainerProps {
  isLoading?: boolean
  className?: string
  error?: ReactNode
  filters?: ReactNode
  itemCount: number
  desktopRowHeight: number
  mobileRowHeight: number
  columns: Column[]
  overscanCount?: number
  itemKey: (rowIndex: number) => string
}

export default function WindowContainer({
  isLoading,
  className = '',
  error,
  filters,
  itemCount,
  desktopRowHeight,
  mobileRowHeight,
  columns,
  itemKey,
  overscanCount,
}: ContainerProps): JSX.Element {
  const classes = [style.windowed_list, className].filter(Boolean)
  const container = useRef<HTMLDivElement | null>(null)
  const grid = useRef<VariableSizeGrid | null>(null)
  const [[width, height], setSize] = useState([0, 0])

  useResizeObserver(container.current, () => {
    if (!container.current) {
      return
    }
    const rect = container.current.getBoundingClientRect()
    setSize([rect.width, rect.height])
  })

  const isTablet = useBreakpoint(BREAKPOINT_VALUE[Breakpoint.TABLET])
  const isDesktop = useBreakpoint(BREAKPOINT_VALUE[Breakpoint.DESKTOP])
  const isFullHd = useBreakpoint(BREAKPOINT_VALUE[Breakpoint.FHD])
  const filteredColumns = useMemo(
    () =>
      columns.filter(
        column =>
          column.onlyAbove === undefined ||
          (column.onlyAbove === Breakpoint.TABLET && isTablet) ||
          (column.onlyAbove === Breakpoint.DESKTOP && isDesktop) ||
          (column.onlyAbove === Breakpoint.FHD && isFullHd),
      ),
    [columns, isTablet, isDesktop, isFullHd],
  )

  const getDesktopRowHeight = useCallback(() => desktopRowHeight + GUTTER_SIZE, [desktopRowHeight])
  const getMobileRowHeight = useCallback(() => mobileRowHeight + GUTTER_SIZE, [mobileRowHeight])
  const columnWidths = useMemo(() => {
    grid.current?.resetAfterColumnIndex(0)
    return getColumnWidths(width - SCROLLBAR_OFFSET, filteredColumns)
  }, [filteredColumns, width])
  const getColumnWidth = useCallback((index: number) => columnWidths[index], [columnWidths])

  const innerElementType = useMemo(
    () =>
      forwardRef<HTMLDivElement>(({ children, ...rest }: HTMLAttributes<HTMLDivElement>, ref) => {
        const divStyle = rest.style && { ...rest.style }
        if (typeof divStyle?.height === 'number') {
          divStyle.height = divStyle.height - GUTTER_SIZE + HEADER_SIZE
        }
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div ref={ref} {...rest} style={divStyle}>
            <Header columns={filteredColumns} getColumnWidth={getColumnWidth} />
            {children}
            {isLoading && <PageSpinner />}
            {error}
          </div>
        )
      }),
    [filteredColumns, getColumnWidth, isLoading, error],
  )

  const mobileInnerElementType = useMemo(
    () =>
      forwardRef<HTMLDivElement>(({ children, ...rest }: HTMLAttributes<HTMLDivElement>, ref) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div ref={ref} {...rest}>
          {children}
          <div style={{ display: 'block' }}>
            {isLoading && <PageSpinner />}
            {error}
          </div>
        </div>
      )),
    [isLoading, error],
  )

  const CellFactory = useCallback(
    ({
      columnIndex,
      rowIndex,
      style: cellStyle,
      isScrolling,
    }: GridChildComponentProps): JSX.Element => {
      const { Cell } = filteredColumns[columnIndex]
      const cellClasses = [
        style.cell,
        style.grid_cell,
        columnIndex === 0 && style.first_of_row,
        columnIndex === filteredColumns.length - 1 && style.last_of_row,
      ].filter(Boolean)
      const top = typeof cellStyle.top === 'number' ? cellStyle.top + HEADER_SIZE : cellStyle.top
      return (
        <Cell
          style={{ ...applyGutter(cellStyle), top }}
          rowIndex={rowIndex}
          isScrolling={isScrolling}
          className={cellClasses.join(' ')}
        />
      )
    },
    [filteredColumns],
  )

  const RowFactory = useCallback(
    ({ index, style: rowStyle, isScrolling }: ListChildComponentProps): JSX.Element => (
      <div
        style={{
          ...applyGutter(rowStyle),
          width: undefined,
        }}
        className={style.row}
      >
        {filteredColumns.map(({ Cell }) => (
          <Cell rowIndex={index} isScrolling={isScrolling} className={style.cell} />
        ))}
      </div>
    ),
    [filteredColumns],
  )

  const getGridItemKey = useCallback<GridItemKeySelector<unknown>>(
    ({ rowIndex, columnIndex }) => `${itemKey(rowIndex)}-${columnIndex}`,
    [itemKey],
  )

  return (
    <>
      <Container>{filters}</Container>
      <div className={style.container} ref={container}>
        {width > 0 && !isTablet && (
          <VariableSizeList
            itemCount={itemCount}
            itemSize={getMobileRowHeight}
            width={width}
            height={height}
            className={classes.join(' ')}
            innerElementType={mobileInnerElementType}
            itemKey={itemKey}
            overscanCount={overscanCount}
          >
            {RowFactory}
          </VariableSizeList>
        )}
        {width > 0 && isTablet && (
          <VariableSizeGrid
            rowCount={itemCount}
            columnCount={filteredColumns.length}
            width={width}
            height={height}
            columnWidth={getColumnWidth}
            estimatedColumnWidth={(width - SCROLLBAR_OFFSET) / filteredColumns.length}
            rowHeight={getDesktopRowHeight}
            className={classes.join(' ')}
            innerElementType={innerElementType}
            ref={grid}
            itemKey={getGridItemKey}
            overscanRowCount={overscanCount}
          >
            {CellFactory}
          </VariableSizeGrid>
        )}
        {isLoading && <PageSpinner />}
        {error}
      </div>
    </>
  )
}
