import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { CloseButton, ContentModal, ModalTitle } from '@advitam/ui';

import { makeSelectDocumentsGenerationQueue } from 'containers/DocumentGenerationModal/selectors';

import Content from './Content';
import { makeSelectIsOpen, makeSelectIsSending } from './selectors';
import slice, { closeModal } from './slice';
import messages from './messages';
import style from './SendBatchModal.module.scss';

function SendBatchModal(): JSX.Element {
  const dispatch = useDispatch();
  const isOpen = useSelector(makeSelectIsOpen());
  const isSending = useSelector(makeSelectIsSending());
  const documentsGenerationQueue = useSelector(
    makeSelectDocumentsGenerationQueue(),
  );

  const onClose = useCallback((): void => {
    if (!isSending) {
      dispatch(closeModal());
    }
  }, [isSending, dispatch]);

  return (
    <ContentModal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={
        documentsGenerationQueue.length > 0 ? style.hidden : undefined
      }
      className={`new-design col-sm-10 ${style.modal}`}
    >
      <ModalTitle hasCloseButton>
        <FormattedMessage id={messages.title.id} />
        <CloseButton onClick={onClose} />
      </ModalTitle>
      <Content onClose={onClose} />
    </ContentModal>
  );
}

export default withSlice(slice)(SendBatchModal);
