import { chatbotApiEndpoint } from '../../../lib/decorators'
import { del, post, put } from '../../../lib/methods'
import { ApiRequestDescriptor } from '../../../lib/request'

export const Feedbacks = {
  create: (runId: string, useful: boolean): ApiRequestDescriptor<void> =>
    chatbotApiEndpoint(post(`/v1/messages/${runId}/feedbacks`, { useful })),
  update: (runId: string, useful: boolean): ApiRequestDescriptor<void> =>
    chatbotApiEndpoint(put(`/v1/messages/${runId}/feedbacks`, { useful })),
  destroy: (runId: string): ApiRequestDescriptor<void> =>
    chatbotApiEndpoint(del(`/v1/messages/${runId}/feedbacks`)),
}
