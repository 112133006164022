import { assert } from '@advitam/support'

import { ApiPayload, ApiRequestDescriptor, JSONValue } from './request'
import { SESSION_ID, HEADER_NAME as SESSION_HEADER_NAME } from './session'
import { getTokens } from './tokens'

type ApiMethod<T, U extends Array<T>, ResponseType, PayloadType> = (
  ...args: U
) => ApiRequestDescriptor<ResponseType, PayloadType>

type DescriptorOrRequest<T, U extends Array<T>, ResponseType, PayloadType> =
  | TypedPropertyDescriptor<ApiMethod<T, U, ResponseType, PayloadType>>
  | ApiRequestDescriptor<ResponseType, PayloadType>

export function authenticate<T, U extends Array<T>, ResponseType, PayloadType, TargetType>(
  _target: TargetType,
  _propertyKey: string,
  descriptor: TypedPropertyDescriptor<ApiMethod<T, U, ResponseType, PayloadType>>,
): TypedPropertyDescriptor<ApiMethod<T, U, ResponseType, PayloadType>>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function authenticate<T, U extends Array<T>, ResponseType, PayloadType>(
  request: ApiRequestDescriptor<ResponseType, PayloadType>,
  _compat1?: undefined,
  _compat2?: undefined,
): ApiRequestDescriptor<ResponseType, PayloadType>

export function authenticate<T, U extends Array<T>, ResponseType, PayloadType, TargetType>(
  builderOrTarget: TargetType | ApiRequestDescriptor<ResponseType, PayloadType>,
  _propertyKey: string | undefined,
  descriptor: TypedPropertyDescriptor<ApiMethod<T, U, ResponseType, PayloadType>> | undefined,
): DescriptorOrRequest<T, U, ResponseType, PayloadType> {
  function makeRequest(
    request: ApiRequestDescriptor<ResponseType, PayloadType>,
  ): ApiRequestDescriptor<ResponseType, PayloadType> {
    return {
      ...request,
      headers: {
        ...request.headers,
        ...getTokens(),
      },
    }
  }

  if (!descriptor) {
    return makeRequest(builderOrTarget as ApiRequestDescriptor<ResponseType, PayloadType>)
  }

  if (!descriptor.value) {
    return descriptor
  }

  const builder = descriptor.value
  const value = (...args: U): ApiRequestDescriptor<ResponseType, PayloadType> => {
    const request: ApiRequestDescriptor<ResponseType, PayloadType> = builder(...args)
    return makeRequest(request)
  }

  return { ...descriptor, value }
}

export function authenticateWithToken<ResponseType, PayloadType>(token: string) {
  return (
    request: ApiRequestDescriptor<ResponseType, PayloadType>,
  ): ApiRequestDescriptor<ResponseType, PayloadType> => ({
    ...request,
    headers: { ...request.headers, Authorization: `Bearer ${token}` },
  })
}

export function authenticateAsAdvitamService<ResponseType, PayloadType>(
  request: ApiRequestDescriptor<ResponseType, PayloadType>,
): ApiRequestDescriptor<ResponseType, PayloadType> {
  if (process.browser) {
    // The tree shaker should drop occurences to ADVITAM_PRIVATE_TOKEN
    return request
  }

  const token = process.env.ADVITAM_PRIVATE_TOKEN
  assert(token !== undefined)
  return authenticateWithToken<ResponseType, PayloadType>(token)(request)
}

function formdataEncode(payload: ApiPayload, formdata: FormData, prefix?: string): void {
  const withPrefix = (value: string): string => (prefix ? `${prefix}[${value}]` : value)

  Object.entries(payload).forEach(([key, value]: [string, JSONValue]) => {
    if (value === null || value === undefined) {
      return
    }
    if (value instanceof Blob) {
      // Blobs are also an object
      formdata.set(withPrefix(key), value)
    } else if (value instanceof Date) {
      formdata.set(withPrefix(key), value.toISOString())
    } else if (typeof value === 'object') {
      formdataEncode(value, formdata, withPrefix(key))
    } else if (value !== null && value !== undefined) {
      formdata.set(withPrefix(key), value.toString())
    }
  })
}

export function formdataEncoded<T, U extends Array<T>, ResponseType, PayloadType, TargetType>(
  _target: TargetType,
  _propertyKey: string,
  descriptor: TypedPropertyDescriptor<ApiMethod<T, U, ResponseType, PayloadType>>,
): TypedPropertyDescriptor<ApiMethod<T, U, ResponseType, PayloadType>> {
  const original = descriptor.value
  if (!original) {
    return descriptor
  }

  return {
    ...descriptor,
    value: (...args: U): ApiRequestDescriptor<ResponseType, PayloadType> => {
      const request: ApiRequestDescriptor<ResponseType, PayloadType> = original(...args)
      const encoded = new FormData()
      if (request.payload) {
        formdataEncode(request.payload, encoded)
      }

      request.payload = encoded
      return request
    },
  }
}

export function withSessionId<T, U extends Array<T>, ResponseType, PayloadType, TargetType>(
  _target: TargetType,
  _propertyKey: string,
  descriptor: TypedPropertyDescriptor<ApiMethod<T, U, ResponseType, PayloadType>>,
): TypedPropertyDescriptor<ApiMethod<T, U, ResponseType, PayloadType>>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withSessionId<T, U extends Array<T>, ResponseType, PayloadType>(
  request: ApiRequestDescriptor<ResponseType, PayloadType>,
  _compat1?: undefined,
  _compat2?: undefined,
): ApiRequestDescriptor<ResponseType, PayloadType>

export function withSessionId<T, U extends Array<T>, ResponseType, PayloadType, TargetType>(
  builderOrTarget: TargetType | ApiMethod<T, U, ResponseType, PayloadType>,
  _propertyKey: string | undefined,
  descriptor: TypedPropertyDescriptor<ApiMethod<T, U, ResponseType, PayloadType>> | undefined,
): DescriptorOrRequest<T, U, ResponseType, PayloadType> {
  function makeRequest(
    request: ApiRequestDescriptor<ResponseType, PayloadType>,
  ): ApiRequestDescriptor<ResponseType, PayloadType> {
    return {
      ...request,
      headers: { ...request.headers, [SESSION_HEADER_NAME]: SESSION_ID },
    }
  }

  if (!descriptor) {
    return makeRequest(builderOrTarget as ApiRequestDescriptor<ResponseType, PayloadType>)
  }

  if (!descriptor.value) {
    return descriptor
  }

  const builder = descriptor.value
  const value = (...args: U): ApiRequestDescriptor<ResponseType, PayloadType> => {
    const request: ApiRequestDescriptor<ResponseType, PayloadType> = builder(...args)
    request.headers[SESSION_HEADER_NAME] = SESSION_ID
    return request
  }

  return { ...descriptor, value }
}

export function chatbotApiEndpoint<ResponseType, PayloadType>(
  request: ApiRequestDescriptor<ResponseType, PayloadType>,
): ApiRequestDescriptor<ResponseType, PayloadType> {
  const host = process.env.CHATBOT_API_ENDPOINT
  assert(host !== undefined)

  return {
    ...request,
    endpoint: [host, request.endpoint].join(''),
  }
}

/**
 * Disable credentials for a request.
 *
 * Useful for extern-origin requests and / or requests to services allowing a wildcard, e.g. download from S3.
 */
export function disableCredentials<ResponseType, PayloadType>(
  request: ApiRequestDescriptor<ResponseType, PayloadType>,
): ApiRequestDescriptor<ResponseType, PayloadType> {
  return {
    ...request,
    credentials: 'omit',
  }
}
