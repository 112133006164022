import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  quote: { id: 'app.components.DealStateFlag.quote' },
  order: { id: 'app.components.DealStateFlag.order' },
  ok: { id: 'app.components.DealStateFlag.ok' },
  ko: { id: 'app.components.DealStateFlag.ko' },
  funeral_contract: { id: 'app.components.DealStateFlag.funeral_contract' },
  funeral_contract_ok: {
    id: 'app.components.DealStateFlag.funeral_contract_ok',
  },
  staging: { id: 'app.components.DealStateFlag.staging' },
  paid: { id: 'app.components.DealStateFlag.paid' },
  anticipation: { id: 'app.components.DealStateFlag.anticipation' },
  duplicate: { id: 'app.components.DealStateFlag.duplicate' },
  not_provided_service: {
    id: 'app.components.DealStateFlag.notProvidedService',
  },
  other: { id: 'app.components.DealStateFlag.other' },
  physical_store: { id: 'app.components.DealStateFlag.physicalStore' },
  too_expensive: { id: 'app.components.DealStateFlag.tooExpensive' },
  unreachable: { id: 'app.components.DealStateFlag.unreachable' },
});
