import type { ApiRequestDescriptor } from '../../lib/request'
import { get } from '../../lib/methods'
import type { CountryJSON, MinimalCountryJSON } from '../../models/Website/Country'
import { authenticateAsAdvitamService } from '../../lib/decorators'

export const Countries = {
  index: (): ApiRequestDescriptor<MinimalCountryJSON[]> =>
    authenticateAsAdvitamService(get('/api/v1/website/countries')),

  show: (slug: string): ApiRequestDescriptor<CountryJSON> =>
    authenticateAsAdvitamService(get(`/api/v1/website/countries/${slug}`)),
}
