import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import ErrorBanner from 'components/ErrorBanner';

import Content from './Content';
import { makeSelectError } from './selectors';
import slice, { setActiveMarker } from './slice';
import style from './Map.module.scss';

function MapContainer(): JSX.Element {
  const dispatch = useDispatch();

  const error = useSelector(makeSelectError());

  useEffect(
    () => () => {
      dispatch(setActiveMarker(null));
    },
    [dispatch],
  );

  return (
    <>
      <div className={style.container}>
        <Content />
      </div>
      {error && <ErrorBanner errors={error} />}
    </>
  );
}

export default withSlice(slice)(MapContainer);
