import { ChangeEvent, FocusEvent, ReactElement, ReactNode } from 'react'
import { BlurEvent } from 'react-autosuggest'

import BaseAutosuggest from '../Autosuggest/Base'
import { Headquarter } from './types'
import { fetchSuggestions, getDisplayValue, renderSuggestion } from './utils'

const SIRET_MASK = '000 000 000 00000'

interface SiretAutocompleteProps {
  value?: Headquarter
  label?: ReactNode
  onChange: (data: Headquarter | undefined) => void
  onInput?: (ev: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLElement>, params?: BlurEvent<Headquarter>) => void
  disabled?: boolean
  expandOnTop?: boolean
  error?: ReactElement | boolean
  className?: string
  placeholder?: string
}

export default function SiretAutocomplete({
  value,
  label,
  disabled,
  expandOnTop,
  error,
  className,
  placeholder,
  onChange,
  onInput,
  onBlur,
}: SiretAutocompleteProps): JSX.Element {
  return (
    <BaseAutosuggest<Headquarter>
      label={label}
      error={error}
      disabled={disabled}
      expandOnTop={expandOnTop}
      className={className}
      placeholder={placeholder}
      value={value}
      getSuggestionValue={getDisplayValue}
      getDisplayValue={getDisplayValue}
      renderSuggestion={renderSuggestion}
      fetchSuggestions={fetchSuggestions}
      onChange={onChange}
      onInput={onInput}
      onBlur={onBlur}
      mask={SIRET_MASK}
    />
  )
}
