import {
  ChangeEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import type { Editor as EditorInstance } from 'tinymce'
import { Editor } from '@tinymce/tinymce-react'

import Spinner from '../../../Spinner'
import { LANGUAGES_ROOT_URL, MODEL_URL, SKIN_URL } from '../../../TinyMCE/constants'
import { useControls } from '../../../TinyMCE/hooks'
import { AiFeature, Control, MailRedactionVariables } from '../../../TinyMCE/types'
import { setValueWithEvent } from '../../support'
import ErrorMessage from '../parts/ErrorMessage'
import Label from '../parts/Label'
import uiStyle from '../UI.module.scss'
import style from './Html.module.scss'

interface HtmlProps {
  value?: HTMLInputElement['value']
  label?: ReactNode
  name?: string
  placeholder?: string
  error?: boolean | ReactElement
  disabled?: boolean
  tooltip?: ReactNode
  onChange: ChangeEventHandler<HTMLInputElement>
  onBlur?: () => void
  className?: string
  withControls?: boolean | Control[]
  controlsPosition?: 'top' | 'bottom'
  aiFeatures?: AiFeature[]
  aiRedactionVariables?: () => MailRedactionVariables
}

/**
 * HTML editor in a textarea-like input
 *
 * @note The main project is responsible for making sure that tinymce is loaded.
 * If not, "This domain is not registered with tiny cloud" will be displayed.
 */
export default function Html({
  value = '',
  label,
  name,
  placeholder,
  error,
  disabled,
  tooltip,
  onChange,
  onBlur,
  className = '',
  withControls = false,
  controlsPosition = 'top',
  aiFeatures,
  aiRedactionVariables,
}: HtmlProps): JSX.Element {
  const intl = useIntl()

  const editor = useRef<EditorInstance | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const showErrorMessage = Boolean(error) && error !== true
  const input = useRef<HTMLInputElement>(null)
  const toolbarContainer = useRef<HTMLDivElement>(null)

  const { externalPlugins, plugins, toolbar } = useControls(withControls)

  const aiBeforeLoad = useCallback(() => setIsLoading(true), [setIsLoading])
  const aiAfterLoad = useCallback(() => setIsLoading(false), [setIsLoading])

  useEffect(() => {
    if (!editor.current) {
      return
    }
    editor.current.options.set('ai_redaction_variables', aiRedactionVariables)
  }, [aiRedactionVariables])

  const classes = [
    uiStyle.input,
    style.html,
    value && value.length > 0 && uiStyle.filled,
    error && uiStyle.error,
    withControls && style.with_controls,
    controlsPosition === 'bottom' && style.bottom_controls,
    className,
  ].filter(Boolean)

  return (
    <div className={uiStyle.field}>
      <Label value={label} tooltip={tooltip} />
      <div className={classes.join(' ')}>
        <div ref={toolbarContainer} className={style.toolbar} />
        {toolbarContainer.current && (
          <Editor
            value={value}
            onInit={(_, instance): void => {
              editor.current = instance
            }}
            onBlur={onBlur}
            onEditorChange={(v): void => {
              if (input.current) {
                setValueWithEvent(input.current, v)
              }
            }}
            init={{
              language: intl.locale === 'en' ? undefined : intl.locale,
              language_url: `${LANGUAGES_ROOT_URL}${intl.locale}.js`,
              menubar: false,
              branding: false,
              resize: false,
              statusbar: false,
              external_plugins: externalPlugins,
              skin_url: SKIN_URL,
              model_url: MODEL_URL,
              // It looks like this feature prevents spaces to be inserted on the second line
              remove_trailing_brs: false,
              fixed_toolbar_container_target: toolbarContainer.current,
              sandbox_iframes: true, // CVE-2024-29203
              convert_unsafe_embeds: true, // CVE-2024-29881
              ai_before_load: aiBeforeLoad,
              ai_after_load: aiAfterLoad,
              ai_features: aiFeatures,
              ai_redaction_variables: aiRedactionVariables,
            }}
            inline
            toolbar={toolbar}
            disabled={disabled}
            plugins={plugins}
          />
        )}
        {!value && <span className={style.placeholder}>{placeholder}</span>}
        <input ref={input} name={name} onChange={onChange} disabled />
        {isLoading && (
          <div className={style.spinner}>
            <Spinner />
          </div>
        )}
      </div>
      {showErrorMessage && <ErrorMessage error={error} />}
    </div>
  )
}
