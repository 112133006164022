import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AutoComplete from 'containers/AutoComplete';
import CheckboxDelegate from 'containers/Deal/DealFuneral/StepsSection/StepEditors/CheckboxDelegate';
import {
  renderStepDefaults,
  renderLocation,
} from 'containers/Deal/DealFuneral/StepsSection/StepEditors/stepEditorsUtils';
import commonMessages from 'containers/Deal/DealFuneral/StepsSection/StepEditors/messages';

import GraveyardBasicInfos from './GraveyardBasicInfos';
import messages from './messages';

class IntermentLocation extends Component {
  componentDidMount() {
    const { step } = this.props;
    if (step.location && step.location.id)
      this.getGraveyardConcessionAndTypes(step.location.id);
  }

  /**
   * Function to get graveyard concession and types
   *
   * @param {number} locationId id of the graveyard
   */
  getGraveyardConcessionAndTypes = locationId => {
    const {
      getGraveyardConcessions,
      getGraveyardConcessionTypes,
      getGraveyardBasicInfos,
    } = this.props;
    getGraveyardConcessionTypes(locationId);
    getGraveyardConcessions(locationId);
    getGraveyardBasicInfos(locationId);
  };

  /**
   * Function to on select graveyard autocomplete
   *
   * @param {number} graveyardId id of graveyard selected
   * @param {string} graveyardDescription description of graveyard selected
   * @param {number} graveyardPostalCode postal code of graveyard selected
   */
  onSelect = (graveyardId, graveyardDescription, graveyardPostalCode) => {
    const { step, handleChange } = this.props;
    const { location } = step;
    if (graveyardId !== (location && location.id)) {
      this.getGraveyardConcessionAndTypes(graveyardId);
      handleChange({
        ...step,
        location: {
          ...location,
          id: graveyardId,
          name: graveyardDescription,
          type: 'Graveyard',
          address: { postal_code: graveyardPostalCode },
        },
        concession: {
          ...step.concession,
          graveyard_id: graveyardId,
          version_id: null,
        },
      });
    }
  };

  render() {
    const {
      handleChange,
      step,
      isDates,
      isDelegate,
      updateDelegate,
      graveyardBasicInfos,
    } = this.props;

    return (
      <div className="step intermentLocation">
        <div className="step__header">
          {isDates && renderStepDefaults(step, handleChange)}
          {step.location &&
            renderLocation(step.location, commonMessages.location)}
          {isDelegate && (
            <CheckboxDelegate
              isDelegate={!!step.doneByAnotherFuneral}
              updateDelegate={updateDelegate}
            />
          )}
        </div>
        <div className="intermentLocation__content">
          <div className="intermentLocation__content__autocomplete">
            <FormattedMessage {...messages.graveyards} />
            <AutoComplete
              url="/api/v1/autocompletes/graveyards?q="
              getKey={item => item.id}
              getValue={item => item.description}
              onSelect={(_, item) =>
                this.onSelect(item.id, item.description, item.postal_code)
              }
              auth
            />
          </div>
          <GraveyardBasicInfos graveyardBasicInfos={graveyardBasicInfos} />
        </div>
      </div>
    );
  }
}

IntermentLocation.propTypes = {
  /** step data */
  step: PropTypes.object.isRequired,
  /** function to update step */
  handleChange: PropTypes.func.isRequired,
  /** function to get graveyard concessions types */
  getGraveyardConcessionTypes: PropTypes.func.isRequired,
  /** function to get graveyard concessions */
  getGraveyardConcessions: PropTypes.func.isRequired,
  /** the interment can be delegate by another funeral company */
  isDelegate: PropTypes.bool.isRequired,
  /** we can set dates in the step */
  isDates: PropTypes.bool.isRequired,
  /** function to update is delegate */
  updateDelegate: PropTypes.func,
  /** function to get graveyard summary data */
  getGraveyardBasicInfos: PropTypes.func.isRequired,
  /** Basic infos of the selected graveyard */
  graveyardBasicInfos: PropTypes.object,
};

export default IntermentLocation;
