import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { ErrorText, ResourceList } from '@advitam/ui';
import { FormattedApiError } from 'components/Format/ApiError';
import { withDatasets } from 'slices/data';

import Filters from './parts/Filters';
import Header from './parts/Header';
import OpsLogModal from './parts/OpsLogs/Modal';
import Row from './parts/Row';
import slice, { resetPaging } from './slice';
import {
  makeSelectDeals,
  makeSelectError,
  makeSelectHasMoreDeals,
  makeSelectIsLoading,
} from './selectors';
import { fetchDeals } from './thunk';

function Payments(): JSX.Element {
  const dispatch = useDispatch();

  const deals = useSelector(makeSelectDeals());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());
  const hasMore = useSelector(makeSelectHasMoreDeals());

  const onFetch = useCallback((): void => {
    dispatch(fetchDeals());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetPaging());
    onFetch();
  }, [dispatch, onFetch]);

  return (
    <main>
      <ResourceList.Container
        hasMore={hasMore}
        isLoading={isLoading}
        fetchResources={onFetch}
        error={
          error && (
            <ErrorText center tagName="p">
              <FormattedApiError error={error} />
            </ErrorText>
          )
        }
        filters={<Filters />}
        header={<Header />}
      >
        {deals.map(deal => (
          <Row deal={deal} key={deal.id} />
        ))}
      </ResourceList.Container>

      <OpsLogModal />
    </main>
  );
}

export default compose(
  withDatasets('funeralTeam', 'funeralBrands'),
  withSlice(slice),
)(Payments);
