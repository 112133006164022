import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { isApiError, request } from '@advitam/api';
import type { ConsulateJSON } from '@advitam/api/models/Consulate';
import { assert, nextTick } from '@advitam/support';
import { Path } from 'containers/App/constants';

import { CONSULATE, CONSULATE_DEFAULT_TAKEN_ERROR } from './constants';
import { AppStateSubset, setConsulate } from './slice';
import { makeSelectRawConsulate } from './selectors';
import type { NewConsulate } from './types';

export const fetchConsulate = createAsyncThunk(
  `${CONSULATE}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Consulates.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createConsulate = createAsyncThunk(
  `${CONSULATE}_CREATE`,
  async (consulate: NewConsulate, { rejectWithValue, dispatch }) => {
    try {
      const { body } = await request(Api.V1.Consulates.create(consulate));

      dispatch(setConsulate(body));
      nextTick(() => nextTick(() => dispatch(replace(`./${body.id}`))));

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateConsulate = createAsyncThunk(
  `${CONSULATE}_UPDATE`,
  async (consulate: ConsulateJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Consulates.update(consulate));
      // Keep the value to avoid an UI shift between the end of this call and the default update call
      body.default = consulate.default;
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateConsulateName = createAsyncThunk(
  `${CONSULATE}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const consulate = makeSelectRawConsulate()(state);
    assert(consulate?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Consulates.update({ id: consulate.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

interface SetConsulateDefaultPayload {
  consulate: ConsulateJSON;
  force: boolean;
}

export const setConsulateDefault = createAsyncThunk(
  `${CONSULATE}_SET_DEFAULT`,
  async (
    { consulate, force }: SetConsulateDefaultPayload,
    { rejectWithValue },
  ) => {
    try {
      await request(
        Api.V1.Consulates.Default.update(
          consulate.id,
          consulate.default,
          force,
        ),
      );
      return { needsConfiramtion: false };
    } catch (error) {
      if (
        isApiError(error) &&
        error.errorCodes[0] === CONSULATE_DEFAULT_TAKEN_ERROR
      ) {
        return { needsConfiramtion: true };
      }
      return rejectWithValue(error);
    }
  },
);

export const saveConsulate = createAsyncThunk(
  `${CONSULATE}_SAVE`,
  async (consulate: ConsulateJSON | NewConsulate, { dispatch, getState }) => {
    const state = getState() as AppStateSubset;
    const currentConsulate = makeSelectRawConsulate()(state);

    if (consulate.id === undefined) {
      await dispatch(createConsulate(consulate));
    } else {
      await dispatch(updateConsulate(consulate));
    }

    if (consulate.id && currentConsulate?.default !== consulate.default) {
      await dispatch(setConsulateDefault({ consulate, force: false }));
    }
  },
);

export const destroyConsulate = createAsyncThunk(
  `${CONSULATE}_DESTROY`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const consulate = makeSelectRawConsulate()(state);
    assert(consulate?.id !== undefined);

    try {
      await request(Api.V1.Consulates.destroy(consulate.id));
      dispatch(push(Path.ENTITIES));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsConsulateDisabled = createAsyncThunk(
  `${CONSULATE}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const consulate = makeSelectRawConsulate()(state);
    assert(consulate?.id !== undefined);

    try {
      await request(Api.V1.Consulates.Disabled.update(consulate.id, disabled));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
