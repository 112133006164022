import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import type { HospitalJSON } from '@advitam/api/models/Hospital';
import { assert, nextTick } from '@advitam/support';
import { Path } from 'containers/App/constants';

import { HOSPITAL } from './constants';
import { AppStateSubset, setHospital } from './slice';
import { makeSelectRawHospital } from './selectors';
import { NewHospital } from './types';

export const fetchHospital = createAsyncThunk(
  `${HOSPITAL}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Hospitals.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createHospital = createAsyncThunk(
  `${HOSPITAL}_CREATE`,
  async (hospital: NewHospital, { rejectWithValue, dispatch }) => {
    try {
      const { body } = await request(Api.V1.Hospitals.create(hospital));

      dispatch(setHospital(body));
      nextTick(() => nextTick(() => dispatch(replace(`./${body.id}`))));

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateHospital = createAsyncThunk(
  `${HOSPITAL}_UPDATE`,
  async (hospital: HospitalJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Hospitals.update(hospital));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateHospitalName = createAsyncThunk(
  `${HOSPITAL}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const hospital = makeSelectRawHospital()(state);
    assert(hospital?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Hospitals.update({ id: hospital.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const saveHospital = createAsyncThunk(
  `${HOSPITAL}_SAVE`,
  async (hospital: HospitalJSON | NewHospital, { dispatch }) => {
    if (hospital.id === undefined) {
      await dispatch(createHospital(hospital));
    } else {
      await dispatch(updateHospital(hospital));
    }
  },
);

export const destroyHospital = createAsyncThunk(
  `${HOSPITAL}_DESTROY`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const hospital = makeSelectRawHospital()(state);
    assert(hospital?.id !== undefined);

    try {
      await request(Api.V1.Hospitals.destroy(hospital.id));
      dispatch(push(Path.ENTITIES));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsHospitalDisabled = createAsyncThunk(
  `${HOSPITAL}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const hospital = makeSelectRawHospital()(state);
    assert(hospital?.id !== undefined);

    try {
      await request(Api.V1.Hospitals.Disabled.update(hospital.id, disabled));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
