import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { FormattedApiError, ResourceList } from '@advitam/ui';

import {
  makeSelectError,
  makeSelectFundingAgencies,
  makeSelectHasMore,
  makeSelectIsLoading,
} from './selectors';
import slice from './slice';
import { fetchFundingAgencies } from './thunk';
import Filters from './Filters';
import Header from './Header';
import Row from './Row';
import style from './FundingAgencies.modules.scss';

function FundingAgencies(): JSX.Element {
  const dispatch = useDispatch();

  const agencies = useSelector(makeSelectFundingAgencies());
  const error = useSelector(makeSelectError());
  const isLoading = useSelector(makeSelectIsLoading());
  const hasMore = useSelector(makeSelectHasMore());

  const fetchAgencies = useCallback(() => {
    dispatch(fetchFundingAgencies());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchFundingAgencies());
  }, []);

  return (
    <main>
      <ResourceList.Container
        error={
          error && (
            <p className={style.error}>
              <FormattedApiError error={error} />
            </p>
          )
        }
        filters={<Filters />}
        hasMore={hasMore}
        header={<Header />}
        isLoading={isLoading}
        useWindow={false}
        fetchResources={fetchAgencies}
      >
        {agencies.map(agency => (
          <Row key={agency.id} agency={agency} />
        ))}
      </ResourceList.Container>
    </main>
  );
}

export default withSlice(slice)(FundingAgencies);
