import { useState } from 'react'

import { useResizeObserver } from './useResizeObserver'

function isWindowAbove(breakpoint: number): boolean {
  if (typeof window === 'undefined') {
    return true
  }
  return window.innerWidth >= breakpoint
}

/**
 * Test whether the window size is above some breakpoint.
 *
 * @warning This hook is not SSR friendly.
 *
 * @return true if the window width is larger than `breakpoint`, false otherwise
 */
export function useBreakpoint(breakpoint: number): boolean {
  const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(isWindowAbove(breakpoint))

  useResizeObserver(document.body, () => {
    setIsAboveBreakpoint(isWindowAbove(breakpoint))
  })

  return isAboveBreakpoint
}
