import { Company, fetchCompanies } from './api'
import type { Headquarter } from './types'

export function getDisplayValue(hq: Headquarter): string {
  return hq.siret || ''
}

export function renderSuggestion(hq: Headquarter): string {
  const siret =
    hq.siret &&
    [
      hq.siret.substring(0, 3),
      hq.siret.substring(3, 6),
      hq.siret.substring(6, 9),
      hq.siret.substring(9),
    ].join(' ')
  return [siret, hq.name].filter(Boolean).join(' - ')
}

function serialize(company: Company): Headquarter {
  return {
    siret: company.siege.siret,
    name: company.nom_complet.toUpperCase(),
    address: [company.siege.numero_voie, company.siege.type_voie, company.siege.libelle_voie]
      .filter(Boolean)
      .join(' '),
    postal_code: company.siege.code_postal,
    city: company.siege.libelle_commune,
    country: {
      name: company.siege.libelle_pays_etranger || 'France',
      code: company.siege.code_pays_etranger || 'FR',
    },
  }
}

export async function fetchSuggestions(q: string): Promise<Headquarter[]> {
  try {
    const { results } = await fetchCompanies({ q })
    return results.map(company => serialize(company))
  } catch (err) {
    if (!['production', 'test'].includes(process.env.NODE_ENV || '')) {
      // eslint-disable-next-line no-console
      console.error(err)
    }

    return []
  }
}
