import { authenticate } from '../../../../lib/decorators'
import { get, post } from '../../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../../lib/request'
import type { DealDocumentBatchJSON } from '../../../../models/Deal/Document/Batch'
import { AddDocument } from './AddDocument'
import { AddRecipient } from './AddRecipient'
import { RemoveDocument } from './RemoveDocument'
import { RemoveRecipient } from './RemoveRecipient'

export const Batches = {
  AddDocument,
  AddRecipient,
  RemoveDocument,
  RemoveRecipient,

  create: (
    dealUuid: string,
    to_sign: boolean,
    generator_name: string,
    documents?: number[],
  ): ApiRequestDescriptor<DealDocumentBatchJSON> =>
    authenticate(
      post(`/api/v1/deals/${dealUuid}/documents/batches`, {
        generator_name,
        to_sign,
        documents,
      }),
    ),

  show: (dealUuid: string, id: number): ApiRequestDescriptor<DealDocumentBatchJSON> =>
    authenticate(get(`/api/v1/deals/${dealUuid}/documents/batches/${id}`)),
}
