import { assert, Objects } from '@advitam/support';
import { authenticate, formdataEncoded } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { del, get, post, put } from 'api/methods';
import { serializeForCreation } from 'api/serializers/SupportingDocuments/Versions';

import type { SupportingDocumentVersionJSON } from 'models/SupportingDocument/Version';
import { ModelType } from 'models/Model';
import { PolymorphicId } from 'models/PolymorphicId';

interface IndexFilters {
  owner_type: ModelType;
}

export default class Versions {
  @authenticate
  static index(
    filters?: IndexFilters,
  ): ApiRequestDescriptor<SupportingDocumentVersionJSON[]> {
    return get('/api/v1/supporting_documents/versions', filters);
  }

  @authenticate
  @formdataEncoded
  static create(
    payload: SupportingDocumentVersionJSON,
    file: Blob,
    owner: PolymorphicId,
  ): ApiRequestDescriptor<SupportingDocumentVersionJSON> {
    return post(
      '/api/v1/supporting_documents/versions',
      serializeForCreation(payload, file, owner),
    );
  }

  @authenticate
  static update(
    payload: SupportingDocumentVersionJSON,
  ): ApiRequestDescriptor<SupportingDocumentVersionJSON> {
    const { uploaded_document } = payload;
    assert(uploaded_document.id !== null);

    return put(
      `/api/v1/supporting_documents/versions/${uploaded_document.id}`,
      {
        uploaded_document: Objects.pick(
          uploaded_document,
          'expiry_date',
          'issue_date',
          'reference',
        ),
      },
    );
  }

  @authenticate
  static destroy(id: number): ApiRequestDescriptor<void> {
    return del(`/api/v1/supporting_documents/versions/${id}`);
  }
}
