import { ReactNode, useCallback, useMemo, ReactElement } from 'react'
import { useForm } from 'react-final-form'

import Api from '@advitam/api'
import { getTokens } from '@advitam/api/lib/tokens'
import type { AutocompleteCityhallResult } from '@advitam/api/v1/Autocompletes'
import { useFormValues } from '@advitam/react'
import { FormUI } from '@advitam/ui'

import FlagSelect from './FlagSelect'

interface AutocompleteProps {
  label: ReactNode
  prefix: string
  error?: ReactElement | boolean
}

export default function ApiBirthLocation({ label, prefix, error }: AutocompleteProps): JSX.Element {
  const form = useForm()
  const values = useFormValues<string | null>([
    `${prefix}.birth_postal_code`,
    `${prefix}.birth_location`,
  ])
  const value = useMemo(
    () => ({
      id: 0,
      description: values[1] || '',
      name: values[1] || '',
      postal_code: values[0] || '',
      insee_code: '',
    }),
    [values],
  )

  const onChange = useCallback(
    (r: AutocompleteCityhallResult | undefined): void => {
      form.batch(() => {
        form.change(`${prefix}.birth_location`, r?.name)
        form.change(`${prefix}.birth_postal_code`, r?.postal_code)
        form.change(`${prefix}.birth_country.code`, 'FR')
      })
    },
    [form, prefix],
  )

  return (
    <FormUI.Autosuggest
      label={label}
      endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.cityhalls)}
      value={value}
      requestHeaders={{ ...getTokens() }}
      onChange={onChange}
      error={error}
      prefix={<FlagSelect prefix={prefix} />}
    />
  )
}
