/*
 * SideBar Messages
 *
 * This contains all the text for the SideBar components.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  dealState: {
    id: 'app.containers.DealTypeWrapper.SideBar.dealState',
  },
  dealOwner: {
    id: 'app.containers.DealTypeWrapper.SideBar.dealOwner',
  },
  dealSummary: {
    id: 'app.containers.DealTypeWrapper.SideBar.dealSummary',
  },
  dealKoReason: {
    id: 'app.containers.DealTypeWrapper.SideBar.dealKoReason',
  },
  unsubscribe: {
    id: 'app.containers.DealTypeWrapper.SideBar.unsubscribe',
  },
  products: {
    id: 'app.containers.DealTypeWrapper.SideBar.products',
  },
  documents: {
    id: 'app.containers.DealTypeWrapper.SideBar.documents',
  },
  payments: {
    id: 'app.containers.DealTypeWrapper.SideBar.payments',
  },
  ordersLinked: {
    id: 'app.containers.DealTypeWrapper.SideBar.ordersLinked',
  },
  suppliers: {
    id: 'app.containers.DealTypeWrapper.SideBar.suppliers',
  },
  dealStateConfirmation: {
    id: 'app.containers.DealTypeWrapper.SideBar.dealStateConfirmation',
  },
  memorial: {
    id: 'app.containers.DealTypeWrapper.SideBar.memorial',
  },
  todoList: {
    id: 'app.containers.DealFuneral.TodoList.header',
  },
  client: {
    id: 'app.containers.DealTypeWrapper.SideBar.client',
  },
  steps: {
    id: 'app.containers.DealFuneral.SideBar.steps',
  },
  others: {
    id: 'app.containers.DealFuneral.SideBar.others',
  },
  history: {
    id: 'app.containers.DealFuneral.SideBar.history',
  },
  prestations: {
    id: 'app.containers.DealTypeWrapper.SideBar.prestations',
  },
});
