import { ReactNode, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import { useThunkDispatch, withSlice } from '@advitam/react';
import { HardSpinner } from '@advitam/ui';

import { FullscreenModal } from 'components/Modal';
import ErrorBanner from 'components/ErrorBanner';

import ConflictsModal from './ConflictsModal';
import DocumentList from './DocumentList';
import ErrorList from './ErrorList';
import Header from './Header';
import {
  makeSelectCurrentResource,
  makeSelectIsSaving,
  makeSelectUploadedDocuments,
  makeSelectError,
} from './selectors';
import slice, { reset, setCurrentResource } from './slice';
import {
  DocumentEdition,
  DocumentEditionsForm,
  NewUploadResource,
} from './types';
import { saveDocuments, uploadDocuments } from './thunk';
import style from './DocumentsEditor.module.scss';

interface ChildrenProps {
  uploadFiles: (
    files: File[],
    resource: NewUploadResource,
  ) => void | Promise<void>;
}

interface DocumentsEditorProps {
  afterSaveHook?: () => void;
  children: (props: ChildrenProps) => ReactNode | ReactNode[];
}

function DocumentsEditor({
  afterSaveHook,
  children,
}: DocumentsEditorProps): JSX.Element {
  const dispatch = useThunkDispatch();

  const uploadedDocuments = useSelector(makeSelectUploadedDocuments());
  const currentResource = useSelector(makeSelectCurrentResource());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());

  const save = useCallback(
    async (documents: DocumentEdition[], shouldBypassConflicts?: true) => {
      const result = await dispatch(
        saveDocuments({ documents, shouldBypassConflicts }),
      );

      if (result.meta.requestStatus === 'fulfilled' && afterSaveHook) {
        afterSaveHook();
      }
    },
    [dispatch, afterSaveHook],
  );

  const onSubmit = useCallback(
    async ({ documents }: DocumentEditionsForm): Promise<void> => {
      await save(documents);
    },
    [save],
  );

  useEffect(
    () => (): void => {
      dispatch(reset());
    },
    [dispatch],
  );

  return (
    <>
      {children({
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        uploadFiles: async (files, resource) => {
          dispatch(setCurrentResource(resource));
          await dispatch(uploadDocuments(files));
        },
      })}

      {currentResource && (
        <>
          <FullscreenModal>
            <Form<DocumentEditionsForm>
              keepDirtyOnReinitialize
              mutators={{ ...arrayMutators }}
              onSubmit={onSubmit}
              initialValues={{ documents: uploadedDocuments }}
            >
              {({ handleSubmit }): JSX.Element => (
                <form className={style.container} onSubmit={handleSubmit}>
                  <Header />
                  <DocumentList />
                </form>
              )}
            </Form>
            <ErrorList />
            <ConflictsModal save={save} />
          </FullscreenModal>

          {isSaving && <HardSpinner />}

          {error && <ErrorBanner errors={error} />}
        </>
      )}
    </>
  );
}

export default withSlice<DocumentsEditorProps>(slice)(DocumentsEditor);
