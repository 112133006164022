import { IntlShape } from 'react-intl';

import { safeFormatMessage } from 'utils/functions.typed';

import { Model } from '../Model';
import messages from '../messages';

enum Civility {
  MR = 'mr',
  MRS = 'mrs',
}

interface PersonContact {
  email?: string;
  phone?: string;
  phone_2?: string;
  phone_3?: string;
  fax?: string;
}

export interface PersonAddress {
  address?: string;
  address_l2?: string;
  postal_code?: string;
  insee_code?: string;
  city?: string;
  department?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  country_code?: string;
}

export interface LegacyPersonFields {
  type: Civility | null;
  firstName: string;
  lastName: string;
  birthName?: string;
  birthLocation: string | null;
  link?: string;
  birthDate?: number | null;
  contact: PersonContact;
  address: PersonAddress;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LegacyPerson<T extends LegacyPersonFields = LegacyPersonFields>
  extends LegacyPersonFields {}
/* eslint-enable @typescript-eslint/no-unused-vars */

export class LegacyPerson<
  T extends LegacyPersonFields = LegacyPersonFields
> extends Model<T> {
  formattedName(intl?: IntlShape): string {
    return [
      safeFormatMessage(intl, messages, this.type || ''),
      this.firstName,
      this.lastName,
    ]
      .filter(part => part && part !== '')
      .join(' ');
  }
}
