import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import type {
  SupplierWarehouseJSON,
  SupplierWarehouseZoneJSON,
} from '@advitam/api/models/Supplier/Warehouse';
import { assert, nextTick } from '@advitam/support';
import { Path } from 'containers/App/constants';

import { Path as SupplierPath } from '../constants';
import { makeSelectRawSupplier } from '../selectors';
import { SUPPLIER_WAREHOUSE } from './constants';
import { makeSelectRawWarehouse } from './selectors';
import type { AppStateSubset as SupplierAppStateSubset } from '../slice';
import {
  AppStateSubset as WarehouseAppStateSubset,
  setWarehouse,
  setZones,
} from './slice';
import type { NewSupplierWarehouse } from './types';

type AppStateSubset = SupplierAppStateSubset & WarehouseAppStateSubset;

async function fetchWarehouseModel(id: number): Promise<SupplierWarehouseJSON> {
  const { body } = await request(Api.V1.Suppliers.Warehouses.show(id));
  return body;
}

async function fetchWarehouseZones(
  id: number,
): Promise<SupplierWarehouseZoneJSON[]> {
  const { body } = await request(Api.V1.Suppliers.Warehouses.Zones.index(id));
  return body;
}

export const fetchWarehouse = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE}_FETCH`,
  async (id: number, { rejectWithValue }) => {
    try {
      const [warehouse, zones] = await Promise.all([
        fetchWarehouseModel(id),
        fetchWarehouseZones(id),
      ]);

      return { warehouse, zones };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createWarehouse = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE}_CREATE`,
  async (
    warehouse: NewSupplierWarehouse,
    { dispatch, getState, rejectWithValue },
  ) => {
    const state = getState() as AppStateSubset;
    const supplier = makeSelectRawSupplier()(state);
    assert(supplier?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Suppliers.Warehouses.create(supplier.id, warehouse),
      );

      dispatch(setWarehouse(body));
      dispatch(setZones([]));
      // Let the form reset to avoid displaying the change loss modal
      nextTick(() => nextTick(() => dispatch(replace(`./${body.id}`))));

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateWarehouse = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE}_UPDATE`,
  async (warehouse: SupplierWarehouseJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(
        Api.V1.Suppliers.Warehouses.update(warehouse),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const saveWarehouse = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE}_SAVE`,
  async (
    warehouse: SupplierWarehouseJSON | NewSupplierWarehouse,
    { dispatch },
  ) => {
    if (warehouse.id === undefined) {
      await dispatch(createWarehouse(warehouse));
    } else {
      await dispatch(updateWarehouse(warehouse));
    }
  },
);

export const updateWarehouseName = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const warehouse = makeSelectRawWarehouse()(state);
    assert(warehouse?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Suppliers.Warehouses.update({ id: warehouse.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const destroyWarehouse = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE}_DESTROY`,
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const warehouse = makeSelectRawWarehouse()(state);
    const supplier = makeSelectRawSupplier()(state);
    assert(supplier?.id !== undefined);
    assert(warehouse?.id !== undefined);

    try {
      await request(Api.V1.Suppliers.Warehouses.destroy(warehouse.id));
      dispatch(push(Path.SUPPLIER(supplier.id, SupplierPath.WAREHOUSES)));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsWarehouseDisabled = createAsyncThunk(
  `${SUPPLIER_WAREHOUSE}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const warehouse = makeSelectRawWarehouse()(state);
    assert(warehouse?.id !== undefined);

    try {
      await request(
        Api.V1.Suppliers.Warehouses.Disabled.update(warehouse.id, disabled),
      );
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
