import { useCallback, useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import { HardSpinner, PageSpinner } from '@advitam/ui';
import { Path } from 'containers/App/constants';
import { useEntitySeed } from 'containers/Entities/useEntitySeed';

import ResourceCable, {
  ResourceDestroyModal,
  ResourceNavbarUsers,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { ResourceRoomType } from 'cables/Resource';
import ErrorBanner from 'components/ErrorBanner';
import { withDatasets } from 'slices/data';

import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectRawRegionalHealthAuthority,
} from './selectors';
import { fetchRegionalHealthAuthority } from './thunk';
import slice, { clearError, setRegionalHealthAuthority } from './slice';
import { REGIONAL_HEALTH_AUTHORITY_SKELETON } from './constants';

function Container(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const regionalHealthAuthority = useSelector(
    makeSelectRawRegionalHealthAuthority(),
  );
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());
  const seed = useEntitySeed();

  const { id } = useParams();
  const regionalHealthAuthorityId = id && parseInt(id, 10);
  const canRender = !isLoading && regionalHealthAuthority;

  const fetchResource = useCallback(() => {
    assert(
      regionalHealthAuthorityId !== undefined &&
        typeof regionalHealthAuthorityId === 'number',
    );
    dispatch(fetchRegionalHealthAuthority(regionalHealthAuthorityId));
  }, [dispatch, regionalHealthAuthorityId]);

  useEffect(() => {
    if (!regionalHealthAuthorityId) {
      if (seed) {
        dispatch(
          setRegionalHealthAuthority({
            ...REGIONAL_HEALTH_AUTHORITY_SKELETON,
            ...seed,
          }),
        );
      } else {
        navigate(Path.ENTITIES, { replace: true });
      }
    } else if (regionalHealthAuthorityId !== regionalHealthAuthority?.id) {
      fetchResource();
    }

    return () => {
      dispatch(clearError());
    };
  }, [regionalHealthAuthorityId]);

  return (
    <>
      {isSaving && <HardSpinner />}
      {canRender ? <Outlet /> : <PageSpinner />}
      {error && <ErrorBanner errors={error} />}
      {regionalHealthAuthorityId && (
        <ResourceCable
          resourceId={regionalHealthAuthorityId}
          resourceType={ResourceRoomType.REGIONAL_HEALTH_AUTHORITY}
        >
          <ResourceNavbarUsers />
          <ResourceUpdateModal onRefresh={fetchResource} />
          <ResourceDestroyModal path={Path.ENTITIES} />
        </ResourceCable>
      )}
    </>
  );
}

export default compose(
  withDatasets('funeralTeam'),
  withSlice(slice),
)(Container);
