import { useSelector } from 'react-redux';

import type { DealDocument } from 'models/Deal/Document';
import DocumentsEditor from 'containers/DocumentsEditor';
import HardSpinner from 'components/HardSpinner';
import ErrorModal, { ErrorProps } from 'components/ErrorModal';
import UploadFiles from 'components/UploadFiles/NoModal';

import { ResourceType } from '@advitam/api/models/DocumentEditions';
import { Arrays } from '@advitam/support';
import { makeSelectDeal } from 'containers/Deal/selectors';
import type { NewUploadResource } from 'containers/DocumentsEditor/types';
import { DealDocumentSection } from 'models/Deal/Document';

import { makeSelectDocumentList } from '../selectors';
import Section from './Section';

interface DocumentListProps {
  loading: boolean;
  error?: ErrorProps | null;
  dealUUID: string;
  refreshDocumentList: () => void;
}

type UploadFiles = (
  files: File[],
  resource: NewUploadResource,
) => void | Promise<void>;

const OREDERED_SECTIONS: string[] = [
  DealDocumentSection.FAMILY,
  DealDocumentSection.OTHERS,
  DealDocumentSection.RESOURCES,
  DealDocumentSection.ADMIN,
  DealDocumentSection.THIRD_PARTY,
  DealDocumentSection.ADVITAM,
  DealDocumentSection.SUPPLIER,
  'unexpected_document_upload',
];

export default function DocumentList({
  error,
  loading,
  dealUUID,
  refreshDocumentList,
}: DocumentListProps): JSX.Element {
  const deal = useSelector(makeSelectDeal());
  const documents = useSelector(makeSelectDocumentList()) as Record<
    string,
    DealDocument[]
  >;

  const sections = Object.keys(documents);
  const sortedSections = Arrays.intersection(
    OREDERED_SECTIONS,
    sections,
  ).concat(Arrays.difference(sections, OREDERED_SECTIONS));

  return (
    <div className="margin--10-0">
      {error && <ErrorModal error={error} />}
      {loading && <HardSpinner />}
      <DocumentsEditor afterSaveHook={refreshDocumentList}>
        {({ uploadFiles }) => (
          <>
            {dealUUID && (
              <UploadFiles
                postFiles={filesToUpload =>
                  uploadFiles(filesToUpload, {
                    type: ResourceType.DEAL,
                    deal: { uuid: deal?.uuid || '' },
                  })
                }
              />
            )}
            <div className="documents__content__documentsContainer">
              {sortedSections.map(category => (
                <Section
                  key={category}
                  section={category}
                  uploadFiles={uploadFiles}
                />
              ))}
            </div>
          </>
        )}
      </DocumentsEditor>
    </div>
  );
}
