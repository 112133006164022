import { authenticate } from '../../../lib/decorators'
import { get, post } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { AdministrativeProcedureJSON } from '../../../models/AdministrativeProcedure'
import type { DefunctJSON } from '../../../models/Defunct'
import type { AutocompleteResult } from '../../Autocompletes'
import { serialize } from './serializers'

export const AdministrativeProcedures = {
  index: (): ApiRequestDescriptor<AdministrativeProcedureJSON[]> =>
    authenticate(get('/api/v1/client/administrative_procedures')),

  create: (
    defunct: DefunctJSON,
    deathCity: AutocompleteResult,
    familyLink: string,
  ): ApiRequestDescriptor<AdministrativeProcedureJSON> =>
    authenticate(
      post('/api/v1/client/administrative_procedures', serialize(defunct, deathCity, familyLink)),
    ),
}
