import { ReactNode, useCallback, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import type { AutocompleteCityhallResult } from '@advitam/api/v1/Autocompletes';
import { FormUI } from '@advitam/ui';
import { Deal } from 'models/Deal';

import FlagSelect from './FlagSelect';

interface AutocompleteProps {
  label: ReactNode;
  error?: boolean;
}

export default function ApiBirthLocation({
  label,
  error,
}: AutocompleteProps): JSX.Element {
  const form = useForm();
  const { values } = useFormState<{ deal: Deal }>();
  const value = useMemo(
    () => ({
      id: 0,
      description: values.deal.defunctInfo?.person?.birthLocation || '',
      name: values.deal.defunctInfo?.person?.birthLocation || '',
      postal_code: values.deal.defunctInfo?.person?.birth_postal_code || '',
      insee_code: '',
    }),
    [values],
  );

  const onChange = useCallback(
    (r: AutocompleteCityhallResult | undefined): void => {
      form.batch(() => {
        form.change('deal.defunctInfo.person.birthLocation', r?.name);
        form.change(
          'deal.defunctInfo.person.birth_postal_code',
          r?.postal_code,
        );
        form.change('deal.defunctInfo.person.birth_country.code', 'FR');
      });
    },
    [form],
  );

  return (
    <FormUI.Autosuggest
      label={label}
      endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.cityhalls)}
      value={value}
      requestHeaders={{ ...getTokens() }}
      onChange={onChange}
      error={error}
      prefix={<FlagSelect />}
    />
  );
}
