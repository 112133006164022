import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import type { FlightJSON } from '@advitam/api/models/Flight';
import { assert, nextTick } from '@advitam/support';
import { Path } from 'containers/App/constants';

import { FLIGHT } from './constants';
import { NewFlight } from './types';
import { makeSelectRawFlight } from './selectors';
import { AppStateSubset, setFlight } from './slice';

export const fetchFlight = createAsyncThunk(
  `${FLIGHT}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(
        Api.V1.Suppliers.Warehouses.Flights.show(id),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createFlight = createAsyncThunk(
  `${FLIGHT}_CREATE`,
  async (flight: NewFlight, { rejectWithValue, dispatch }) => {
    try {
      const { body } = await request(
        Api.V1.Suppliers.Warehouses.Flights.create(flight),
      );

      dispatch(setFlight(body));
      nextTick(() => {
        nextTick(() => dispatch(replace(`./${body.id}`)));
      });

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateFlight = createAsyncThunk(
  `${FLIGHT}_UPDATE`,
  async (flight: FlightJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(
        Api.V1.Suppliers.Warehouses.Flights.update(flight),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const destroyFlight = createAsyncThunk(
  `${FLIGHT}_DESTROY`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const flight = makeSelectRawFlight()(state);
    assert(flight?.id !== undefined);

    try {
      await request(Api.V1.Suppliers.Warehouses.Flights.destroy(flight.id));
      dispatch(push(Path.FLIGHTS));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsFlightDisabled = createAsyncThunk(
  `${FLIGHT}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const flight = makeSelectRawFlight()(state);
    assert(flight?.id !== undefined);

    try {
      await request(
        Api.V1.Suppliers.Warehouses.Flights.Disabled.update(
          flight.id,
          disabled,
        ),
      );
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
