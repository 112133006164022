import { useCallback, useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import { HardSpinner, PageSpinner } from '@advitam/ui';

import { Path } from 'containers/App/constants';
import { useEntitySeed } from 'containers/Entities/useEntitySeed';
import ResourceCable, {
  ResourceDestroyModal,
  ResourceNavbarUsers,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { ResourceRoomType } from 'cables/Resource';
import ErrorBanner from 'components/ErrorBanner';
import { withDatasets } from 'slices/data';

import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectRawConsulate,
} from './selectors';
import { fetchConsulate } from './thunk';
import slice, { clearError, setConsulate } from './slice';
import { CONSULATE_SKELETON } from './constants';

function Container(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const consulate = useSelector(makeSelectRawConsulate());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());
  const seed = useEntitySeed();

  const { id } = useParams();
  const consulateId = id && parseInt(id, 10);
  const canRender = !isLoading && consulate;

  const fetchResource = useCallback(() => {
    assert(typeof consulateId === 'number');
    dispatch(fetchConsulate(consulateId));
  }, [dispatch, consulateId]);

  useEffect(() => {
    if (!id) {
      if (seed) {
        dispatch(setConsulate({ ...CONSULATE_SKELETON, ...seed }));
      } else {
        navigate(Path.ENTITIES, { replace: true });
      }
    } else if (consulateId !== consulate?.id) {
      fetchResource();
    }

    return () => {
      dispatch(clearError());
    };
  }, [id]);

  return (
    <>
      {isSaving && <HardSpinner />}
      {canRender ? <Outlet /> : <PageSpinner />}
      {error && <ErrorBanner errors={error} />}
      {consulateId && (
        <ResourceCable
          resourceId={consulateId}
          resourceType={ResourceRoomType.CONSULATE}
        >
          <ResourceNavbarUsers />
          <ResourceUpdateModal onRefresh={fetchResource} />
          <ResourceDestroyModal path={Path.ENTITIES} />
        </ResourceCable>
      )}
    </>
  );
}

export default compose(
  withDatasets('funeralTeam'),
  withSlice(slice),
)(Container);
