import { FocusEvent, ReactElement, ReactNode } from 'react'
import PhoneInput, { Value, isValidPhoneNumber } from 'react-phone-number-input'
import fr from 'react-phone-number-input/locale/fr.json'

import PhoneIcon from '../../../../images/icons/phone-alt.svg'
import { LinkButton } from '../../../Button'
import ErrorMessage from '../parts/ErrorMessage'
import Label from '../parts/Label'
import uiStyle from '../UI.module.scss'
import style from './Phone.module.scss'

interface PhoneProps {
  value?: HTMLInputElement['value']
  label?: ReactNode
  name?: string
  placeholder?: string
  error?: ReactElement | boolean
  disabled?: boolean
  tooltip?: ReactNode
  onChange: (v: Value) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  withCallButton?: boolean
  className?: string
  containerClassName?: string
}

export default function Phone({
  value,
  label,
  name,
  placeholder,
  error,
  disabled,
  tooltip,
  onChange,
  onBlur,
  withCallButton,
  className = '',
  containerClassName = '',
}: PhoneProps): JSX.Element {
  const showErrorMessage = Boolean(error) && error !== true

  const containerClasses = [uiStyle.field, containerClassName].filter(Boolean)
  const wrapperClasses = [
    style.wrapper,
    uiStyle.input,
    error && uiStyle.error,
    disabled && uiStyle.disabled,
    className,
  ].filter(Boolean)
  const inputClasses = [uiStyle.input_element, value && value.length > 0 && uiStyle.filled].filter(
    Boolean,
  )

  return (
    <div className={containerClasses.join(' ')}>
      <Label value={label} tooltip={tooltip} />
      <div className={style.outer}>
        <PhoneInput
          name={name}
          value={value}
          initialValueFormat="national"
          defaultCountry="FR"
          labels={fr}
          placeholder={placeholder}
          disabled={disabled}
          numberInputProps={{ className: inputClasses.join(' ') }}
          className={wrapperClasses.join(' ')}
          onBlur={onBlur}
          onChange={onChange}
        />
        {withCallButton && value && isValidPhoneNumber(value) && (
          <LinkButton outline href={`tel:${value}`} className={style.call} icon={<PhoneIcon />} />
        )}
      </div>
      {showErrorMessage && <ErrorMessage error={error} />}
    </div>
  )
}
