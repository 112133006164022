import type { IntlShape } from 'react-intl';

import type { FuneralTeamUserJSON } from '@advitam/api/models/FuneralTeamUser';
import type {
  DateRangeFilter,
  SearchFilter,
  SelectFilter,
} from '@advitam/ui/components/ResourceList/Filters/types';

import { DealState } from '@advitam/api/models/Deal/State';
import { DealType } from '@advitam/api/models/Deal/Type';
import { FuneralBrandJSON } from 'models/FuneralBrand';

import messages from './messages';
import { DealFilters } from './slice';

export const DEALS_LIST = 'DealsList';

export const DEALS_PER_PAGE = 25;

export const DEFAULT_FILTERS: DealFilters = {
  per_page: DEALS_PER_PAGE,
  deals_state_key_in: [DealState.QUOTE, DealState.ORDER],
};

export const STATE_FILTER_NAME = 'deals_state_key_in';
export const TYPE_FILTER_NAME = 'deal_type_eq';
export const FUNERAL_BRAND_FILTER_NAME = 'funeral_brand_id_eq';
export const USER_FILTER_NAME = 'user_id_eq';
export const DATE_FILTER_START_NAME = 'deal_date_gteq';
export const DATE_FILTER_END_NAME = 'deal_date_lteq';
export const SEARCH_FILTER_NAME = 'q';

const STATE_FILTER = (
  intl: IntlShape,
  initialValue?: DealState[],
): SelectFilter => ({
  type: 'Select',
  name: STATE_FILTER_NAME,
  placeholder: intl.formatMessage(messages.state),
  items: [
    { value: DealState.QUOTE, name: intl.formatMessage(messages.quote) },
    { value: DealState.ORDER, name: intl.formatMessage(messages.order) },
    { value: DealState.OK, name: intl.formatMessage(messages.ok) },
    { value: DealState.KO, name: intl.formatMessage(messages.ko) },
    {
      value: DealState.FUNERAL_CONTRACT,
      name: intl.formatMessage(messages.funeral_contract),
    },
    {
      value: DealState.FUNERAL_CONTRACT_OK,
      name: intl.formatMessage(messages.funeral_contract_ok),
    },
  ],
  initialValue,
  multiple: true,
});

const TYPE_FILTER = (
  intl: IntlShape,
  initialValue?: DealType,
): SelectFilter => ({
  type: 'Select',
  name: TYPE_FILTER_NAME,
  placeholder: intl.formatMessage(messages.type),
  items: [
    { value: DealType.FUNERAL, name: intl.formatMessage(messages.funeral) },
    { value: DealType.MARBLE, name: intl.formatMessage(messages.marble) },
    { value: DealType.ITEM, name: intl.formatMessage(messages.item) },
  ],
  initialValue,
});

const FUNERAL_BRAND_FILTER = (
  intl: IntlShape,
  brands: FuneralBrandJSON[],
  initialValue?: number,
): SelectFilter => ({
  type: 'Select',
  name: FUNERAL_BRAND_FILTER_NAME,
  placeholder: intl.formatMessage(messages.brand),
  items: brands.map(({ id, name }) => ({ value: id, name })),
  initialValue,
});

const USER_FILTER = (
  intl: IntlShape,
  team: FuneralTeamUserJSON[],
  initialValue?: number,
): SelectFilter => ({
  type: 'Select',
  name: USER_FILTER_NAME,
  placeholder: intl.formatMessage(messages.advisor),
  items: team
    .filter(({ disabled }) => !disabled)
    .map(({ id, name, email }) => ({ value: id, name: name || email })),
  initialValue,
});

const DATE_FILTER = (
  intl: IntlShape,
  initialStartValue?: Date,
  initialEndValue?: Date,
): DateRangeFilter => ({
  type: 'DateRange',
  startName: DATE_FILTER_START_NAME,
  endName: DATE_FILTER_END_NAME,
  placeholder: intl.formatMessage(messages.dates),
  initialStartValue,
  initialEndValue,
});

const SEARCH_FILTER = (
  intl: IntlShape,
  initialValue?: string,
): SearchFilter => ({
  name: SEARCH_FILTER_NAME,
  placeholder: intl.formatMessage(messages.search),
  initialValue,
});

export const FILTERS = {
  STATE: STATE_FILTER,
  TYPE: TYPE_FILTER,
  FUNERAL_BRAND: FUNERAL_BRAND_FILTER,
  USER: USER_FILTER,
  DATE: DATE_FILTER,
  SEARCH: SEARCH_FILTER,
};
