import { createSelector, Selector } from 'reselect';

import { SerializedApiError } from '@advitam/api';
import {
  DealDocumentBatchDocumentJSON,
  DealDocumentBatchRecipientJSON,
} from '@advitam/api/models/Deal/Document/Batch';
import { DocumentGenerationType } from '@advitam/api/models/Documents/Editable/GenerationType';
import { BatchEntityRecipient } from '@advitam/api/v1/Deals/Documents/types';

import { makeSelectDocuments as makeSelectDealDocuments } from 'containers/Documents/selectors.js';
import { DealDocument } from 'models/Deal/Document';

import { SEND_BATCH_MODAL } from './constants';
import { AppStateSubset, State } from './slice';
import { PartialBatchDocument, SendBatchForm, StateBatch } from './types';
import { buildEntityRecipients } from './utils';

type SendBatchModalSelector<T> = Selector<AppStateSubset, T>;

function selectSendBatchModalDomain(state: AppStateSubset): State {
  return state[SEND_BATCH_MODAL];
}

export function makeSelectBatch(): SendBatchModalSelector<StateBatch | null> {
  return createSelector(selectSendBatchModalDomain, ({ batch }) => batch);
}

export function makeSelectIsOpen(): SendBatchModalSelector<boolean> {
  return createSelector(selectSendBatchModalDomain, ({ isOpen }) => isOpen);
}

export function makeSelectIsLoading(): SendBatchModalSelector<boolean> {
  return createSelector(
    selectSendBatchModalDomain,
    ({ isLoading }) => isLoading,
  );
}

export function makeSelectIsSending(): SendBatchModalSelector<boolean> {
  return createSelector(
    selectSendBatchModalDomain,
    ({ isSending }) => isSending,
  );
}

export function makeSelectError(): SendBatchModalSelector<SerializedApiError | null> {
  return createSelector(selectSendBatchModalDomain, ({ error }) => error);
}

export function makeSelectDocuments(): SendBatchModalSelector<
  Array<DealDocumentBatchDocumentJSON | PartialBatchDocument>
> {
  return createSelector(makeSelectBatch(), batch => batch?.documents || []);
}

export function makeSelectDocumentsToGenerate(): SendBatchModalSelector<
  DealDocument[]
> {
  return createSelector(
    makeSelectDealDocuments(),
    makeSelectDocuments(),
    (dealDocuments, batchDocuments) =>
      (dealDocuments as DealDocument[]).filter(dealDocument => {
        const isInBatch = batchDocuments.some(
          batchDocument => batchDocument.deal_document_id === dealDocument.id,
        );

        return (
          isInBatch &&
          !dealDocument.locked &&
          !dealDocument.template_id &&
          dealDocument.document &&
          dealDocument.document.generation_type !==
            DocumentGenerationType.IDENTICAL
        );
      }),
  );
}

export function makeSelectRecipients(): SendBatchModalSelector<
  DealDocumentBatchRecipientJSON[]
> {
  return createSelector(makeSelectBatch(), batch => batch?.recipients || []);
}

function makeSelectSubject(): SendBatchModalSelector<string> {
  return createSelector(makeSelectBatch(), batch => batch?.subject || '');
}

function makeSelectContent(): SendBatchModalSelector<string> {
  return createSelector(makeSelectBatch(), batch => batch?.content || '');
}

function makeSelectRecipientEntities(): SendBatchModalSelector<
  BatchEntityRecipient[]
> {
  return createSelector(makeSelectRecipients(), recipients =>
    recipients.flatMap(recipient => buildEntityRecipients(recipient)),
  );
}

export function makeSelectFormInitialValues(): SendBatchModalSelector<
  SendBatchForm
> {
  return createSelector(
    makeSelectContent(),
    makeSelectRecipientEntities(),
    makeSelectSubject(),
    (content, recipientEntities, subject) => ({
      content,
      recipientEntities,
      subject,
    }),
  );
}
