import { FormattedDate, FormattedMessage } from 'react-intl';

import { DealType } from '@advitam/api/models/Deal/Type';
import { ResourceList, Tooltip, FormattedPrice } from '@advitam/ui';
import Later from '@advitam/ui/images/icons/later.svg';

import { DEALS_PATH } from 'containers/App/constants';
import BusinessUserBadge from 'components/BusinessUserBadge';
import DealStateFlag from 'components/DealStateFlag';
import { Summary } from 'models/Deal/Summary';
import CrossIcon from 'images/cross.svg';
import FlowerIcon from 'images/couronne-fleurs.svg';
import TombstoneIcon from 'images/tombstone.svg';

import style from '../DealsList.module.scss';
import messages from '../messages';

const DEAL_TYPE_ICON = {
  [DealType.FUNERAL]: <CrossIcon />,
  [DealType.ITEM]: <FlowerIcon />,
  [DealType.MARBLE]: <TombstoneIcon />,
};

interface DealRowProps {
  deal: Summary;
}

export default function DealRow({ deal }: DealRowProps): JSX.Element {
  return (
    <ResourceList.Row
      className={style.row}
      link={{ href: `${DEALS_PATH}/${deal.uuid}`, internal: true }}
    >
      <ResourceList.Cell className={style.status}>
        <Tooltip
          contentClassName={style.icon}
          content={<FormattedMessage id={messages[deal.deal_type].id} />}
        >
          {DEAL_TYPE_ICON[deal.deal_type]}
        </Tooltip>
        <DealStateFlag
          state={deal.state}
          paid={deal.paid}
          koReason={deal.ko_reason}
        />
        {deal.remind_at && (
          <Tooltip
            content={
              <FormattedMessage
                id={messages.reminder.id}
                values={{
                  date: (
                    <FormattedDate
                      value={deal.remind_at}
                      weekday="short"
                      day="numeric"
                      month="short"
                      year="numeric"
                      hour="2-digit"
                      minute="2-digit"
                    />
                  ),
                }}
              />
            }
          >
            <span className={style.reminder}>
              <Later />
            </span>
          </Tooltip>
        )}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.defunct}>
        {deal?.defunct?.name}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.client}>
        {deal?.client?.name}
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>{deal.business_id}</ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        {deal.invoice_business_id}
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnMobile>
        <FormattedPrice value={deal.price} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedPrice value={deal.payments.accepted_total} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <BusinessUserBadge userId={deal.user_id} />
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
