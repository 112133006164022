import { assert } from '@advitam/support';
import { Session, withSessionId } from '@advitam/api';
import type { DealType } from '@advitam/api/models/Deal/Type';
import type { DealState } from '@advitam/api/models/Deal/State';
import { PaymentReceived } from '@advitam/api/models/Payment/Received';

import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get, post, put } from 'api/methods';
import { Deals as DealSerializer } from 'api/serializers/Deals';

import { Deal, DealJSON } from 'models/Deal';
import { SummaryJSON } from 'models/Deal/Summary';
import { ServiceType } from 'models/Deal/Service/Type';

import Abilities from './Abilities';
import Documents from './Documents';
import Items from './Items';
import Marbles from './Marbles';
import State from './State';
import Layers from './Layers';
import EmpoweredClient from './EmpoweredClient';
import Owner from './Owner';
import Memorial from './Memorial';
import { Payments } from './Payments';
import { Welcome } from './Welcome';
import { PaymentProblem } from './PaymentProblem';
import Reminder from './Reminder';

export interface FuneralDealPayload {
  deal: DealJSON;
  uuid: string;
  deal_type: DealType;
}

export const enum SortOrder {
  RECENT_FIRST = 'recent_first',
  OLD_FIRST = 'old_first',
}

export interface Filters {
  deals_state_key_in?: DealState[];
  deal_type_eq?: DealType;
  payment_received_cont?: PaymentReceived;
  payment_received_not_cont_all?: Array<PaymentReceived>;
  funeral_brand_id_eq?: number;
  user_id_eq?: number;
  payment_problem_in?: PaymentProblem[];
  paid_eq?: boolean;
  deal_date_gteq_datetime?: string;
  deal_date_lteq_datetime?: string;
  q?: string;
  sort?: SortOrder;
  page?: number;
  per_page?: number;
}

export interface TriggerPingService {
  type: ServiceType;
  step_id: number;
}

export default class Deals {
  static readonly Abilities = Abilities;

  static readonly Documents = Documents;

  static readonly Items = Items;

  static readonly Marbles = Marbles;

  static readonly State = State;

  static readonly Layers = Layers;

  static readonly EmpoweredClient = EmpoweredClient;

  static readonly Owner = Owner;

  static readonly Memorial = Memorial;

  static readonly Payments = Payments;

  static readonly Reminder = Reminder;

  static readonly Welcome = Welcome;

  static readonly PaymentProblem = PaymentProblem;

  @authenticate
  static index(filters?: Filters): ApiRequestDescriptor<SummaryJSON[]> {
    return get('/api/v1/deals', filters);
  }

  @authenticate
  static get(id: number | string): ApiRequestDescriptor<FuneralDealPayload> {
    return get(`/api/v1/deals/${id}`);
  }

  @authenticate
  @withSessionId
  static create(
    deal: Deal | DealJSON,
    triggerPingServices?: TriggerPingService[],
  ): ApiRequestDescriptor<FuneralDealPayload> {
    return post('/api/v1/deals', {
      deal,
      _session_id: Session.SESSION_ID,
      _trigger_ping_services: triggerPingServices,
    });
  }

  @authenticate
  @withSessionId
  static update(
    deal: Deal | DealJSON,
    triggerPingServices?: TriggerPingService[],
  ): ApiRequestDescriptor<FuneralDealPayload> {
    assert(deal.uuid !== undefined);
    const serializedDeal = DealSerializer.Funeral.serialize(deal);

    return put(`/api/v1/deals/${deal.uuid}`, {
      deal: serializedDeal.deal,
      _trigger_ping_services: triggerPingServices,
    });
  }

  static updateOrCreate(
    deal: Deal | DealJSON,
    triggerPingServices?: TriggerPingService[],
  ): ApiRequestDescriptor<FuneralDealPayload> {
    if (deal.uuid) {
      return Deals.update(deal, triggerPingServices);
    }
    return Deals.create(deal, triggerPingServices);
  }
}
