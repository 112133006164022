import { Abilities } from './Abilities'
import { BookingTransactions } from './BookingTransactions'
import { Brand } from './Brand'
import { Communications } from './Communications'
import { Defuncts } from './Defuncts'
import { Documents } from './Documents'
import { Duplicate } from './Duplicate'
import { EmpoweredClient } from './EmpoweredClient'
import { Owner } from './Owner'
import { Reviews } from './Reviews'
import { State } from './State'
import { User } from './User'
import { TodoItems } from './TodoItems'
import { Welcome } from './Welcome'

export const Deals = {
  Abilities,
  BookingTransactions,
  Brand,
  Communications,
  Defuncts,
  Documents,
  Duplicate,
  EmpoweredClient,
  Owner,
  Reviews,
  State,
  User,
  TodoItems,
  Welcome,
}
