import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import { EntityType } from '@advitam/api/models/Entity/EntityType';
import { LocationType } from '@advitam/api/models/LocationType';
import { CityhallAutocomplete } from '@advitam/integration';
import {
  ContentModal,
  FormLayout,
  FormUI,
  HiddenInput,
  ModalTitle,
  Select,
  SubmitButton,
} from '@advitam/ui';
import { NEW_PATH, Path } from 'containers/App/constants';

import { EntitySeed } from '../../useEntitySeed';
import { makeSelectIsCreationModalOpen } from '../../selectors';
import { setIsCreationModalOpen } from '../../slice';
import messages from './messages';
import type { CreationForm } from './types';
import { ENTITY_TEXTS } from '../EntityIcon';
import style from './CreationModal.module.scss';

const ENDPOINTS: Record<EntityType, string> = {
  [LocationType.AIRPORT]: Api.V1.Autocompletes.Path.airports.index,
  [LocationType.CITYHALL]: Api.V1.Autocompletes.Path.cityhalls,
  [LocationType.CONSULATE]: Api.V1.Autocompletes.Path.consulates,
  [LocationType.CREMATORIUM]: Api.V1.Autocompletes.Path.crematoriums,
  [LocationType.FUNERAL_PARLOR]: Api.V1.Autocompletes.Path.funeralParlors,
  [LocationType.GRAVEYARD]: Api.V1.Autocompletes.Path.graveyards,
  [LocationType.HOSPITAL]: Api.V1.Autocompletes.Path.hospitals,
  [LocationType.POLICE]: Api.V1.Autocompletes.Path.police,
  [LocationType.PREFECTURE]: Api.V1.Autocompletes.Path.prefectures,
  [LocationType.REGIONAL_HEALTH_AUTHORITY]: Api.V1.Autocompletes.Path.rha,
  [LocationType.WORSHIP]: Api.V1.Autocompletes.Path.worships,
};

const CRUD_PATHS: Record<EntityType, string> = {
  [LocationType.CONSULATE]: Path.CONSULATES,
  [LocationType.CREMATORIUM]: Path.CREMATORIUMS,
  [LocationType.FUNERAL_PARLOR]: Path.FUNERAL_PARLORS,
  [LocationType.GRAVEYARD]: Path.GRAVEYARDS,
  [LocationType.HOSPITAL]: Path.HOSPITALS,
  [LocationType.POLICE]: Path.POLICE,
  [LocationType.PREFECTURE]: Path.PREFECTURES,
  [LocationType.REGIONAL_HEALTH_AUTHORITY]: Path.RHAS,
  [LocationType.WORSHIP]: Path.WORSHIPS,
  [LocationType.CITYHALL]: '',
  [LocationType.AIRPORT]: '',
};

export default function CreationModal(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const isOpen = useSelector(makeSelectIsCreationModalOpen());

  const onClose = useCallback(() => {
    dispatch(setIsCreationModalOpen(false));
  }, [dispatch]);

  const onSubmit = useCallback(
    (values: CreationForm): void => {
      onClose();
      const seed: EntitySeed = {
        name: values.name,
        postal_code: values.city.postal_code,
        insee_code: values.city.insee_code,
        city: values.city.name,
      };
      navigate(`${CRUD_PATHS[values.type]}/${NEW_PATH}`, {
        state: { seed },
      });
    },
    [onClose, navigate],
  );

  return (
    <ContentModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`col-6 ${style.modal}`}
      overlayClassName={style.overlay}
    >
      <ModalTitle>
        <FormattedMessage id={messages.title.id} />
      </ModalTitle>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form, values }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <FormLayout.Row>
              <Select
                required
                name="type"
                label={<FormattedMessage id={messages.typeLabel.id} />}
                items={Object.entries(
                  omit(
                    ENTITY_TEXTS,
                    LocationType.AIRPORT,
                    LocationType.CITYHALL,
                  ),
                ).map(([value, message]) => ({
                  value,
                  name: intl.formatMessage(message),
                }))}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <CityhallAutocomplete
                required
                name="city"
                disabled={!values.type}
                label={<FormattedMessage id={messages.cityLabel.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <FormUI.Autosuggest
                expandOnTop
                disabled={!values.city}
                label={<FormattedMessage id={messages.nameLabel.id} />}
                endpoint={Api.V1.absolute(ENDPOINTS[values.type])}
                requestHeaders={{ ...getTokens() }}
                searchParams={{ insee_code_eq: values.city?.insee_code }}
                onInput={(event): void => {
                  form.change('name', (event.target as HTMLInputElement).value);
                }}
                onChange={(result): void => {
                  if (result) {
                    onClose();
                    navigate(`${CRUD_PATHS[values.type]}/${result.id}`);
                  }
                }}
              />
              <HiddenInput name="name" />
            </FormLayout.Row>
            <SubmitButton
              primary
              text={<FormattedMessage id={messages.submit.id} />}
            />
          </form>
        )}
      </Form>
    </ContentModal>
  );
}
