import { defineMessages } from 'react-intl';

export default defineMessages({
  createDeal: { id: 'app.containers.DealsList.createDeal' },
  defunctName: { id: 'app.containers.DealsList.defunctName' },
  clientName: { id: 'app.containers.DealsList.clientName' },
  quote: { id: 'app.containers.DealsList.quote' },
  order: { id: 'app.containers.DealsList.order' },
  ok: { id: 'app.containers.DealsList.ok' },
  paid: { id: 'app.containers.DealsList.paid' },
  ko: { id: 'app.containers.DealsList.ko' },
  staging: { id: 'app.containers.DealsList.staging' },
  funeral_contract: { id: 'app.containers.DealsList.funeral_contract' },
  funeral_contract_ok: { id: 'app.containers.DealsList.funeral_contract_ok' },
  invoiceBusinessId: { id: 'app.containers.DealsList.invoiceBusinessId' },
  businessId: { id: 'app.containers.DealsList.businessId' },
  price: { id: 'app.containers.DealsList.price' },
  state: { id: 'app.containers.DealsList.state' },
  type: { id: 'app.containers.DealsList.type' },
  funeral: { id: 'app.containers.DealsList.funeral' },
  marble: { id: 'app.containers.DealsList.marble' },
  item: { id: 'app.containers.DealsList.item' },
  brand: { id: 'app.containers.DealsList.brand' },
  advisor: { id: 'app.containers.DealsList.advisor' },
  dates: { id: 'app.containers.DealsList.dates' },
  search: { id: 'app.containers.DealsList.search' },
  reminder: { id: 'app.containers.DealsList.reminder' },
});
