import type { Middleware } from '@reduxjs/toolkit'
import type { ReactNode } from 'react'
import { Provider } from 'react-redux'

import { useDeepCompareMemo } from '../src/hooks/useDeepCompareMemo'
import configureStore from './configureStore'
import createReducer from './createReducer'
import type { Store } from './Store'

interface StoreProviderProps {
  children?: ReactNode
  state?: Record<string, unknown>
  middlewares?: Middleware[]
  extendStore?: (store: Store) => void
}

export function StoreProvider({
  children,
  state,
  middlewares,
  extendStore,
}: StoreProviderProps): JSX.Element {
  // TODO: Reconfiguring the store in render triggers a bad setState error
  const store = useDeepCompareMemo(
    () => configureStore(createReducer(state || {}), middlewares, state, extendStore),
    [state],
  )

  return <Provider store={store}>{children}</Provider>
}
