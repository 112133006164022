import { DateTime } from 'luxon';
import { useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Civility } from '@advitam/api/models/Person/Civility';
import { assert } from '@advitam/support';
import {
  Collapsible,
  DateOnlyInput,
  DimensionsInput,
  FormLayout,
  GooglePlaceInput,
  Input,
  NumberInput,
  Select,
  Text,
  Tooltip,
} from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import Cross from '@advitam/ui/images/illustrations/cross.svg';
import { formatMaritalStatus } from 'components/Format/Defunct';
import { messages as crudMessages } from 'containers/Crud';
import actionMessages from 'messages/actions';
import { Deal } from 'models/Deal';
import { MaritalStatus } from 'models/Person/MaritalStatus';
import { makeSelectWorshipTypes } from 'slices/data';

import type { FuneralForm } from '../../../Funeral/types';
import DefunctBirthPlaceInput from './DefunctBirthPlaceInput';
import messages from './messages';
import style from './Form.module.scss';

function timestampToDateTime(value: string): DateTime {
  return DateTime.fromSeconds((value as unknown) as number)
    .setZone('utc')
    .setZone('local', { keepLocalTime: true });
}

function timestampFromDateTime(value: DateTime): string {
  return (value
    .setZone('utc', { keepLocalTime: true })
    .toUnixInteger() as unknown) as string;
}

export default function Defunct(): JSX.Element {
  const intl = useIntl();

  const { values } = useFormState<FuneralForm>();
  const deal = new Deal(values.deal);
  assert(deal?.defunct !== undefined);
  const { defunct } = deal;
  const defunctAge =
    typeof defunct.person.birthDate === 'number' && defunct.age;
  const worshipTypes = useSelector(makeSelectWorshipTypes());

  function makeMaritalStatusOption(
    value: MaritalStatus,
  ): SelectableItem<MaritalStatus> {
    return {
      name: formatMaritalStatus(
        { type: defunct.person.type, marital_status: value },
        intl,
      ),
      value,
    };
  }

  return (
    <Collapsible
      isOpen={false}
      className={style.fieldset}
      title={
        defunct && (
          <div className={style.section_title}>
            <b>{defunct?.formattedName(intl)}</b>
            {defunctAge !== null && defunctAge !== false && (
              <Text className={style.defunct_age}>
                &nbsp;{'- '}
                <FormattedMessage
                  id={messages.defunctAge.id}
                  values={{ age: defunctAge }}
                />
              </Text>
            )}
            <Tooltip
              contentClassName={style.section_pictogram}
              content={<FormattedMessage id={messages.defunctTooltip.id} />}
            >
              <Cross />
            </Tooltip>
          </div>
        )
      }
    >
      <FormLayout.Row>
        <Select
          label={<FormattedMessage id={messages.civility.id} />}
          name="deal.defunctInfo.person.type"
          unselectable
          items={[
            { name: intl.formatMessage(messages.mr), value: Civility.MR },
            { name: intl.formatMessage(messages.mrs), value: Civility.MRS },
          ]}
        />
        <Input
          label={<FormattedMessage id={messages.firstname.id} />}
          name="deal.defunctInfo.person.firstName"
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={messages.lastname.id} />}
          name="deal.defunctInfo.person.lastName"
        />
        <Input
          label={<FormattedMessage id={messages.birthName.id} />}
          name="deal.defunctInfo.person.birthName"
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <GooglePlaceInput
          label={<FormattedMessage id={messages.address.id} />}
          name="deal.defunctInfo.person.address"
          onlyFrance={false}
        />
        <Input
          label={<FormattedMessage id={messages.city.id} />}
          name="deal.defunctInfo.person.address.city"
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={messages.postalCode.id} />}
          name="deal.defunctInfo.person.address.postal_code"
        />
        <Select
          label={<FormattedMessage id={messages.maritalStatus.id} />}
          name="deal.defunctInfo.person.marital_status"
          unselectable
          items={[
            makeMaritalStatusOption(MaritalStatus.SINGLE),
            makeMaritalStatusOption(MaritalStatus.COHABITEE),
            makeMaritalStatusOption(MaritalStatus.MARRIED),
            makeMaritalStatusOption(MaritalStatus.PACSED),
            makeMaritalStatusOption(MaritalStatus.DIVORCED),
            makeMaritalStatusOption(MaritalStatus.WIDOW),
          ]}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <DateOnlyInput
          label={<FormattedMessage id={messages.birthDate.id} />}
          name="deal.defunctInfo.person.birthDate"
          format={{
            toDateTime: timestampToDateTime,
            fromDateTime: timestampFromDateTime,
          }}
        />
        <DefunctBirthPlaceInput
          label={<FormattedMessage id={messages.birthPlace.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={messages.nationality.id} />}
          name="deal.defunctInfo.person.nationality"
        />
        <Input
          label={<FormattedMessage id={messages.work.id} />}
          name="deal.defunctInfo.person.work"
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          label={<FormattedMessage id={messages.worship.id} />}
          name="deal.defunctInfo.defunctCompInfo.worship_type_id"
          unselectable
          items={worshipTypes.map(worshipType => ({
            name: worshipType.name,
            value: worshipType.id,
          }))}
        />
        <Input
          label={<FormattedMessage id={messages.decoration.id} />}
          name="deal.defunctInfo.defunctCompInfo.decoration"
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <NumberInput
          label={<FormattedMessage id={messages.weight.id} />}
          name="deal.defunctInfo.defunctCompInfo.weight"
          suffix={<FormattedMessage id={crudMessages.unitKilogram.id} />}
        />
        <DimensionsInput
          label={<FormattedMessage id={messages.size.id} />}
          tooltip={
            <FormattedMessage
              id={messages.sizeTooltip.id}
              values={{ br: <br /> }}
            />
          }
          placeholder={intl.formatMessage(messages.sizePlaceholder)}
          names={[
            'deal.defunctInfo.defunctCompInfo.size',
            'deal.defunctInfo.defunctCompInfo.width',
            'deal.defunctInfo.defunctCompInfo.height',
          ]}
          suffix={<FormattedMessage id={crudMessages.unitCentimeter.id} />}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Select
          label={<FormattedMessage id={messages.poweredDevice.id} />}
          name="deal.defunctInfo.defunctCompInfo.poweredDevice"
          unselectable
          items={[
            { name: intl.formatMessage(actionMessages.yes), value: true },
            { name: intl.formatMessage(actionMessages.no), value: false },
          ]}
        />
        <Select
          label={<FormattedMessage id={messages.guardianship.id} />}
          tooltip={<FormattedMessage id={messages.guardianshipTooltip.id} />}
          name="deal.defunctInfo.defunctCompInfo.on_guardianship"
          unselectable
          items={[
            { name: intl.formatMessage(actionMessages.yes), value: true },
            { name: intl.formatMessage(actionMessages.no), value: false },
          ]}
        />
      </FormLayout.Row>
    </Collapsible>
  );
}
