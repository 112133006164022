import {
  RepatriationTrip,
  RepatriationTripContact,
  RepatriationTripFlight,
} from './types';

export const TRIP_FLIGHT_SKELETON: RepatriationTripFlight = {
  id: null,
  flightNumber: null,
  departureInfo: {
    airport: null,
    date: null,
  },
  arrivalInfo: {
    airport: null,
    date: null,
  },
};

export const TRIP_CONTACT_SKELETON: RepatriationTripContact = {
  isCompany: false,
  infoCompany: {
    firstName: '',
    lastName: '',
    type: null,
    contact: {},
    address: {},
    birthLocation: null,
  },
  infoCivilian: {
    firstName: '',
    lastName: '',
    type: null,
    contact: {},
    address: {},
    birthLocation: null,
  },
};

export const TRIP_SKELETON: RepatriationTrip = {
  air_company: null,
  contact: null,
  crossed_borders: [],
  flight_id: null,
  flights: null,
  interment_place: null,
  lta: null,
  nbFlight: null,
};
