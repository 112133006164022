import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';

import type { DealDocumentBatchRecipientJSON } from '@advitam/api/models/Deal/Document/Batch';
import { ContactMedia } from '@advitam/api/models/Entity/ContactMedia';
import { FormUI } from '@advitam/ui';
import { useThunkDispatch } from '@advitam/react';
import { assert } from '@advitam/support';

import { makeSelectBatch, makeSelectIsSending } from '../../selectors';
import { SendBatchForm, SendingMethodKey } from '../../types';
import { getRecipientUniqueId, parseSendingKeyMethod } from '../../utils';
import AutocompletesItem from '../Item';
import messages from './messages';
import { makeSelectIsRecipientLoading } from './selectors';
import { destroyRecipient } from './thunk';
import {
  makeSendingMethodOptions,
  getValidSendingMethodKeys,
  getInitialMethodKeys,
} from './utils';
import style from './Recipients.module.scss';

interface RecipientProps {
  recipient: DealDocumentBatchRecipientJSON;
}

export default function Recipient({ recipient }: RecipientProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useThunkDispatch();
  const batch = useSelector(makeSelectBatch());
  assert(batch !== null);
  const isLoading = useSelector(makeSelectIsRecipientLoading(recipient));
  const isSending = useSelector(makeSelectIsSending());

  const { change } = useForm<SendBatchForm>();
  const { values } = useFormState<SendBatchForm>();

  const formMethodValues = useMemo(
    () =>
      values.recipientEntities.reduce<string[]>((methodValues, entity) => {
        if (!entity.method_value) {
          return methodValues;
        }

        if (getRecipientUniqueId(recipient) !== getRecipientUniqueId(entity)) {
          return methodValues;
        }

        return [...methodValues, entity.method_value];
      }, []),
    [values, recipient],
  );

  const [selectedMethodKeys, setSelectedMethodKeys] = useState<
    SendingMethodKey[]
  >(getInitialMethodKeys(recipient));

  const sendingMethodOptions = useMemo(() => {
    const validSendingMethodKeys = getValidSendingMethodKeys(recipient, batch);
    const options = makeSendingMethodOptions(
      intl,
      recipient,
      validSendingMethodKeys,
    );

    const emailKeys = validSendingMethodKeys.filter(key =>
      key.includes(ContactMedia.EMAIL),
    );

    if (emailKeys.length > 1) {
      options.unshift({
        value: emailKeys,
        name: intl.formatMessage(messages.emailAll),
      });
    }

    return options;
  }, [intl, batch, recipient]);

  const onDestroy = useCallback(async () => {
    try {
      await dispatch(
        destroyRecipient({ id: recipient.id, type: recipient.type }),
      );

      const newRecipients = values.recipientEntities.filter(
        r => getRecipientUniqueId(r) !== getRecipientUniqueId(recipient),
      );

      change('recipientEntities', newRecipients);
    } catch {
      // let the slice handle
    }
  }, [change, dispatch, values, recipient]);

  const onChange = useCallback(
    (value?: SendingMethodKey[] | SendingMethodKey[][]): void => {
      if (!value) {
        return;
      }

      const newSelectedMethodKeys = value.flat();
      const newRecipients = values.recipientEntities.filter(
        r => getRecipientUniqueId(r) !== getRecipientUniqueId(recipient),
      );

      newSelectedMethodKeys.forEach(method => {
        const methodValue = recipient[method];
        assert(methodValue !== null);

        newRecipients.push({
          ...recipient,
          method: parseSendingKeyMethod(method),
          method_value: methodValue,
        });
      });

      change('recipientEntities', newRecipients);
      setSelectedMethodKeys(newSelectedMethodKeys);
    },
    [change, values, recipient],
  );

  return (
    <AutocompletesItem
      isLoading={isLoading}
      actions={
        <FormUI.Select<SendingMethodKey[]>
          contained={false}
          value={selectedMethodKeys}
          onChange={onChange}
          disabled={isLoading || isSending}
          items={sendingMethodOptions}
          className={style.select}
        />
      }
      onRemove={onDestroy}
    >
      <b>{recipient.name}</b>
      {formMethodValues.length > 0 && ` (${formMethodValues.join(', ')})`}
    </AutocompletesItem>
  );
}
