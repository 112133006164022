import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import messages from './messages';

export function GraveyardBasicInfos({ graveyardBasicInfos }) {
  if (Object.keys(graveyardBasicInfos).length === 0) {
    return null;
  }

  return (
    <div className="graveyardBasicInfos">
      {(graveyardBasicInfos.semelle_length !== null ||
        graveyardBasicInfos.semelle_width !== null) && (
        <div>
          <div className="graveyardBasicInfos__label">
            <FormattedMessage id={messages.dimensions.id} />
          </div>
          <div>
            <FormattedMessage
              id={messages.dimensionsValues.id}
              values={{
                length: graveyardBasicInfos.semelle_length,
                width: graveyardBasicInfos.semelle_width,
              }}
            />
          </div>
        </div>
      )}
      {graveyardBasicInfos.concession_semelle &&
        graveyardBasicInfos.concession_semelle.semelle_type !== null && (
          <div>
            <div className="graveyardBasicInfos__label">
              <FormattedMessage id={messages.semelleType.id} />
            </div>
            <div>
              <FormattedMessage
                id={
                  messages[graveyardBasicInfos.concession_semelle.semelle_type]
                    .id
                }
              />
            </div>
          </div>
        )}
      {graveyardBasicInfos.required_semelle_shape !== null && (
        <div>
          <div className="graveyardBasicInfos__label">
            <FormattedMessage id={messages.semelleShape.id} />
          </div>
          <div>
            <FormattedMessage
              id={messages[graveyardBasicInfos.required_semelle_shape].id}
            />
          </div>
        </div>
      )}
      {graveyardBasicInfos.graveyard_type !== null && (
        <div>
          <div className="graveyardBasicInfos__label">
            <FormattedMessage id={messages.graveyardType.id} />
          </div>
          <div>
            <FormattedMessage
              id={messages[graveyardBasicInfos.graveyard_type].id}
            />
          </div>
        </div>
      )}
      {graveyardBasicInfos.vault_mandatory !== null && (
        <div>
          <div className="graveyardBasicInfos__label">
            <FormattedMessage id={messages.vaultMandatory.id} />
          </div>
          <div>
            <FormattedMessage
              id={messages[graveyardBasicInfos.vault_mandatory].id}
            />
          </div>
        </div>
      )}
      {graveyardBasicInfos.fausse_case !== null && (
        <div>
          <div className="graveyardBasicInfos__label">
            <FormattedMessage id={messages.fausseCase.id} />
          </div>
          <div>
            <FormattedMessage
              id={messages.fausseCaseValue.id}
              values={{
                time: graveyardBasicInfos.fausse_case,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

GraveyardBasicInfos.propTypes = {
  /** basic infos of selected graveyard */
  graveyardBasicInfos: PropTypes.object,
};

export default GraveyardBasicInfos;
