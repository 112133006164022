import { authenticate, chatbotApiEndpoint } from '../../../lib/decorators'
import { post } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { ChatbotConversationJSON } from '../../../models/Chatbot/Conversation'

export const Conversations = {
  show: (conversationUuid: string): ApiRequestDescriptor<ChatbotConversationJSON> =>
    chatbotApiEndpoint(post(`/v1/conversations/${conversationUuid}`)),
  create: (ai_agent_slug: string): ApiRequestDescriptor<ChatbotConversationJSON> =>
    chatbotApiEndpoint(authenticate(post('/v1/conversations', { ai_agent_slug }))),
}
