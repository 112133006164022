import { Model } from 'models/Model';
import { DocumentType } from 'models/DocumentType';

import { PolymorphicId } from '../../PolymorphicId';
import { Document } from '../../Document';
import { DealDocumentVersion, DealDocumentVersionJSON } from './Version';

export enum DealDocumentSource {
  TEMPLATE = 'template',
  UNIQUE = 'unique',
  UPLOAD = 'upload',
  USER_UPLOAD = 'user_upload',
  EDITABLE = 'editable',
}

export enum DealDocumentSection {
  SUPPLIER = 'supplier',
  FAMILY = 'family',
  ADMIN = 'admin',
  ADVITAM = 'advitam',
  RESOURCES = 'ressources',
  THIRD_PARTY = 'third_party',
  OTHERS = 'others',
}

interface DealDocumentBase {
  id: number;
  name: string;
  uuid: string;
  section: DealDocumentSection;
  source?: DealDocumentSource;
  document_type: DocumentType;
  signed_document_type: DocumentType;
  locked: boolean;
  entity: PolymorphicId;
  template_id: number | null;
  document: Document | null;
}

export interface DealDocumentJSON extends DealDocumentBase {
  version: DealDocumentVersionJSON;
}

export interface DealDocument extends DealDocumentBase {
  version: DealDocumentVersion;
}

export class DealDocument extends Model<DealDocumentJSON> {
  static readonly Version = DealDocumentVersion;

  private static readonly UPLOAD_SOURCE_TYPES = [
    null,
    DealDocumentSource.UPLOAD,
    DealDocumentSource.USER_UPLOAD,
    DealDocumentSource.EDITABLE,
  ];

  constructor(data: DealDocumentJSON) {
    super(data);
    this.version = new DealDocumentVersion(data.version);
  }

  get isUpload(): boolean {
    return this.source
      ? DealDocument.UPLOAD_SOURCE_TYPES.includes(this.source)
      : false;
  }

  get hasVersion(): boolean {
    return this.version.id !== null;
  }

  get file(): string | null {
    return this.version.file;
  }

  get miniature(): string | null {
    return this.version.miniature;
  }
}
