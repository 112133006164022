import { useCallback, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import { HardSpinner, PageSpinner } from '@advitam/ui';

import ResourceCable, {
  ResourceNavbarUsers,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { ResourceRoomType } from 'cables/Resource';
import ErrorBanner from 'components/ErrorBanner';
import { withDatasets } from 'slices/data';

import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectRawCityhall,
} from './selectors';
import { fetchCityhall } from './thunk';
import slice, { clearError } from './slice';

function Container(): JSX.Element {
  const dispatch = useDispatch();

  const cityhall = useSelector(makeSelectRawCityhall());
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const error = useSelector(makeSelectError());

  const { id } = useParams();
  assert(id !== undefined);
  const cityhallId = parseInt(id, 10);
  const canRender = !isLoading && cityhall;

  const fetchResource = useCallback(() => {
    dispatch(fetchCityhall(cityhallId));
  }, [dispatch, cityhallId]);

  useEffect(() => {
    if (cityhallId !== cityhall?.id) {
      fetchResource();
    }

    return () => {
      dispatch(clearError());
    };
  }, [cityhallId]);

  return (
    <>
      {isSaving && <HardSpinner />}
      {canRender ? <Outlet /> : <PageSpinner />}
      {error && <ErrorBanner errors={error} />}
      {cityhallId && (
        <ResourceCable
          resourceId={cityhallId}
          resourceType={ResourceRoomType.CITYHALL}
        >
          <ResourceNavbarUsers />
          <ResourceUpdateModal onRefresh={fetchResource} />
        </ResourceCable>
      )}
    </>
  );
}

export default compose(
  withDatasets('funeralTeam'),
  withSlice(slice),
)(Container);
