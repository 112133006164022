import type { ReactNode } from 'react'

import style from './Container.module.scss'

interface ContainerProps {
  background?: ReactNode
  children: ReactNode
  className?: string
  // RDFa
  vocab?: string
  typeof?: string
}

export default function Container({
  background,
  children,
  className,
  vocab,
  typeof: rdfaTypeof,
}: ContainerProps): JSX.Element {
  const classes = ['col-12', style.container, className].filter(Boolean)

  return (
    <div className={style.wrapper}>
      {background && <div className={style.background}>{background}</div>}
      <div className={classes.join(' ')} vocab={vocab} typeof={rdfaTypeof}>
        {children}
      </div>
    </div>
  )
}
