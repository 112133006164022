import { useCallback, useState, MouseEvent } from 'react';
import { useForm } from 'react-final-form';

import { useFormValue } from '@advitam/react';
import { Popup, TransparentButton } from '@advitam/ui';

import OtherFlag from './other.svg';
import FranceFlag from './france.svg';
import style from './DefunctBrithPlaceInput.module.scss';

export default function FlagSelect(): JSX.Element {
  const form = useForm();
  const country = useFormValue<string | null>(
    'deal.defunctInfo.person.birth_country.code',
  );
  const isFrance = !country || country === 'FR';

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const openDropdown = useCallback(
    (event: MouseEvent): void => {
      event.stopPropagation();
      event.preventDefault();
      setDropdownOpen(true);
    },
    [setDropdownOpen],
  );
  const closeDropdown = useCallback((): void => {
    setDropdownOpen(false);
  }, [setDropdownOpen]);

  const selectCountry = useCallback(
    (code: string): void => {
      form.batch(() => {
        form.change('deal.defunctInfo.person.birth_country.code', code);
        form.change('deal.defunctInfo.person.birth_country.name', '');
        form.change('deal.defunctInfo.person.birthLocation', '');
        form.change('deal.defunctInfo.person.birth_postal_code', '');
      });
      closeDropdown();
    },
    [form, closeDropdown],
  );

  const selectFrance = useCallback(
    (event: MouseEvent): void => {
      event.stopPropagation();
      selectCountry('FR');
    },
    [selectCountry],
  );

  const selectOther = useCallback(
    (event: MouseEvent): void => {
      event.stopPropagation();
      selectCountry('XX');
    },
    [selectCountry],
  );

  return (
    <Popup
      className={style.popup}
      onFocusOut={closeDropdown}
      onClick={openDropdown}
      autoFocus
    >
      {isFrance ? <FranceFlag /> : <OtherFlag />}
      {isDropdownOpen && (
        <div className={style.dropdown_content}>
          <TransparentButton onClick={selectFrance}>
            <FranceFlag />
          </TransparentButton>
          <TransparentButton onClick={selectOther}>
            <OtherFlag />
          </TransparentButton>
        </div>
      )}
    </Popup>
  );
}
