import { useEffect } from 'react'

import { Browsers } from '@advitam/support'

export function useResizeObserver(
  element: HTMLElement | null,
  callback: () => void,
  dependencies: readonly unknown[] = [],
): void {
  useEffect(() => {
    let observer: ResizeObserver

    async function observe(): Promise<void> {
      if (!element) {
        return
      }

      observer = await Browsers.getResizeObserver(callback)
      observer.observe(element)
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    observe()

    return (): void => observer?.disconnect()
  }, [element, ...dependencies])
}
