/**
 * Function to format the address
 *
 * @param {object} address data of address
 * @returns {string} readable address
 */
export function formatAddress(address) {
  if (!address) {
    return '';
  }

  return [
    address.address,
    address.address_l2,
    `${address.postal_code || ''} ${address.city || ''}`.trim(),
    address.country && typeof address.country === 'object'
      ? address.country.name
      : address.country,
  ]
    .filter(Boolean)
    .join(', ');
}
