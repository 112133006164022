import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { DealState } from '@advitam/api/models/Deal/State';
import { withSlice } from '@advitam/react';
import {
  ErrorText,
  FormattedApiError,
  FormLayout,
  FormUI,
  PageSpinner,
  Person,
  Text,
} from '@advitam/ui';
import StarIcon from '@advitam/ui/images/icons/star.svg';
import { Layout } from 'containers/Crud';

import { makeSelectDeal } from '../../../selectors.typed';
import {
  makeSelectError,
  makeSelectIsLoading,
  makeSelectReviews,
} from './selectors';
import slice, { clear } from './slice';
import { fetchReviews } from './thunk';
import messages from './messages';
import style from './Reviews.module.scss';

function Reviews(): JSX.Element | null {
  const dispatch = useDispatch();

  const deal = useSelector(makeSelectDeal());
  const reviews = useSelector(makeSelectReviews());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  useEffect(() => {
    if (deal?.uuid && deal.state === DealState.OK) {
      dispatch(fetchReviews(deal.uuid));
    }

    return () => {
      dispatch(clear());
    };
  }, [dispatch, deal?.uuid, deal?.state]);

  if (!deal?.uuid) {
    return null;
  }

  if (isLoading) {
    return <PageSpinner />;
  }

  if (error) {
    return (
      <ErrorText center>
        <FormattedApiError error={error} />
      </ErrorText>
    );
  }

  return (
    <Layout.FieldsetGrid>
      {reviews.map(review => (
        <Layout.Fieldset
          title={<Person.FormattedName person={review.client} />}
        >
          <FormLayout.Row>
            <div>
              <Text tagName="div" className={style.label}>
                <FormattedMessage id={messages.funeralAdvisorLabel.id} />
                <div className={style.stars}>
                  <StarIcon
                    className={
                      review.funeral_advisor_grade < 1 ? style.empty : undefined
                    }
                  />
                  <StarIcon
                    className={
                      review.funeral_advisor_grade < 2 ? style.empty : undefined
                    }
                  />
                  <StarIcon
                    className={
                      review.funeral_advisor_grade < 3 ? style.empty : undefined
                    }
                  />
                  <StarIcon
                    className={
                      review.funeral_advisor_grade < 4 ? style.empty : undefined
                    }
                  />
                  <StarIcon
                    className={
                      review.funeral_advisor_grade < 5 ? style.empty : undefined
                    }
                  />
                </div>
              </Text>
              <div>
                <FormUI.TextArea
                  readOnly
                  value={review.funeral_advisor_commentary}
                />
              </div>
            </div>
          </FormLayout.Row>
          <FormLayout.Row>
            <div>
              <Text tagName="div" className={style.label}>
                <FormattedMessage id={messages.ceremonyLabel.id} />
                <div className={style.stars}>
                  <StarIcon
                    className={
                      review.ceremony_grade < 1 ? style.empty : undefined
                    }
                  />
                  <StarIcon
                    className={
                      review.ceremony_grade < 2 ? style.empty : undefined
                    }
                  />
                  <StarIcon
                    className={
                      review.ceremony_grade < 3 ? style.empty : undefined
                    }
                  />
                  <StarIcon
                    className={
                      review.ceremony_grade < 4 ? style.empty : undefined
                    }
                  />
                  <StarIcon
                    className={
                      review.ceremony_grade < 5 ? style.empty : undefined
                    }
                  />
                </div>
              </Text>
              <div>
                <FormUI.TextArea readOnly value={review.ceremony_commentary} />
              </div>
            </div>
          </FormLayout.Row>
        </Layout.Fieldset>
      ))}
    </Layout.FieldsetGrid>
  );
}

export default withSlice(slice)(Reviews);
