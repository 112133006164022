import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { ErrorText, FormattedApiError, ResourceList } from '@advitam/ui';
import type { FlightsIndexFilters } from '@advitam/api/v1/Suppliers/Warehouses/Flights';
import { useLocationState } from 'lib/reactvitam/hooks/useLocationState';

import {
  makeSelectError,
  makeSelectFlights,
  makeSelectHasMore,
  makeSelectIsLoading,
} from './selectors';
import slice, { setFilters } from './slice';
import { fetchFlights } from './thunk';
import FlightsHeader from './parts/Header';
import FlightsRow from './parts/Row';
import FlightsFilters from './parts/Filters';
import CreateModal from './CreateModal';
import { setIsOpen } from './CreateModal/slice';
import style from './Flights.module.scss';

interface LocationState {
  filters: FlightsIndexFilters;
}

function Flights(): JSX.Element {
  const dispatch = useDispatch();
  const locationState = useLocationState<LocationState>();

  const flights = useSelector(makeSelectFlights());
  const error = useSelector(makeSelectError());
  const isLoading = useSelector(makeSelectIsLoading());
  const hasMorePages = useSelector(makeSelectHasMore());

  const fetchFlightPage = useCallback(() => {
    dispatch(fetchFlights());
  }, [dispatch]);

  useEffect(() => {
    if (locationState) {
      dispatch(setFilters(locationState.filters));
      fetchFlightPage();
    } else if (flights.length === 0) {
      fetchFlightPage();
    }

    return (): void => {
      dispatch(setIsOpen(false));
    };
  }, [dispatch, fetchFlightPage]);

  return (
    <>
      <main>
        <ResourceList.Container
          error={
            error && (
              <ErrorText center tagName="p" className={style.error}>
                <FormattedApiError error={error} />
              </ErrorText>
            )
          }
          filters={<FlightsFilters />}
          hasMore={hasMorePages}
          header={<FlightsHeader />}
          isLoading={isLoading}
          useWindow={false}
          fetchResources={fetchFlightPage}
        >
          {flights.map(flight => (
            <FlightsRow key={flight.id} flight={flight} />
          ))}
        </ResourceList.Container>
      </main>
      <CreateModal />
    </>
  );
}

export default withSlice(slice)(Flights);
