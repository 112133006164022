import { useCallback, useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { ErrorText, FormattedApiError, ResourceList } from '@advitam/ui';
import { withDatasets } from 'slices/data';

import slice, { resetContent } from './slice';
import {
  makeSelectEntities,
  makeSelectIsLoading,
  makeSelectError,
  makeSelectHasMore,
} from './selectors';
import style from './Entities.module.scss';
import CreationModal from './parts/CreationModal';
import Filters from './parts/Filters';
import Header from './parts/Header';
import Row from './parts/Row';
import { fetchEntities } from './thunk';

function Entities(): JSX.Element {
  const dispatch = useDispatch();

  const entities = useSelector(makeSelectEntities());
  const hasMore = useSelector(makeSelectHasMore());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());

  const fetch = useCallback(() => {
    dispatch(fetchEntities());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetContent());
    fetch();
  }, []);

  return (
    <main className={style.main}>
      <ResourceList.Container
        error={
          error && (
            <ErrorText center tagName="p">
              <FormattedApiError error={error} />
            </ErrorText>
          )
        }
        filters={<Filters />}
        hasMore={hasMore}
        header={<Header />}
        isLoading={isLoading}
        fetchResources={fetch}
      >
        {entities.map(entity => (
          <Row key={`${entity.entity_type}--${entity.id}`} entity={entity} />
        ))}
      </ResourceList.Container>
      <CreationModal />
    </main>
  );
}

export default compose<typeof Entities>(
  withSlice(slice),
  withDatasets('departments'),
)(Entities);
