import { ReactNode, useCallback, useMemo, ReactElement } from 'react'
import { useForm } from 'react-final-form'

import { useFormValues } from '@advitam/react'
import { FormUI } from '@advitam/ui'
import type { Address as AddressUI } from '@advitam/ui/components/Form/UI/GooglePlace/types'

import FlagSelect from './FlagSelect'

interface DefunctBirthPlaceInputProps {
  label: ReactNode
  prefix: string
  error?: ReactElement | boolean
}

export default function DefunctBirthPlaceInput({
  label,
  prefix,
  error,
}: DefunctBirthPlaceInputProps): JSX.Element {
  const form = useForm()

  const values = useFormValues<string | null>([
    `${prefix}.birth_postal_code`,
    `${prefix}.birth_location`,
    `${prefix}.birth_country.name`,
    `${prefix}.birth_country.code`,
  ])

  const value = useMemo(
    (): AddressUI => ({
      street_name: null,
      street_number: null,
      postal_code: values[0],
      city: values[1],
      country: values[2],
      country_code: values[3],
      latitude: null,
      longitude: null,
    }),
    [values],
  )

  const onChange = useCallback(
    (r: AddressUI): void => {
      form.batch(() => {
        form.change(`${prefix}.birth_postal_code`, r.postal_code)
        form.change(`${prefix}.birth_location`, r.city)
        form.change(`${prefix}.birth_country.name`, r.country)
        form.change(`${prefix}.birth_country.code`, r.country_code)
      })
    },
    [form, prefix],
  )

  return (
    <FormUI.GooglePlace
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      onlyCities
      onlyFrance={false}
      prefix={<FlagSelect prefix={prefix} />}
    />
  )
}
