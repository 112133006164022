import { createAsyncThunk } from '@reduxjs/toolkit';
import { push, replace } from 'redux-first-history';

import Api, { request } from '@advitam/api';
import { CrematoriumJSON } from '@advitam/api/models/Crematorium';
import { assert, nextTick } from '@advitam/support';
import { Path } from 'containers/App/constants';

import { CREMATORIUM } from './constants';
import { AppStateSubset, setCrematorium } from './slice';
import { makeSelectRawCrematorium } from './selectors';
import { NewCrematorium } from './types';

export const fetchCrematorium = createAsyncThunk(
  `${CREMATORIUM}_SHOW`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Crematoriums.show(id));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createCrematorium = createAsyncThunk(
  `${CREMATORIUM}_CREATE`,
  async (crematorium: NewCrematorium, { rejectWithValue, dispatch }) => {
    try {
      const { body } = await request(Api.V1.Crematoriums.create(crematorium));

      dispatch(setCrematorium(body));
      // Let the form reset to avoid displaying the change loss modal
      nextTick(() => nextTick(() => dispatch(replace(`./${body.id}`))));

      return undefined;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCrematorium = createAsyncThunk(
  `${CREMATORIUM}_UPDATE`,
  async (crematorium: CrematoriumJSON, { rejectWithValue }) => {
    try {
      const { body } = await request(Api.V1.Crematoriums.update(crematorium));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateCrematoriumName = createAsyncThunk(
  `${CREMATORIUM}_UPDATE_NAME`,
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const crematorium = makeSelectRawCrematorium()(state);
    assert(crematorium?.id !== undefined);

    try {
      const { body } = await request(
        Api.V1.Crematoriums.update({ id: crematorium.id, name }),
      );
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const saveCrematorium = createAsyncThunk(
  `${CREMATORIUM}_SAVE`,
  async (crematorium: CrematoriumJSON | NewCrematorium, { dispatch }) => {
    if (crematorium.id === undefined) {
      await dispatch(createCrematorium(crematorium));
    } else {
      await dispatch(updateCrematorium(crematorium));
    }
  },
);

export const destroyCrematorium = createAsyncThunk(
  `${CREMATORIUM}_DESTROY`,
  async (_: void, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const crematorium = makeSelectRawCrematorium()(state);
    assert(crematorium?.id !== undefined);

    try {
      await request(Api.V1.Crematoriums.destroy(crematorium.id));
      dispatch(push(Path.ENTITIES));
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setIsCrematoriumDisabled = createAsyncThunk(
  `${CREMATORIUM}_SET_IS_DISABLED`,
  async (disabled: boolean, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const crematorium = makeSelectRawCrematorium()(state);
    assert(crematorium?.id !== undefined);

    try {
      await request(
        Api.V1.Crematoriums.Disabled.update(crematorium.id, disabled),
      );
      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
