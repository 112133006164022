/* eslint-disable import/no-import-module-exports */

import 'core-js/stable';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import * as Sentry from '@sentry/react';
import { createReduxHistoryContext } from 'redux-first-history';
import { HistoryRouter } from 'redux-first-history/rr6';
import { createBrowserHistory } from 'history';

import 'react-phone-number-input/style.css';

import moment from 'moment';
import 'moment/locale/fr';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'tippy.js/dist/tippy.css';
import 'cropperjs/dist/cropper.css';

import * as Company from '@advitam/api/lib/company';
import createReducer from '@advitam/react/redux/createReducer';
import { assert } from '@advitam/support';
import { LinkContext } from '@advitam/ui';
import App from 'containers/App';
import { ReactRouterLinkWrapper } from 'components/LinkWrapper';
import LanguageProvider from 'components/LanguageProvider';
import {
  LOCALE,
  TranslationMessages,
} from 'components/LanguageProvider/constants';

import { configureStore } from 'lib/reactvitam/redux';
import { unsafeContext } from 'lib/support';

// Import i18n messages
import { translationMessages } from './i18n';

// Import CSS reset and Global Styles
import './scss/app.scss';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.replayIntegration()],
  });
}

assert(process.env.COMPANY_ID !== undefined);
Company.set({
  id: parseInt(process.env.COMPANY_ID, 10),
  name: 'Advitam',
  slug: 'advitam',
});

if (process.env.NODE_ENV === 'production') {
  const { serviceWorker } = unsafeContext();

  if (serviceWorker) {
    window.addEventListener('load', () => {
      serviceWorker
        .register('/service-worker.js')
        .then(registration => {
          // eslint-disable-next-line no-console
          console.log('SW registered: ', registration);
        })
        .catch(registrationError => {
          // eslint-disable-next-line no-console
          console.log('SW registration failed: ', registrationError);
        });
    });
  }
}

moment.locale(LOCALE);

// Create redux store with history
const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({ history: createBrowserHistory() });
const store = configureStore(createReducer({}, { router: routerReducer }), {}, [
  routerMiddleware,
]);
store.injectedReducers.router = routerReducer;

const history = createReduxHistory(store);

const MOUNT_NODE = document.getElementById('app');
assert(MOUNT_NODE !== null);
Modal.setAppElement(MOUNT_NODE);
const root = createRoot(MOUNT_NODE);

function render(
  Component: typeof App,
  translations: TranslationMessages,
): void {
  root.render(
    <Provider store={store}>
      <LanguageProvider messages={translations}>
        <LinkContext.Provider value={ReactRouterLinkWrapper}>
          <HistoryRouter history={history}>
            <Component history={history} />
          </HistoryRouter>
        </LinkContext.Provider>
      </LanguageProvider>
    </Provider>,
  );
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    // eslint-disable-next-line react/no-deprecated
    root.unmount();
    /* eslint-disable @typescript-eslint/no-var-requires,global-require */
    const UpdatedApp = (require('containers/App') as typeof import('containers/App'))
      .default;
    const {
      translationMessages: updatedTranslationMessages,
    } = require('./i18n') as typeof import('./i18n');
    /* eslint-enable @typescript-eslint/no-var-requires,global-require */
    render(UpdatedApp, updatedTranslationMessages);
  });
}

render(App, translationMessages);
