import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import { ErrorText, FormattedApiError, PageSpinner } from '@advitam/ui';
import { DealType } from '@advitam/api/models/Deal/Type';
import { ResourceRoomType } from 'cables/Resource';
import { NEW_PATH } from 'containers/App/constants';
import ConfirmModal from 'containers/ConfirmModal/index.js';
import { resetDocuments } from 'containers/Documents/actions.js';
import sagaDocuments from 'containers/Documents/sagas.js';
import reducerDocuments from 'containers/Documents/reducer.js';
import ResourceCable, {
  ResourceNavbarUsers,
  ResourceUpdateModal,
} from 'containers/ResourceCable';
import { withDatasets } from 'slices/data';
import injectReducer from 'utils/injectReducer.js';
import injectSaga from 'utils/injectSaga.js';

import documentGenerationSlice from '../DocumentGenerationModal/slice';
import layersSlice from './Funeral/LayersModal/slice';
import { error as setError } from './actions.js';
import saga from './sagas.js';
import reducer from './reducer.js';
import DealTypeFactory from './DealTypeFactory';
import TypeSelection from './TypeSelection';
import clientsSlice from './Clients/slice';
import paymentsSlice from './Payments/slice';
import identitySlice from './Sections/Identity/slice';
import todolistSlice from './Sections/Todolist/slice';
import legacyTodoListSlice from './TodoList/slice';

import {
  makeSelectIsLoading,
  makeSelectDeal,
  makeSelectFetchError,
} from './selectors.typed';
import { initialFetch } from './thunk';
import slice, { setDeal } from './slice';
import Funeral from './Funeral';

function Deal(): JSX.Element {
  const dispatch = useDispatch();

  const { dealUUID } = useParams();
  assert(dealUUID !== undefined);

  const deal = useSelector(makeSelectDeal());
  const isLoading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectFetchError());

  const getDeal = useCallback((): void => {
    dispatch(initialFetch(dealUUID));
  }, [dispatch, dealUUID]);

  useEffect(() => {
    if (dealUUID === NEW_PATH) {
      dispatch(setDeal(null));
    } else if (dealUUID !== deal?.uuid) {
      getDeal();
    }
  }, [dispatch, getDeal, dealUUID]);

  useEffect(
    () => (): void => {
      dispatch(setDeal(null));
      dispatch(setError(null));
      dispatch(resetDocuments()); // TODO: Not here ?
    },
    [dispatch],
  );

  if (isLoading || (dealUUID !== NEW_PATH && !deal)) {
    return <PageSpinner />;
  }

  if (error) {
    return (
      <ErrorText center>
        <FormattedApiError error={error} />
      </ErrorText>
    );
  }

  if (dealUUID === NEW_PATH && !deal) {
    return <TypeSelection />;
  }

  assert(deal !== null);
  switch (deal.deal_type) {
    case DealType.FUNERAL:
      return <Funeral />;
    default:
      break;
  }

  return (
    <>
      <Helmet>
        <body className="old-design compact-design" />
      </Helmet>

      {deal.id && (
        <ResourceCable
          resourceId={deal.id}
          resourceType={ResourceRoomType.DEAL}
        >
          <ResourceNavbarUsers />
          <ResourceUpdateModal onRefresh={getDeal} />
        </ResourceCable>
      )}
      <ConfirmModal />
      <DealTypeFactory getDeal={getDeal} />
    </>
  );
}

const withReducer = [
  // TODO: Move downwards. Needed by fetchLayers, setDeal.
  injectReducer({ key: 'dealTypeWrapper', reducer }),
  // TODO: Move downwards ?
  injectReducer({ key: 'documents', reducer: reducerDocuments }),
];
const withSaga = [
  injectSaga({ key: 'dealTypeWrapper', saga }),
  injectSaga({ key: 'documents', saga: sagaDocuments }),
];

export default compose<typeof Deal>(
  ...withReducer,
  ...withSaga,
  withSlice(
    slice,
    clientsSlice, // Item & Marble
    identitySlice, // Funeral
    paymentsSlice,
    todolistSlice, // permits legacy to use new thunks. TODO: remove once legacy todolist is rem
    legacyTodoListSlice,
    layersSlice,
    documentGenerationSlice, // TODO: Move downwards ?
  ),
  withDatasets('funeralTeam', 'funeralBrands', 'worshipTypes'), // TODO (worshipTypes): Specific to deal / section
)(Deal);
