import qs from 'qs';
import { call, put, takeLatest } from 'redux-saga/effects';

import { DealType } from '@advitam/api/models/Deal/Type';
import { requestWithAuth } from 'utils/request';
import { fetchDocuments } from 'containers/Documents/sagas';
import { getBookingInfo, getOrdersLinked } from 'containers/Deal/sagas';

import { fetchPaymentsDetails } from '../Payments/thunk.ts';
import {
  FETCH_FUNERAL_DETAILS,
  GET_CONCESSION_PRICES,
  GET_DEAL_SUMMARY,
  GET_CREMATORIUM,
  GET_CEREMONIES,
  GET_CONCESSION_TYPES,
  GET_FUNERAL_PARLOR_STAY_TYPES,
} from './constants';
import { error } from './actions';
import {
  setConcessionPrices,
  setCrematorium,
  setCeremonies,
  setConcessionTypes,
  setFuneralParlorStayTypes,
} from './StepsSection/actions';
import { setDealSummary } from './DealSummarySection/actions';

export function* getConcessionPrices(action) {
  try {
    const requestURL = `/api/v1/graveyards/${action.id}/concessions`;
    const response = yield call(requestWithAuth, requestURL);

    yield put(setConcessionPrices(response));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* getDealSummary(action) {
  try {
    const requestURL = `/api/v1/deals/${action.uuid}/summary`;
    const response = yield call(requestWithAuth, requestURL);

    yield put(setDealSummary(response));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* getCrematorium(action) {
  try {
    const requestURL = `/api/v1/crematoriums/${action.id}`;
    const resp = yield call(requestWithAuth, requestURL);
    yield put(setCrematorium(resp));
  } catch (err) {
    err.response = yield err.response;
    yield put(setCrematorium({}));
    yield put(error(err));
  }
}

export function* getCeremonies(action) {
  try {
    const requestURL = `/api/v1/crematoriums/${
      action.id
    }/ceremonies?${qs.stringify(action.params, { arrayFormat: 'brackets' })}`;
    const resp = yield call(requestWithAuth, requestURL);
    yield put(setCeremonies(resp));
  } catch (err) {
    err.response = yield err.response;
    yield put(setCeremonies({}));
    yield put(error(err));
  }
}

export function* getConcessionTypes() {
  try {
    const requestURL = `/api/v1/graveyards/concession_types`;
    const resp = yield call(requestWithAuth, requestURL);
    yield put(setConcessionTypes(resp));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* getFuneralParlorStayTypes(action) {
  try {
    const { funeralParlorId } = action;
    const requestURL = `/api/v1/funeral_parlors/${funeralParlorId}/stays`;
    const resp = yield call(requestWithAuth, requestURL);
    yield put(setFuneralParlorStayTypes(resp));
  } catch (err) {
    err.response = yield err.response;
    yield put(error(err));
  }
}

export function* fetchDetails({ deal: { uuid } }) {
  yield call(getDealSummary, { uuid });
  yield call(fetchDocuments, { dealUUID: uuid });
  yield put(fetchPaymentsDetails(uuid));
  yield call(getBookingInfo, { dealUUID: uuid });
  yield call(getOrdersLinked, {
    dealUUID: uuid,
    dealType: DealType.FUNERAL,
  });
}

export default function* dealData() {
  yield takeLatest(FETCH_FUNERAL_DETAILS, fetchDetails);
  yield takeLatest(GET_CONCESSION_PRICES, getConcessionPrices);
  yield takeLatest(GET_DEAL_SUMMARY, getDealSummary);
  yield takeLatest(GET_CREMATORIUM, getCrematorium);
  yield takeLatest(GET_CEREMONIES, getCeremonies);
  yield takeLatest(GET_CONCESSION_TYPES, getConcessionTypes);
  yield takeLatest(GET_FUNERAL_PARLOR_STAY_TYPES, getFuneralParlorStayTypes);
}
