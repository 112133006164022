import { authenticate } from 'api/decorators';
import { ApiRequestDescriptor } from 'api/request';
import { get, post } from 'api/methods';

import type { OpsLogJSON, OpsLogTag } from 'models/OpsLog';

export interface OpsLogsFilters {
  deal_id_in?: number[];
  tag_eq?: OpsLogTag;
  page?: number;
  per_page?: number;
}

export class OpsLogs {
  @authenticate
  static index(filters?: OpsLogsFilters): ApiRequestDescriptor<OpsLogJSON[]> {
    return get('/api/v1/ops_logs', filters);
  }

  @authenticate
  static create(ops_log: OpsLogJSON): ApiRequestDescriptor<OpsLogJSON> {
    return post('/api/v1/ops_logs', ops_log);
  }
}
