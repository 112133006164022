import { FormattedMessage } from 'react-intl';

import { Text } from '@advitam/ui';

import messages from './messages';
import style from './List.module.scss';

export default function Header(): JSX.Element {
  return (
    <div className={`${style.row} ${style.header}`}>
      <Text small tagName="div">
        <FormattedMessage id={messages.task.id} />
      </Text>
      <Text small tagName="div" className={style.actions}>
        <FormattedMessage id={messages.actions.id} />
      </Text>
    </div>
  );
}
