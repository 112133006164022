import { authenticate } from '../../lib/decorators'
import { get, put } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { EntityInvoiceJSON } from '../../models/EntityInvoice'
import type { EntityInvoicePrestationStatus } from '../../models/EntityInvoice/Prestation/Status'
import type { EntityInvoiceEntityType } from '../../models/EntityInvoice/Entity/Type'

import { PendingPayments } from './PendingPayments'
import { Prestations } from './Prestations'
import { Validate } from './Validate'
import { serializeUpdate } from './serializers'

export interface EntityInvoicesFilters {
  start_date: number
  end_date: number
  entity_type?: EntityInvoiceEntityType
  entity_id?: number
  status?: EntityInvoicePrestationStatus
  q?: string
  page: number
}

export interface EntityInvoicesIndexResponse {
  list: EntityInvoiceJSON[]
  page_count: number
}

export const EntityInvoices = {
  PendingPayments,
  Prestations,
  Validate,
  index: (filters: EntityInvoicesFilters): ApiRequestDescriptor<EntityInvoicesIndexResponse> =>
    authenticate(get('/api/v1/entity_invoices', filters)),
  update: (invoice: EntityInvoiceJSON): ApiRequestDescriptor<EntityInvoicesIndexResponse> =>
    authenticate(put(`/api/v1/entity_invoices/${invoice.id}`, serializeUpdate(invoice))),
}
