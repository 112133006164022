import { defineMessages } from 'react-intl';

export default defineMessages({
  deliverySteps: {
    id: 'app.containers.DealItems.DeliverySection.deliverySteps',
  },
  delivery: {
    id: 'app.containers.DealItems.DeliverySection.delivery',
  },
  deliveryMessage: {
    id: 'app.containers.DealItems.DeliverySection.deliveryMessage',
  },
  signature: {
    id: 'app.containers.DealItems.DeliverySection.signature',
  },
  'casketing-Hospital': {
    id: 'app.containers.DealItems.DeliverySection.casketing-Hospital',
  },
  'casketing-FuneralParlor': {
    id: 'app.containers.DealItems.DeliverySection.casketing-FuneralParlor',
  },
  'casketing-PublicLocation': {
    id: 'app.containers.DealItems.DeliverySection.casketing-PublicLocation',
  },
  'closing-Hospital': {
    id: 'app.containers.DealItems.DeliverySection.closing-Hospital',
  },
  'closing-FuneralParlor': {
    id: 'app.containers.DealItems.DeliverySection.closing-FuneralParlor',
  },
  'closing-PublicLocation': {
    id: 'app.containers.DealItems.DeliverySection.closing-PublicLocation',
  },
  'ceremony-Worship': {
    id: 'app.containers.DealItems.DeliverySection.ceremony-Worship',
  },
  'interment-Graveyard': {
    id: 'app.containers.DealItems.DeliverySection.interment-Graveyard',
  },
  'cremation-Crematorium': {
    id: 'app.containers.DealItems.DeliverySection.cremation-Crematorium',
  },
  'urn_sealing-Graveyard': {
    id: 'app.containers.DealItems.DeliverySection.urn_sealing-Graveyard',
  },
  'dispersion-Graveyard': {
    id: 'app.containers.DealItems.DeliverySection.dispersion-Graveyard',
  },
  'dispersion-Crematorium': {
    id: 'app.containers.DealItems.DeliverySection.dispersion-Crematorium',
  },
  'dispersion-PublicLocation': {
    id: 'app.containers.DealItems.DeliverySection.dispersion-PublicLocation',
  },
  'gathering-PublicLocation': {
    id: 'app.containers.DealItems.DeliverySection.gathering-PublicLocation',
  },
});
