import { createAsyncThunk } from '@reduxjs/toolkit';

import { DealType } from '@advitam/api/models/Deal/Type';
import Api, { requestAsync } from 'api';
import { Deal, DealJSON } from 'models/Deal';

import { makeSelectDeal } from '../selectors.typed';
import { DEAL_COMMENTARIES } from './constants';
import type { AppStateSubset } from '../slice.js';

const API_METHOD_V2 = {
  [DealType.FUNERAL]: Api.V2.Deals.Funeral.update.bind(null),
  [DealType.ITEM]: Api.V2.Deals.Item.update.bind(null),
  [DealType.MARBLE]: Api.V2.Deals.Marble.update.bind(null),
};

export const updateCommentary = createAsyncThunk<string, string>(
  `${DEAL_COMMENTARIES}/UPDATE_COMMENTARY`,
  async (commentary, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const dealState = makeSelectDeal()(state);
    const deal = new Deal({ ...dealState, commentary } as DealJSON);

    try {
      if (deal.id) {
        const apiMethod = API_METHOD_V2[deal.deal_type];
        await requestAsync(apiMethod(deal));
      }

      return commentary;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
