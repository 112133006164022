import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { PaymentJSON, Payment as PaymentModel } from 'models/Payment';

import { setDeleteFunction as setDeleteFunctionDispatch } from 'containers/ConfirmModal/actions';
import { ButtonV2 as Button } from 'components/Button';
import messagesAction from 'messages/actions';

import { PaymentMean } from '@advitam/api/models/Payment/Mean';
import { PaymentState } from '@advitam/api/models/Payment/State';
import { PaymentReceived } from '@advitam/api/models/Payment/Received';

import {
  makeSelectIsDirty,
  makeSelectIsLoading,
  makeSelectSortedPaymentsByParent,
} from '../selectors';
import messages from './messages';
import { postPayments } from '../thunk';
import {
  updatePayment,
  addPayment as addPaymentAction,
  removePayment,
} from '../slice';
import Payment from '../Payment';
import PaymentData from './PaymentData';

interface PaymentListProps {
  dealUUID: string;
}

export default function PaymentList({
  dealUUID,
}: PaymentListProps): JSX.Element {
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsLoading());
  const isDirty = useSelector(makeSelectIsDirty());
  const sortedPayments = useSelector(makeSelectSortedPaymentsByParent());

  const [displayedPayments, setDisplayedPayments] = useState<number[]>([]);
  const [stagingPayment, setStagingPayment] = useState<PaymentJSON>(
    PaymentModel.skeleton,
  );

  const setDeleteFunction = (callback: () => void): void => {
    dispatch(setDeleteFunctionDispatch(callback));
  };

  const updateDisplayedPayments = (index: number): void => {
    const paymentIndex = displayedPayments.findIndex(
      payment => payment === index,
    );

    if (paymentIndex === -1) {
      setDisplayedPayments([...displayedPayments, index]);
    } else {
      setDisplayedPayments(
        displayedPayments.filter(payment => payment !== index),
      );
    }
  };

  const updateStagingPayment = (payment: PaymentJSON): void => {
    setStagingPayment({ ...stagingPayment, ...payment });
  };

  const addPayment = (): void => {
    dispatch(addPaymentAction(stagingPayment));
    setStagingPayment(PaymentModel.skeleton);
  };

  const areAllPaymentsValid = sortedPayments.every(
    ({ payment }) =>
      (payment.mean !== PaymentMean.CHECK &&
        payment.mean !== PaymentMean.CASH) ||
      payment.state !== PaymentState.ACCEPTED ||
      payment.received === PaymentReceived.YES,
  );

  return (
    <div className="payments__list">
      {isDirty && (
        <div className="payments__list__save">
          <FormattedMessage id={messages.updatePayments.id} />
          <Button
            onClick={(): void => {
              if (!isLoading && areAllPaymentsValid) {
                dispatch(postPayments(dealUUID));
              }
            }}
            disabled={isLoading || !areAllPaymentsValid}
          >
            <FormattedMessage id={messagesAction.save.id} />
          </Button>
        </div>
      )}
      <div className="payments__list__header">
        <div className="col-8">
          <FormattedMessage id={messages.details.id} />
        </div>
        <div className="col-3">
          <FormattedMessage id={messages.amount.id} />
        </div>
        <div className="col-3">
          <FormattedMessage id={messages.mean.id} />
        </div>
      </div>
      {sortedPayments.map(({ originalIndex, payment }) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="payments__list__body" key={originalIndex}>
          <PaymentData
            payment={payment}
            updateDisplayedPayments={(): void =>
              updateDisplayedPayments(originalIndex)
            }
          />
          {displayedPayments.includes(originalIndex) && (
            <div className="payments__list__body__form">
              <Payment
                payment={payment}
                removePayment={(): void => {
                  dispatch(removePayment(originalIndex));
                }}
                setDeleteFunction={setDeleteFunction}
                handleChange={(updatedPayment: PaymentJSON): void => {
                  dispatch(
                    updatePayment({
                      updatedPayment,
                      paymentIndex: originalIndex,
                    }),
                  );
                }}
              />
            </div>
          )}
        </div>
      ))}
      <div className="fullwidth">
        <Payment
          payment={stagingPayment || {}}
          addPayment={addPayment}
          handleChange={updateStagingPayment}
          setDeleteFunction={setDeleteFunction}
          newPayment
        />
      </div>
    </div>
  );
}
