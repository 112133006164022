import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  quote: { id: 'app.containers.Deal.Sections.Home.StateSelect.quote' },
  order: { id: 'app.containers.Deal.Sections.Home.StateSelect.order' },
  ok: { id: 'app.containers.Deal.Sections.Home.StateSelect.ok' },
  ko: { id: 'app.containers.Deal.Sections.Home.StateSelect.ko' },
  funeralContract: {
    id: 'app.containers.Deal.Sections.Home.StateSelect.funeralContract',
  },
  funeralContractOk: {
    id: 'app.containers.Deal.Sections.Home.StateSelect.funeralContractOk',
  },

  anticipation: {
    id: 'app.containers.Deal.Sections.Home.StateSelect.anticipation',
  },
  confirmationText: {
    id: 'app.containers.Deal.Sections.Home.StateSelect.confirmationText',
  },
  confirmationTitle: {
    id: 'app.containers.Deal.Sections.Home.StateSelect.confirmationTitle',
  },
  duplicate: { id: 'app.containers.Deal.Sections.Home.StateSelect.duplicate' },
  notProvidedService: {
    id: 'app.containers.Deal.Sections.Home.StateSelect.notProvidedService',
  },
  other: { id: 'app.containers.Deal.Sections.Home.StateSelect.other' },
  physicalStore: {
    id: 'app.containers.Deal.Sections.Home.StateSelect.physicalStore',
  },
  toSpecify: { id: 'app.containers.Deal.Sections.Home.StateSelect.toSpecify' },
  text: { id: 'app.containers.Deal.Sections.Home.StateSelect.text' },
  title: { id: 'app.containers.Deal.Sections.Home.StateSelect.title' },
  tooExpensive: {
    id: 'app.containers.Deal.Sections.Home.StateSelect.tooExpensive',
  },
  unreachable: {
    id: 'app.containers.Deal.Sections.Home.StateSelect.unreachable',
  },

  errorModalTitle: {
    id: 'app.containers.Deal.Sections.Home.StateSelect.errorModalTitle',
  },
  errorModalClose: {
    id: 'app.containers.Deal.Sections.Home.StateSelect.errorModalClose',
  },
});
