import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import ConcessionSelection from 'components/ConcessionSelection';
import IntermentLocation from 'components/IntermentLocation';
import stepsMessages from 'containers/Deal/DealFuneral/StepsSection/StepEditors/messages';

export class Grave extends Component {
  /**
   * Function to update grave in marble
   *
   * @param {object} value value to update
   */
  updateGrave = value => {
    const { grave, updateGraveyard } = this.props;
    const updatedGrave = {
      ...grave,
      ...value,
    };
    updateGraveyard(updatedGrave);
  };

  render() {
    const {
      grave,
      graveyardConcessionTypes,
      graveyardConcessions,
      getGraveyardConcessionTypes,
      getGraveyardConcession,
      updateGraveyard,
      isFamilyBuyingConcession,
      getDefunctConcession,
      getGraveyardBasicInfos,
      graveyardBasicInfos,
      wishes,
      updateWish,
      findWish,
    } = this.props;
    return (
      <>
        <div className="margin--10">
          <div className="step__title">
            <div className="steps__header">
              <h3>
                <FormattedMessage id={stepsMessages.graveyard.id} />
              </h3>
            </div>
          </div>
          <div className="step__content">
            <IntermentLocation
              step={grave}
              handleChange={updateGraveyard}
              getGraveyardConcessionTypes={getGraveyardConcessionTypes}
              getGraveyardConcessions={getGraveyardConcession}
              isDelegate={false}
              isDates={false}
              getGraveyardBasicInfos={getGraveyardBasicInfos}
              graveyardBasicInfos={graveyardBasicInfos}
            />
          </div>
        </div>
        <ConcessionSelection
          location={grave.location}
          concession={grave.concession}
          handleChange={this.updateGrave}
          graveyardConcessionTypes={graveyardConcessionTypes}
          graveyardConcessions={graveyardConcessions}
          isFamilyBuyingConcession={isFamilyBuyingConcession}
          getDefunctConcession={getDefunctConcession}
          wishes={wishes}
          findWish={findWish}
          updateWish={updateWish}
        />
      </>
    );
  }
}

Grave.propTypes = {
  /** grave data */
  grave: PropTypes.object,
  /** function to update deal marble */
  updateGraveyard: PropTypes.func.isRequired,
  /** list of concession types of a graveyard */
  graveyardConcessionTypes: PropTypes.array,
  /** graveyard concession data */
  graveyardConcessions: PropTypes.object,
  /** function to get concession types from a graveyard */
  getGraveyardConcessionTypes: PropTypes.func.isRequired,
  /** function to get a graveyard concession */
  getGraveyardConcession: PropTypes.func.isRequired,
  /** does the family want to buy a concession */
  isFamilyBuyingConcession: PropTypes.bool.isRequired,
  /** function to get defunct concession by id */
  getDefunctConcession: PropTypes.func.isRequired,
  /** function to get graveyard summary data */
  getGraveyardBasicInfos: PropTypes.func.isRequired,
  /** Basic infos of the selected graveyard */
  graveyardBasicInfos: PropTypes.object,
  /** list of wishes */
  wishes: PropTypes.array.isRequired,
  /** function to find a wish */
  findWish: PropTypes.func.isRequired,
  /** function to update a wish */
  updateWish: PropTypes.func.isRequired,
};

export default Grave;
