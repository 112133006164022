import { ReactNode, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { assert } from '@advitam/support';
import { ActionsPopup } from 'containers/Crud';

import Sidebar from '../Sidebar';
import type { FlightForm } from './types';
import { makeSelectRawFlight } from '../selectors';
import style from '../Flight.module.scss';
import { createFlight, updateFlight } from '../thunk';

interface WrapperProps {
  children: ReactNode;
}

export default function Wrapper({ children }: WrapperProps): JSX.Element {
  const dispatch = useDispatch();

  const flight = useSelector(makeSelectRawFlight());
  assert(flight !== null);

  const onSubmit = useCallback(
    (updatedFlight: FlightForm): void => {
      if (updatedFlight.id !== undefined) {
        dispatch(updateFlight(updatedFlight));
      } else {
        dispatch(createFlight(updatedFlight));
      }
    },
    [dispatch],
  );

  return (
    <Form<FlightForm> onSubmit={onSubmit} initialValues={flight}>
      {({ handleSubmit, dirty }): JSX.Element => (
        <form onSubmit={handleSubmit} className={style.container}>
          <Sidebar />
          {(dirty || !flight.id) && <ActionsPopup isNew={!flight.id} />}
          {children}
        </form>
      )}
    </Form>
  );
}
