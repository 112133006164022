import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import {
  STEP_CREMATION,
  STEP_CASKETING,
  STEP_DEATH,
  STEP_CLOSING,
  STEP_REPATRIATION,
  STEP_CEREMONY,
  STEP_INTERMENT,
  STEP_TAC,
  STEP_TBC,
  STEP_DEPOSIT,
  STEP_URN_PICKUP,
  PUBLIC_LOCATION,
  FOREIGN_FUNERAL_CONTACT,
  ENTITY_TYPE_TO_PATH,
} from 'containers/Deal/DealFuneral/constants';
import { Path } from 'containers/App/constants.ts';
import { SUPPLIER_WAREHOUSE } from 'containers/Deal/DealFuneral/StepsSection/skeletons';
import PhoneIcon from 'components/PhoneIcon';
import messagesConcession from 'components/ConcessionSelection/Place/messages';
import messagesFlights from 'containers/Deal/DealFuneral/StepsSection/StepEditors/Repatriation/messages.ts';
import LinkToMap from 'components/LinkToMap';
import { formatAddress } from 'components/GoogleAutocomplete/utils';
import { RepatriationType } from 'models/RepatriationType.ts';
import TransportIcon from 'images/transport.svg';
import OpenedCoffinIcon from 'images/opened-coffin-black.svg';
import ClosedCoffinIcon from 'images/closed-coffin-black.svg';
import CrownFlowerIcon from 'images/couronne-fleurs-black.svg';
import PlaneIcon from 'images/plane-black.svg';
import TombstoneIcon from 'images/tombstone.svg';
import FireIcon from 'images/fire-black.svg';
import CrossIcon from 'images/cross.svg';
import WarehouseIcon from 'images/warehouse.svg';
import UrnIcon from 'images/urn.svg';
import CheckoutInfos from './parts/CheckoutInfos/index.tsx';
import {
  makeSelectDealSummary,
  makeSelectDealSummaryOtherFuneral,
} from './selectors';
import messages from './messages';

export class DealSummarySection extends PureComponent {
  /**
   * Function to render the date and time
   *
   * @param {integer} timestamp timestamp to convert
   */
  renderDate = timestamp => {
    if (!timestamp) return null;
    return (
      <div>
        <div>
          <b>{moment.unix(timestamp).utc().format('DD.MM.YYYY')}</b>
        </div>
        <div>{moment.unix(timestamp).utc().format('HH[h]mm')}</div>
      </div>
    );
  };

  /**
   * Function to render date without distance
   *
   * @param {object} event data of event
   */
  renderNoDistance = event => {
    if (!event) return null;

    if (event.eventType === STEP_DEPOSIT) {
      return (
        <>
          {this.renderDate(event.startDate)}
          {this.renderDate(event.endDate)}
          <div className="dealSummary__step__schedule__distance"></div>
        </>
      );
    }

    return (
      <>
        {this.renderDate(event.eventDate)}
        <div className="dealSummary__step__schedule__distance"></div>
      </>
    );
  };

  renderMinutesToHoursMinute = minutes => {
    let h = Math.floor(minutes / 60);
    let m = Math.ceil(minutes % 60);
    h = h < 10 ? `0${h}` : h;
    m = m < 10 ? `0${m}` : m;
    return `${h}h${m}`;
  };

  /**
   * Function to render a date with a distance
   *
   * @param {object} event data of event
   */
  renderDistance = event => {
    if (!event) return null;
    return (
      <div className="dealSummary__step__schedule__container">
        {!!event.distance && <div>{event.distance} km</div>}
        {event.duration && this.renderMinutesToHoursMinute(event.duration)}
        <div className="dealSummary__step__schedule__container__distance"></div>
      </div>
    );
  };

  renderIcon = step => {
    switch (step.eventType) {
      case STEP_DEATH:
        return <CrossIcon />;
      case STEP_CASKETING:
        return <OpenedCoffinIcon />;
      case STEP_CLOSING:
        return <ClosedCoffinIcon />;
      case STEP_CEREMONY:
        return <CrownFlowerIcon />;
      case STEP_REPATRIATION:
        return step.repatriation_type === RepatriationType.PLANE ? (
          <PlaneIcon />
        ) : (
          <TransportIcon />
        );
      case STEP_INTERMENT:
        return <TombstoneIcon />;
      case STEP_CREMATION:
        return <FireIcon />;
      case STEP_URN_PICKUP:
        return <UrnIcon />;
      case STEP_DEPOSIT:
        return <WarehouseIcon />;
      default:
        return null;
    }
  };

  /**
   * Function to render flights
   *
   * @param {array} flights list of flight
   * @param {number} repatriationId id of last flight
   */
  renderFlights = (flights, repatriationId) => {
    const lastFlight = [...flights].pop();
    if (!lastFlight) return null;
    return (
      <div className="dealSummary__step__body__places__other__place">
        {repatriationId ? (
          <a
            className="dealSummary__step__body__places__other__place__title"
            href={`${Path.FLIGHTS}/${repatriationId}`}
            target="_blank"
          >
            {lastFlight.arrivalInfo.airport}
          </a>
        ) : (
          <div>{lastFlight.arrivalInfo.airport}</div>
        )}
        {flights.map(flight => (
          <div key={flight.flightNumber} className="margin--0-0-10-0">
            <div className="flex dealSummary__step__body__places__other__place__flight__name">
              <FormattedMessage
                {...messagesFlights.flightNumber}
                tagName="span"
              />
              <div>{flight.flightNumber}</div>
            </div>
            <div className="dealSummary__step__body__places__other__place__flight__name">
              <span>{flight.departureInfo.airport}</span>
              {flight.departureInfo.date &&
                moment
                  .unix(flight.departureInfo.date)
                  .utc()
                  .format('DD/MM/YYYY - HH:mm')}
            </div>
            <div className="dealSummary__step__body__places__other__place__flight__name">
              <span>{flight.arrivalInfo.airport}</span>
              {flight.arrivalInfo.date &&
                moment
                  .unix(flight.arrivalInfo.date)
                  .utc()
                  .format('DD/MM/YYYY - HH:mm')}
            </div>
          </div>
        ))}
      </div>
    );
  };

  /**
   * Function to render concession
   *
   * @param {object} concession concession datadd
   */
  renderConcession = concession => (
    <div className="margin--10">
      {concession.address && (
        <div>
          <div className="flex">
            <FormattedMessage {...messagesConcession.division} />
            <div className="margin--0-10">:</div>
            <div>{concession.address.division}</div>
          </div>
          <div className="flex">
            <FormattedMessage {...messagesConcession.line} />
            <div className="margin--0-10">:</div>
            <div>{concession.address.line}</div>
          </div>
          <div className="flex">
            <FormattedMessage {...messagesConcession.id} />
            <div className="margin--0-10">:</div>
            <div>{concession.address.id}</div>
          </div>
          <div className="flex">
            <FormattedMessage {...messagesConcession.addon} />
            <div className="margin--0-10">:</div>
            <div>{concession.address.addon}</div>
          </div>
        </div>
      )}
    </div>
  );

  /**
   * Function to render entity
   *
   * @param {object} entity entity data
   */
  renderEntityStep = (entity, index) => {
    // We do not want to display those entities in the recap
    const entitiesToIgnore = [FOREIGN_FUNERAL_CONTACT, SUPPLIER_WAREHOUSE];
    if (entitiesToIgnore.includes(entity.location.type)) return null;

    const entityHasCrud =
      ENTITY_TYPE_TO_PATH[entity.location.type] !== undefined;
    return (
      <div
        key={index || entity.location.id}
        className="dealSummary__step__body__places__other__place"
      >
        <div className="flex">
          {entity.location.type === PUBLIC_LOCATION ? (
            <div className="dealSummary__step__body__places__other__place__title dealSummary__step__body__places__other__place__title--public">
              {formatAddress(entity.location.address)}
            </div>
          ) : (
            <>
              {entityHasCrud ? (
                <a
                  className="dealSummary__step__body__places__other__place__title"
                  href={`${ENTITY_TYPE_TO_PATH[entity.location.type]}/${
                    entity.location.id
                  }`}
                  target="_blank"
                >
                  {entity.location.name}
                </a>
              ) : (
                <span className="dealSummary__step__body__places__other__place__title">
                  {entity.location.name}
                </span>
              )}
            </>
          )}
          {entity.location.contact.phone && (
            <a
              className="dealSummary__step__body__places__other__place__icon"
              href={`tel:${entity.location.contact.phone}`}
            >
              <PhoneIcon />
            </a>
          )}
          {entity.location.address && (
            <LinkToMap
              address={entity.location.address}
              className="dealSummary__step__body__places__other__place__icon"
              name={entity.location.name}
            />
          )}
        </div>
        <CheckoutInfos checkout={entity.checkout} taxes={entity.tax} />
      </div>
    );
  };

  /**
   * Function to render entity or specific part of step
   *
   * @param {object} step step data
   */
  renderStep = step => (
    <>
      {step.repatriation_type === RepatriationType.PLANE && (
        <div className="dealSummary__step__body__places__other__place">
          <span className="dealSummary__step__body__places__other__place__title">
            {`${step.destination.country}${
              step.destination.country && step.destination.city ? ', ' : ' '
            }${step.destination.city}`}
          </span>
        </div>
      )}
      {step.entity_step && this.renderEntityStep(step.entity_step)}
      <div className="dealSummary__step__body__places__other">
        {step.linked_entities &&
          step.linked_entities.map((entity, index) =>
            this.renderEntityStep(entity, index),
          )}
      </div>
    </>
  );

  renderOtherFuneralSummary = otherFuneralSummary => {
    if (!otherFuneralSummary) return null;
    const {
      address: { address, city, postal_code: postalCode, country },
      contact,
      name,
    } = otherFuneralSummary;
    return (
      <div className="dealSummary__otherFuneralCompany">
        <h3>
          <FormattedMessage {...messages.otherFuneral} /> {name}
        </h3>
        <div>
          <FormattedMessage {...messages.phone} />
          <a href={`tel:${contact.phone}`}>{contact.phone}</a>
        </div>
        <div>
          <FormattedMessage {...messages.fax} />
          <a href={`tel:${contact.fax}`}>{contact.fax}</a>
        </div>
        {address && (
          <div>
            {address} {city} {postalCode} {country}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { dealSummary, otherFuneralSummary, defunctName } = this.props;
    return (
      <section id="dealSummary" className="dealSummary">
        <h2 className="margin--10-0">{defunctName}</h2>
        {otherFuneralSummary &&
          this.renderOtherFuneralSummary(otherFuneralSummary)}
        {dealSummary &&
          dealSummary.map((step, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="dealSummary__step">
              <div className="dealSummary__step__schedule">
                {step.eventType === STEP_TBC || step.eventType === STEP_TAC
                  ? this.renderDistance(step)
                  : this.renderNoDistance(step)}
              </div>
              <div className="dealSummary__step__timeline">
                <div className="dealSummary__step__timeline__line"></div>
                {step.eventType !== STEP_TBC && step.eventType !== STEP_TAC && (
                  <div className="dealSummary__step__timeline__icon">
                    <div>{this.renderIcon(step)}</div>
                  </div>
                )}
              </div>
              <div className="dealSummary__step__body">
                <div className="dealSummary__step__body__places">
                  {this.renderStep(step)}
                </div>
                {step.flights &&
                  this.renderFlights(
                    step.flights,
                    step.supplier_warehouses_flight_id,
                  )}
                {step.concession && this.renderConcession(step.concession)}
              </div>
            </div>
          ))}
        <div className="dealSummary__step">
          <div className="dealSummary__step__schedule"></div>
          <div className="dealSummary__step__timeline dealSummary__step__timeline--end">
            <div className="dealSummary__step__timeline__line"></div>
          </div>
        </div>
      </section>
    );
  }
}

DealSummarySection.propTypes = {
  /** deal summary data */
  dealSummary: PropTypes.array,
  /** other funeral company data */
  otherFuneralSummary: PropTypes.object,
  /** Defunct name */
  defunctName: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  dealSummary: makeSelectDealSummary(),
  otherFuneralSummary: makeSelectDealSummaryOtherFuneral(),
});

export default connect(mapStateToProps)(DealSummarySection);
