import {
  Action,
  createAsyncThunk,
  ThunkDispatch,
  unwrapResult,
} from '@reduxjs/toolkit';
import { replace } from 'redux-first-history';
import { captureException } from '@sentry/react';

import { DealType } from '@advitam/api/models/Deal/Type';

import LegacyApi, { isApiError, requestAsync } from 'api';
import { FuneralDealPayload } from 'api/v1/Deals';
import { MarbleDealPayload } from 'api/v1/Deals/Marbles';
import { Deals } from 'api/formatters/Deals';

import { assert, nextTick } from '@advitam/support';
import { Path } from 'containers/App/constants';
import { Deal, DealJSON } from 'models/Deal';

import type { AppStateSubset as ClientsAppStateSubset } from './Clients/slice';
import { initialize as initializeClients } from './Clients/slice';
import { updateClients } from './Clients/thunk';
import {
  makeSelectClientsError,
  makeSelectDealClients,
} from './Clients/selectors';

import type { AppStateSubset as DealAppStateSubset } from './slice';
import { setDeal } from './slice';
import { fetchFuneralDetails } from './DealFuneral/actions.js';
import { fetchDealDetails } from './DealItems/actions.js';
import { getDealMarble } from './DealMarble/actions.js';
import { setDealSummary } from './DealFuneral/DealSummarySection/actions.js';
import { DEAL } from './constants';
import { makeSelectTriggerPingServices } from './selectors.js';
import { fetchLayers } from './Funeral/LayersModal/thunk';
import { fetchItems } from './Sections/Todolist/thunk';
import { makeSelectDeal } from './selectors.typed';
import { fetchAbilities } from './Sections/Identity/thunk';

type AppStateSubset = DealAppStateSubset & ClientsAppStateSubset;

export const fetchDealDetailsThunk = createAsyncThunk(
  `${DEAL}/FETCH_DETAILS`,
  async (_, { dispatch, getState }) => {
    const state = getState() as AppStateSubset;
    const deal = makeSelectDeal()(state);
    assert(deal !== null);

    switch (deal.deal_type) {
      case DealType.FUNERAL:
        await dispatch(fetchLayers());
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        dispatch(fetchItems());
        break;
      default:
        break;
    }
  },
);

export const fetchClients = createAsyncThunk(
  `${DEAL}/FETCH_CLIENTS`,
  async (deal: DealJSON, { rejectWithValue, dispatch }) => {
    if (deal.deal_type === DealType.FUNERAL) {
      await dispatch(fetchAbilities(deal));
      return undefined;
    }

    let abilities;
    try {
      const { body } = await requestAsync(LegacyApi.V1.Abilities.index(deal));
      assert(body !== null);
      abilities = body;
    } catch (error) {
      return rejectWithValue(error);
    }

    dispatch(
      initializeClients(
        abilities.map(ability => Deals.Ability.formatToOldClient(ability)),
      ),
    );

    return undefined;
  },
);

export const refreshDeal = createAsyncThunk(
  `${DEAL}/REFRESH`,
  async (uuid: string, { rejectWithValue, dispatch }) => {
    let deal: DealJSON;

    try {
      const { body } = await requestAsync(LegacyApi.V1.Deals.get(uuid));
      assert(body !== null);
      deal = body.deal;
    } catch (error) {
      if (isApiError(error) && error.status === 404) {
        dispatch(replace(Path.NOT_FOUND));
        return null;
      }
      return rejectWithValue(error);
    }

    try {
      await dispatch(fetchClients(deal));
      return deal;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDeal = createAsyncThunk(
  `${DEAL}/FETCH`,
  async (uuid: string, { dispatch }) => {
    await dispatch(refreshDeal(uuid));
  },
);

export const initialFetch = createAsyncThunk(
  `${DEAL}/INITIAL_FETCH`,
  async (uuid: string, { dispatch }) => {
    await dispatch(fetchDeal(uuid));
    await dispatch(fetchDealDetailsThunk());
  },
);

type DealPayload = FuneralDealPayload | DealJSON | MarbleDealPayload;

const API_METHOD = {
  [DealType.FUNERAL]: LegacyApi.V1.Deals.updateOrCreate.bind(null),
  [DealType.ITEM]: LegacyApi.V1.Deals.Items.updateOrCreate.bind(null),
  [DealType.MARBLE]: LegacyApi.V1.Deals.Marbles.updateOrCreate.bind(null),
};

const FETCH_DETAILS_ACTION_CREATOR = {
  [DealType.FUNERAL]: fetchFuneralDetails.bind(null),
  [DealType.ITEM]: fetchDealDetails.bind(null),
  [DealType.MARBLE]: getDealMarble.bind(null),
};

function getDealObject(body: DealPayload): DealJSON {
  // Warning : this function is not type safe and sensible to API changes
  if (!('deal_type' in body)) {
    // TODO: The API does not send the uuid in deal object for marble deals (at least on failed initial creation)
    const { deal } = body;
    deal.uuid = body.uuid;
    return deal;
  }
  if (body.deal_type === DealType.ITEM) {
    return body as DealJSON;
  }
  return (body as FuneralDealPayload).deal;
}

async function setDealFromResponse(
  input: Deal | DealJSON,
  dispatch: ThunkDispatch<unknown, unknown, Action<unknown>>,
  response: DealPayload,
): Promise<DealJSON> {
  let deal: DealJSON;
  try {
    deal = getDealObject(response);
  } catch (error) {
    // This should be done automatically if this part throws,
    // error handling needs a big review
    captureException(error);
    throw error;
  }

  // TODO: The API does not send the deal type for marble deals (at least on failed initial creation)
  // eslint-disable-next-line camelcase
  deal.deal_type = input.deal_type;
  dispatch(setDeal(deal));

  if (!input.uuid) {
    if (deal.deal_type !== DealType.FUNERAL) {
      await dispatch(fetchClients(deal));
    }
    nextTick(() => {
      assert(deal.uuid !== undefined);
      dispatch(replace(Path.DEAL(deal.uuid, window.location.hash.slice(1))));
    });
  }
  return deal;
}

export const saveDeal = createAsyncThunk(
  `${DEAL}/SAVE`,
  async (deal: Deal | DealJSON, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const payload = { ...deal } as DealJSON;

    if (deal.deal_type !== DealType.FUNERAL) {
      if (deal.uuid) {
        try {
          await dispatch(updateClients()).then(unwrapResult);
        } catch (err) {
          const clientError = makeSelectClientsError()(state);
          assert(clientError !== null);
          return rejectWithValue(clientError);
        }
      } else {
        payload.clients = makeSelectDealClients()(state);
      }
    }

    try {
      const triggerPingServices = makeSelectTriggerPingServices()(state);
      const apiMethod = API_METHOD[deal.deal_type];

      const { body } = await requestAsync(
        apiMethod(payload, triggerPingServices),
      );
      if (!body) {
        return undefined;
      }
      const dealResponse = await setDealFromResponse(deal, dispatch, body);
      if (deal.deal_type === DealType.FUNERAL) {
        return undefined;
      }

      await dispatch(fetchDealDetailsThunk());

      // Warning: this dispatches a saga, meaning that we can not await for the result.
      // As such, triggering dependent actions after it would be a race condition.
      const fetchDetails = FETCH_DETAILS_ACTION_CREATOR[deal.deal_type];
      dispatch(fetchDetails(dealResponse));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.name !== 'ApiError' || err.status >= 500) {
        return rejectWithValue(err);
      }

      if (deal.deal_type === DealType.ITEM && err.status === 400) {
        // TODO: We NEED a real error handling
        return rejectWithValue(({
          status: 'dealIncomplete',
        } as unknown) as Error);
      }

      try {
        await setDealFromResponse(deal, dispatch, err.body);
      } catch (error) {
        return rejectWithValue(error);
      }

      if (!deal.uuid && deal.deal_type === DealType.FUNERAL) {
        dispatch(setDealSummary([]));
      }
      return rejectWithValue(err);
    }

    return undefined;
  },
);
