import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import {
  makeSelectActiveFuneralTeam,
  AppStateSubset as DataAppStateSubset,
} from 'slices/data';

import { STATE_SELECT_SLICE } from './constants';
import type { AppStateSubset, State } from './slice';

type StateSelector<T> = Selector<AppStateSubset, T>;

function selectStateSelectDomain(state: AppStateSubset): State {
  return state[STATE_SELECT_SLICE];
}

export function makeSelectError(): StateSelector<SerializedApiError | null> {
  return createSelector(selectStateSelectDomain, domain => domain.error);
}

export function makeSelectFuneralTeamItems(): Selector<
  DataAppStateSubset,
  SelectableItem<number>[]
> {
  return createSelector(makeSelectActiveFuneralTeam(), team =>
    team.map(user => ({
      value: user.id,
      name: user.name || user.email,
    })),
  );
}
