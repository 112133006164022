import { assert, Objects } from '@advitam/support'

import type { DefunctJSON } from '../../../models/Defunct'
import type { Civility } from '../../../models/Person/Civility'
import type { AutocompleteResult } from '../../Autocompletes'

interface DefunctAttributes {
  firstname: string
  lastname: string
  civility: Civility
  birth_date?: string
  death_date?: string
  birth_location: string
  birth_postal_code: string
  birth_country_code: string
}

interface Procedure {
  defunct_attributes: DefunctAttributes
  defunct_death_city_id?: number
  defunct_family_link?: string
}

interface CreationPayload {
  procedure: Procedure
}

export function serialize(
  defunct: DefunctJSON,
  deathCity?: AutocompleteResult,
  familyLink?: string,
): CreationPayload {
  assert(defunct.birth_location !== null)
  assert(defunct.birth_postal_code !== null)
  assert(defunct.birth_country !== null)

  return {
    procedure: {
      defunct_death_city_id: deathCity?.id,
      defunct_family_link: familyLink,
      defunct_attributes: {
        ...Objects.pick(defunct, 'firstname', 'lastname', 'civility'),
        birth_location: defunct.birth_location,
        birth_postal_code: defunct.birth_postal_code,
        birth_country_code: defunct.birth_country.code,
        birth_date: defunct.birth_date || undefined,
        death_date: defunct.birth_date || undefined,
      },
    },
  }
}
