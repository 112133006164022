import { authenticate } from '../../../lib/decorators'
import { del, get, post } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import { EntityInvoicePendingPaymentJSON } from '../../../models/EntityInvoice/PendingPayment'

import { BankTransfers } from './BankTransfers'
import { Reset } from './Reset'

export const PendingPayments = {
  BankTransfers,
  Reset,
  index: (): ApiRequestDescriptor<EntityInvoicePendingPaymentJSON[]> =>
    authenticate(get('/api/v1/entity_invoices/pending_payments')),
  create: (entityInvoiceId: number): ApiRequestDescriptor<EntityInvoicePendingPaymentJSON> =>
    authenticate(post(`/api/v1/entity_invoices/${entityInvoiceId}/pending_payments`)),
  destroy: (pendingPaymentId: number): ApiRequestDescriptor<void> =>
    authenticate(del(`/api/v1/entity_invoices/pending_payments/${pendingPaymentId}`)),
}
