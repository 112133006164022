import { IntlShape } from 'react-intl';

import { DealDocumentBatchRecipientJSON } from '@advitam/api/models/Deal/Document/Batch';
import { ContactMedia } from '@advitam/api/models/Entity/ContactMedia';
import { RecipientType } from '@advitam/api/models/RecipientType';
import { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import { safeFormatMessage } from 'utils/functions.typed';

import { AutocompleteDealEntitiesResult } from '@advitam/api/v1/Autocompletes';
import { SendingMethodKey, StateBatch } from '../../types';
import {
  getRecipientSendingMethodKey,
  getRecipientUniqueId,
  parseSendingKeyMethod,
} from '../../utils';
import messages from './messages';

const SENDING_METHODS_KEYS: SendingMethodKey[] = [
  'email',
  'email_2',
  'email_3',
  'email_4',
  'fax',
  'letter',
];

const EMAIL_METHOD_KEYS: SendingMethodKey[] = [
  'email',
  'email_2',
  'email_3',
  'email_4',
];

export function getInitialMethodKeys(
  recipient: DealDocumentBatchRecipientJSON,
): SendingMethodKey[] {
  const initialMethodKey = getRecipientSendingMethodKey(recipient);
  if (!initialMethodKey) {
    return [];
  }

  if (initialMethodKey !== ContactMedia.EMAIL) {
    return [initialMethodKey];
  }

  return EMAIL_METHOD_KEYS.filter(method => recipient[method]);
}

export function getValidSendingMethodKeys(
  recipient: DealDocumentBatchRecipientJSON,
  batch: StateBatch,
): SendingMethodKey[] {
  return SENDING_METHODS_KEYS.filter(
    method =>
      recipient[method] &&
      !(batch?.to_sign && ['fax', 'letter'].includes(method)) &&
      !(method === 'fax' && recipient.type === RecipientType.CLIENT),
  );
}

export function makeSendingMethodOptions(
  intl: IntlShape,
  recipient: DealDocumentBatchRecipientJSON,
  methodKeys: SendingMethodKey[],
): SelectableItem<SendingMethodKey[]>[] {
  return methodKeys.map(method => {
    const parsedMethod = parseSendingKeyMethod(method);

    return {
      value: [method],
      name: safeFormatMessage(intl, messages, parsedMethod, {
        value: recipient[method],
      }),
    };
  });
}

export function findExistingEntity(
  entity: AutocompleteDealEntitiesResult,
  recipients: DealDocumentBatchRecipientJSON[],
): DealDocumentBatchRecipientJSON | undefined {
  const entityId = getRecipientUniqueId({
    id: entity.entity_id,
    type: entity.entity_type,
  });

  return recipients.find(
    recipient => getRecipientUniqueId(recipient) === entityId,
  );
}
