import { get } from 'lodash';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Form, useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import type { Country } from '@advitam/api/models/Country';
import { CountryAutocomplete } from '@advitam/integration';
import {
  useValidators,
  FormLayout,
  FormUI,
  Input,
  isRequired,
  Button,
  Modal,
  ModalTitle,
  SubmitButton,
  NumberInput,
} from '@advitam/ui';
import LockIcon from '@advitam/ui/images/icons/lock.svg';
import type { Address } from '@advitam/ui/components/Form/UI/GooglePlace/types';
import actionMessages from 'messages/actions';
import { makeSelectUser } from 'slices/auth';

import { getFieldName } from '../utils';
import messages from './messages';
import type { ManualAddressForm } from './types';
import style from './Contact.module.scss';

function formatAddress(v: Address | undefined): string {
  return [v?.street_number || '', v?.street_name || ''].join(' ').trim();
}

interface LocationFieldProps {
  prefix?: string;
  withoutCity?: boolean;
  required?: boolean;
}

export default function LocationField({
  prefix = '',
  withoutCity,
  required,
}: LocationFieldProps): JSX.Element {
  const user = useSelector(makeSelectUser());

  const form = useForm();
  const { values } = useFormState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const address = getFieldName(prefix, 'address');
  const postalCode = getFieldName(prefix, 'postal_code');
  const city = getFieldName(prefix, 'city');
  const name = getFieldName(prefix, 'name');
  const country = getFieldName(prefix, 'country');
  const inseeCode = getFieldName(prefix, 'insee_code');
  const latitude = getFieldName(prefix, 'latitude');
  const longitude = getFieldName(prefix, 'longitude');
  const manualAddress = getFieldName(prefix, 'manual_address');

  const isManual = get(values, manualAddress) as boolean;
  const value: Address = {
    street_name: get(values, address) as string,
    street_number: null,
    postal_code: get(values, postalCode) as string,
    city: get(values, city) as string,
    country: get(values, `${country}.name`) as string,
    country_code: null,
    latitude: null,
    longitude: null,
  };

  const onLocationChange = useCallback(
    (v: Address | undefined): void => {
      form.batch(() => {
        form.change(address, formatAddress(v));
        form.change(postalCode, v?.postal_code);
        form.change(city, v?.city);
        form.change(country, { code: v?.country_code, name: v?.country });
        form.change(inseeCode, '');
      });
    },
    [form, address, postalCode, city, country, inseeCode],
  );

  const onToggleManualAddress = useCallback(() => {
    if (!user?.isAdmin) {
      return;
    }

    if (isManual) {
      form.change(manualAddress, false);
    } else {
      setIsModalOpen(true);
    }
  }, [form, user, isManual, manualAddress, setIsModalOpen]);

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const initialManualValue: ManualAddressForm = {
    address: get(values, address) as string | null,
    postalCode: get(values, postalCode) as string | null,
    inseeCode: get(values, inseeCode) as string | null,
    city: get(values, withoutCity ? name : city) as string | null,
    country: get(values, country) as Country | null,
    latitude: get(values, latitude) as number | null,
    longitude: get(values, longitude) as number | null,
  };

  const onManualAdressSubmit = useCallback(
    (manualValues: ManualAddressForm): void => {
      form.batch(() => {
        form.change(manualAddress, true);
        form.change(address, manualValues.address);
        form.change(postalCode, manualValues.postalCode);
        if (!withoutCity) {
          form.change(city, manualValues.city);
        }
        form.change(country, manualValues.country);
        form.change(inseeCode, manualValues.inseeCode);
        form.change(latitude, manualValues.latitude);
        form.change(longitude, manualValues.longitude);
      });
      setIsModalOpen(false);
    },
    [
      form,
      address,
      postalCode,
      city,
      country,
      inseeCode,
      latitude,
      longitude,
      manualAddress,
      setIsModalOpen,
      withoutCity,
    ],
  );

  const validate = useValidators(required && isRequired);

  const lockButtonClassName = [style.lock_button, isManual && style.active]
    .filter(Boolean)
    .join(' ');

  return (
    <>
      <FormLayout.Row>
        <div className={style.address_wrapper}>
          <Field required={required} name={address} validate={validate}>
            {({ meta }): JSX.Element => (
              <FormUI.GooglePlace
                value={value}
                formatDisplayValue={formatAddress}
                error={meta.touched && !meta.valid}
                onChange={onLocationChange}
                label={<FormattedMessage id={messages.address.id} />}
                disabled={isManual}
              />
            )}
          </Field>
          <Field type="checkbox" name={manualAddress}>
            {(): JSX.Element => (
              <Button
                outline
                icon={<LockIcon />}
                onClick={onToggleManualAddress}
                className={lockButtonClassName}
                disabled={!user?.isAdmin}
              />
            )}
          </Field>
        </div>
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          label={<FormattedMessage id={messages.addressAdditional.id} />}
          name={getFieldName(prefix, 'address_l2')}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <Input
          disabled
          label={<FormattedMessage id={messages.addressPostalCode.id} />}
          name={postalCode}
        />
        <Input
          disabled
          label={<FormattedMessage id={messages.addressInseeCode.id} />}
          name={inseeCode}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        {!withoutCity && (
          <Input
            disabled={isManual}
            required={required}
            label={<FormattedMessage id={messages.addressCity.id} />}
            name={city}
          />
        )}
        <Input
          disabled
          required={required}
          label={<FormattedMessage id={messages.addressCountry.id} />}
          name={`${country}.name`}
        />
      </FormLayout.Row>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={onCloseModal}
        className={style.manual_address_modal}
      >
        <ModalTitle>
          <FormattedMessage id={messages.manualAddressTitle.id} />
        </ModalTitle>
        <Form
          initialValues={initialManualValue}
          onSubmit={onManualAdressSubmit}
        >
          {({ handleSubmit }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <FormLayout.Row>
                <Input
                  required
                  name="address"
                  label={<FormattedMessage id={messages.address.id} />}
                />
              </FormLayout.Row>
              <FormLayout.Row>
                <Input
                  name="postalCode"
                  label={
                    <FormattedMessage id={messages.addressPostalCode.id} />
                  }
                />
                <Input
                  required={!withoutCity}
                  name="city"
                  label={<FormattedMessage id={messages.addressCity.id} />}
                  disabled={withoutCity}
                />
              </FormLayout.Row>
              <FormLayout.Row>
                <Input
                  name="inseeCode"
                  label={<FormattedMessage id={messages.addressInseeCode.id} />}
                  disabled={withoutCity}
                />
                <CountryAutocomplete
                  name="country"
                  label={<FormattedMessage id={messages.addressCountry.id} />}
                  disabled={withoutCity}
                />
              </FormLayout.Row>
              <FormLayout.Row>
                <NumberInput
                  name="latitude"
                  step={0.000001}
                  label={<FormattedMessage id={messages.addressLatitude.id} />}
                />
                <NumberInput
                  name="longitude"
                  step={0.000001}
                  label={<FormattedMessage id={messages.addressLongitude.id} />}
                />
              </FormLayout.Row>
              <FormLayout.Row>
                <Button
                  outline
                  text={<FormattedMessage id={actionMessages.cancel.id} />}
                  onClick={onCloseModal}
                />
                <SubmitButton
                  primary
                  text={<FormattedMessage id={actionMessages.validate.id} />}
                />
              </FormLayout.Row>
            </form>
          )}
        </Form>
      </Modal>
    </>
  );
}
