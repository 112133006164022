import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { MessageModal } from '@advitam/ui';
import apiMessages from 'api/messages';

import { makeSelectError } from './selectors';
import messages from './messages';
import { clearError } from './slice';

export default function ErrorModal(): JSX.Element | null {
  const dispatch = useDispatch();

  const error = useSelector(makeSelectError());

  const onClose = useCallback(() => {
    dispatch(clearError());
  }, [dispatch]);

  if (!error) {
    return null;
  }

  return (
    <MessageModal
      className="new-design"
      isOpen
      title={<FormattedMessage id={messages.errorModalTitle.id} />}
      ctaText={<FormattedMessage id={messages.errorModalClose.id} />}
      message={
        <ul>
          {error.errorCodes.map(code => {
            const message = apiMessages[code as keyof typeof apiMessages];
            if (message) {
              return (
                <li>
                  <FormattedMessage id={message.id} />
                </li>
              );
            }
            return <li>{code}</li>;
          })}
        </ul>
      }
      onClose={onClose}
    />
  );
}
