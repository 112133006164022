import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { DealState } from '@advitam/api/models/Deal/State';
import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import { FormUI, Tooltip } from '@advitam/ui';
import { makeSelectUser } from 'slices/auth';

import { makeSelectDeal } from '../../../selectors.typed';
import homeStyle from '../Home.module.scss';
import NewIcon from './icon/new.svg';
import InProgressIcon from './icon/in_progress.svg';
import OkIcon from './icon/ok.svg';
import KoIcon from './icon/cancel.svg';
import { updateState } from './thunk';
import slice from './slice';
import messages from './messages';
import KoReasonModal from './KoReasonModal';
import { FormattedKoReason } from './FormattedKoReason';
import UpdateConfirmationModal from './UpdateConfirmationModal';
import ErrorModal from './ErrorModal';
import style from './StateSelect.module.scss';

const STATE_CONFIRMATION_REQUIRED = [DealState.OK, DealState.ORDER];

function StateSelect(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);
  const user = useSelector(makeSelectUser());

  const [stateToConfirm, setStateToConfirm] = useState<null | DealState>(null);
  const [isKoModalOpen, setIsKoModalOpen] = useState(false);

  const onCloseKoModal = useCallback(() => {
    setIsKoModalOpen(false);
  }, [setIsKoModalOpen]);

  const onCloseUpdateConfirmModal = useCallback(() => {
    setStateToConfirm(null);
  }, [setStateToConfirm]);

  const renderPlaceholder = useCallback(
    (value: DealState): JSX.Element => {
      switch (value) {
        case DealState.QUOTE:
          return (
            <Tooltip content={<FormattedMessage id={messages.quote.id} />}>
              <NewIcon className={style.placeholder} />
            </Tooltip>
          );
        case DealState.ORDER:
          return (
            <Tooltip content={<FormattedMessage id={messages.order.id} />}>
              <InProgressIcon className={style.placeholder} />
            </Tooltip>
          );
        case DealState.OK:
          return (
            <Tooltip content={<FormattedMessage id={messages.ok.id} />}>
              <OkIcon className={style.placeholder} />
            </Tooltip>
          );
        case DealState.KO:
          return (
            <Tooltip
              content={
                <FormattedKoReason
                  reason={deal.ko_reason}
                  comment={deal.ko_comment}
                />
              }
            >
              <KoIcon className={style.placeholder} />
            </Tooltip>
          );
        case DealState.FUNERAL_CONTRACT:
          return (
            <Tooltip
              content={<FormattedMessage id={messages.funeralContract.id} />}
            >
              <KoIcon className={style.placeholder} />
            </Tooltip>
          );
        case DealState.FUNERAL_CONTRACT_OK:
          return (
            <Tooltip
              content={<FormattedMessage id={messages.funeralContractOk.id} />}
            >
              <NewIcon className={style.placeholder} />
            </Tooltip>
          );
        default:
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <>{value}</>;
      }
    },
    [deal],
  );

  const onChange = useCallback(
    (value: DealState | DealState[] | undefined): void => {
      if (!value) {
        return;
      }

      assert(!Array.isArray(value));

      if (value === DealState.KO) {
        setIsKoModalOpen(true);
      } else if (STATE_CONFIRMATION_REQUIRED.includes(value)) {
        setStateToConfirm(value);
      } else {
        dispatch(updateState({ state: value }));
      }
    },
    [dispatch, setIsKoModalOpen, setStateToConfirm],
  );

  const items = useMemo(
    () => [
      { value: DealState.QUOTE, name: intl.formatMessage(messages.quote) },
      { value: DealState.ORDER, name: intl.formatMessage(messages.order) },
      { value: DealState.OK, name: intl.formatMessage(messages.ok) },
      { value: DealState.KO, name: intl.formatMessage(messages.ko) },
      {
        value: DealState.FUNERAL_CONTRACT,
        name: intl.formatMessage(messages.funeralContract),
      },
      {
        value: DealState.FUNERAL_CONTRACT_OK,
        name: intl.formatMessage(messages.funeralContractOk),
      },
    ],
    [intl],
  );

  const isDisabled = deal.state === DealState.OK && !user?.isAdmin;

  return (
    <>
      <FormUI.Select
        disabled={isDisabled}
        items={items}
        renderPlaceholder={renderPlaceholder}
        value={deal.state || undefined}
        onChange={onChange}
        contained={false}
        className={homeStyle.borderless_select}
      />
      {isKoModalOpen && <KoReasonModal onClose={onCloseKoModal} />}
      <UpdateConfirmationModal
        state={stateToConfirm}
        onClose={onCloseUpdateConfirmModal}
      />
      <ErrorModal />
    </>
  );
}

export default withSlice(slice)(StateSelect);
