import { hasLength } from '@advitam/ui';

export function formatVat(v: string | null): string | null {
  return v?.replace(/^FR/, '') || null;
}

export function parseVat(v: string | null): string | null {
  return v ? `FR${v}` : null;
}

export const validateVat = hasLength(13);
