import { authenticate } from '../../lib/decorators'
import { get } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'

import { FullDefunctJSON } from '../../models/Defunct/Full'

export const Defuncts = {
  show: (dealId: number): ApiRequestDescriptor<FullDefunctJSON> =>
    authenticate(get(`/api/v1/deals/${dealId}/defunct`)),
}
