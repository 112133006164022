import { authenticate } from '../../../lib/decorators'
import { del, post, put } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { EntityInvoiceJSON } from '../../../models/EntityInvoice'
import { serializeCreation, serializeUpdate } from './serializers'
import type { NewEntityInvoice, NewEntityInvoicePrestation } from './types'

export const Prestations = {
  create: (
    invoice: NewEntityInvoice,
    prestation: NewEntityInvoicePrestation,
  ): ApiRequestDescriptor<void> =>
    authenticate(
      post('/api/v1/entity_invoices/prestations', serializeCreation(invoice, prestation)),
    ),
  update: (
    prestationId: number,
    invoice: NewEntityInvoice,
  ): ApiRequestDescriptor<EntityInvoiceJSON> =>
    authenticate(
      put(`/api/v1/entity_invoices/prestations/${prestationId}`, serializeUpdate(invoice)),
    ),
  delete: (prestationId: number): ApiRequestDescriptor<void> =>
    authenticate(del(`/api/v1/entity_invoices/prestations/${prestationId}`)),
}
