import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { ResourceList } from '@advitam/ui';
import type { FilterSelection } from '@advitam/ui/components/ResourceList/Filters/types';
import { withSlice } from '@advitam/react';
import { FormattedApiError } from 'components/Format/ApiError';
import { withDatasets } from 'slices/data';

import {
  makeSelectDeals,
  makeSelectIsLoading,
  makeSelectError,
  makeSelectHasMore,
  makeSelectFilters,
} from './selectors';
import slice, { clearDeals, setFilters } from './slice';
import { fetchDeals } from './thunk';
import DealFilters from './parts/Filters';
import DealHeader from './parts/Header';
import DealRow from './parts/Row';
import style from './DealsList.module.scss';

function DealsList(): JSX.Element {
  const dispatch = useDispatch();
  const deals = useSelector(makeSelectDeals());
  const loading = useSelector(makeSelectIsLoading());
  const error = useSelector(makeSelectError());
  const hasMorePages = useSelector(makeSelectHasMore());
  const filters = useSelector(makeSelectFilters());

  const fetchNewDeals = useCallback((): void => {
    dispatch(fetchDeals());
  }, [dispatch]);

  const onFilterChange = (
    newFilters: Record<string, FilterSelection>,
  ): void => {
    const filtersUpdate = {
      ...filters,
      ...newFilters,
    };

    dispatch(setFilters(filtersUpdate));
    fetchNewDeals();
  };

  useEffect(() => {
    fetchNewDeals();
    return () => {
      dispatch(clearDeals());
    };
  }, [dispatch, fetchNewDeals]);

  return (
    <main>
      <ResourceList.Container
        error={
          error && (
            <p className={style.error}>
              <FormattedApiError error={error} />
            </p>
          )
        }
        filters={<DealFilters onChange={onFilterChange} />}
        hasMore={hasMorePages}
        header={<DealHeader />}
        isLoading={loading}
        useWindow={false}
        fetchResources={fetchNewDeals}
      >
        {deals.map(deal => (
          <DealRow key={deal.uuid} deal={deal} />
        ))}
      </ResourceList.Container>
    </main>
  );
}

export default compose(
  withDatasets('funeralTeam', 'funeralBrands'),
  withSlice(slice),
)(DealsList);
