import { Objects } from '@advitam/support'
import { authenticate, withSessionId } from '../../../lib/decorators'
import { del, get, post, put } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { SupplierWarehouseProductJSON } from '../../../models/Supplier/Warehouse/Product'
import { serializeProduct } from './serializers'
import type { WarehouseProductBody } from './types'

export interface ProductsIndexFilters {
  q?: string
  page?: number
  per_page?: number
}

export const Products = {
  create: (
    warehouseId: number,
    product: Omit<WarehouseProductBody, 'id'>,
  ): ApiRequestDescriptor<SupplierWarehouseProductJSON> =>
    authenticate(
      withSessionId(
        post(`/api/v1/suppliers/warehouses/${warehouseId}/products`, serializeProduct(product)),
      ),
    ),

  index: (
    warehouseId: number,
    filters?: ProductsIndexFilters,
  ): ApiRequestDescriptor<SupplierWarehouseProductJSON[]> =>
    authenticate(get(`/api/v1/suppliers/warehouses/${warehouseId}/products`, filters)),

  update: (product: WarehouseProductBody): ApiRequestDescriptor<SupplierWarehouseProductJSON> =>
    authenticate(
      withSessionId(
        put(
          `/api/v1/suppliers/warehouses/products/${product.id}`,
          Objects.omit(product, 'product'),
        ),
      ),
    ),

  destroy: (id: number): ApiRequestDescriptor<void> =>
    authenticate(withSessionId(del(`/api/v1/suppliers/warehouses/products/${id}`))),
}
