import defaultTheme from './default'
import MonotypeTracking from './MonotypeTracking'

export interface FontThemeProps {
  theme?: Record<string, string>
  googleFont?: boolean
  nonce?: string
}

export default function FontTheme({
  theme = defaultTheme,
  googleFont,
  nonce = '',
}: FontThemeProps): JSX.Element {
  const variables = Object.entries(theme)
    .map(([name, value]) => `--${name}:${value}`)
    .join(';')

  return (
    <>
      <MonotypeTracking />
      {googleFont && (
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
          <link
            href={`https://fonts.googleapis.com/css2?family=${theme.font.replace(
              /\w/g,
              '+',
            )}:ital,wght@0,300;0,400;0,800;0,900;1,300&display=swap`}
            rel="stylesheet"
          />
        </>
      )}
      <style nonce={nonce}>{`:root{${variables}}`}</style>
    </>
  )
}
